//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfInput, SfButton, SfSelect, SfCheckbox } from '@storefront-ui/vue';
import { addressGetter, useAddresses, useCountrySearch } from '@vue-storefront/magento';
import { useUser } from '~/composables';
import { required, min, oneOf } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { reactive, computed, ref, onBeforeMount, defineComponent } from '@nuxtjs/composition-api';
import omitDeep from 'omit-deep';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('oneOf', {
  ...oneOf,
  message: 'Invalid country',
});

export default defineComponent({
  name: 'AddressForm',

  components: {
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    address: {
      type: Object,
      default: () => ({
        id: undefined,
        apartment: '',
        city: '',
        country_code: '',
        firstname: '',
        lastname: '',
        postcode: '',
        region: {
          region_code: '',
          region_id: 0,
        },
        street: '',
        telephone: '',
        default_shipping: false,
        default_billing: false,
      }),
    },
    isNew: {
      type: Boolean,
      required: true,
    },
    isSubmitting: false,
  },

  setup(props, { emit }) {
    const { load: loadCountries, countries, search: searchCountry, country } = useCountrySearch('my-account-shipping');
    const { load } = useAddresses();
    const { isAuthenticated } = useUser();

    const form = reactive({
      apartment: props.address.apartment,
      city: props.address.city,
      country_code: props.address.country_code,
      firstname: props.address.firstname,
      lastname: props.address.lastname,
      postcode: props.address.postcode,
      region: {
        region_code: '',
        region_id: null,
        ...props.address.region,
      },
      street: props.address.street,
      telephone: props.address.telephone,
      default_shipping: props.address.default_shipping,
      default_billing: props.address.default_billing,
      ...(props.isNew ? {} : { id: props.address.id }),
    });
    const formSubmitted = ref(false);

    // @ts-ignore
    const countriesList = computed(() => addressGetter.countriesList(countries.value));
    const regionInformation = computed(() => addressGetter.regionList(country.value));

    const submitForm = () => {
      const regionId = regionInformation.value.find((r) => r.abbreviation === form.region.region_code)?.id;
      if (regionId) {
        form.region.region_id = regionId;
      }

      emit('submit', {
        form: omitDeep(form, ['__typename']),
        onComplete: async () => {
          await load();
        },
        // TODO: Handle Error
        onError: () => {},
      });
    };

    onBeforeMount(async () => {
      await loadCountries();
      if (props.address.country_code) {
        await searchCountry({ id: props.address.country_code });
      }
    });

    return {
      form,
      formSubmitted,
      submitForm,
      countriesList,
      regionInformation,
      searchCountry,
      isAuthenticated,
    };
  },
});
