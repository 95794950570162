








































import { SfCard, SfHeading } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import BynderAsset from '../BynderAsset.vue';

export default defineComponent({
  name: 'PromotionBanner',
  components: {
    SfCard,
    SfHeading,
    BynderAsset,
  },
  props: {
    banners: null,
  },
  computed: {
    placeholderImage() {
      // To provide the SfCard with its required image
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    },
  },
  setup(props) {
    const getAttributes = (banner) => {
      if (banner.ctaUrl) {
        return {
          href: banner.ctaUrl,
        };
      } else {
        return {
          to: banner.ctaLink,
        };
      }
    };

    const columns = computed(() => [4, 3, 2, 1].find((num) => (props.banners?.length || 1) % num === 0));

    return {
      getAttributes,
      columns,
    };
  },
});
