



















































import { defineComponent, onMounted, ref, toRef, watch, nextTick, onUnmounted } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import Swiper, { Navigation, Pagination, Thumbs, Controller } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { transformImageUrlToSize, ImageSize } from '~/helpers/magentoImage';
Swiper.use([Controller]);
Swiper.use([Navigation]);
Swiper.use([Pagination]);
Swiper.use([Thumbs]);

import ProductSwiperSlide from '~/components/Product/ProductSwiperSlide.vue';
import ChevronSliderIcon from '~/assets/icons/chevron-slider.svg';
import ChevronLeftIcon from '~/assets/icons/chevron-left.svg';
import ChevronRightIcon from '~/assets/icons/chevron-right.svg';
import PlayIcon from '~/assets/icons/play.svg';

export default defineComponent({
  name: 'ProductSwiperSlider',
  components: {
    ChevronSliderIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    PlayIcon,
    ProductSwiperSlide,
    SfButton,
  },
  props: {
    slides: {
      type: Array,
      default: [],
    },
    currentSlideIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const thumbsSliderRef = ref(null);
    const thumbsSlider = ref(null);
    const mainSliderRef = ref(null);
    const mainSlider = ref(null);
    const swiperSlides = toRef(props, 'slides');

    const initializeSwiper = () => {
      thumbsSlider.value = new Swiper(thumbsSliderRef.value, {
        direction: 'vertical',
        slidesPerView: 5,
        spaceBetween: 8,
        centeredSlides: false,
        loop: false,
        slideToClickedSlide: true,
        threshold: 1,
        navigation: {
          prevEl: '.product-gallery-slider__thumbs-button--prev',
          nextEl: '.product-gallery-slider__thumbs-button--next',
        },
      });

      mainSlider.value = new Swiper(mainSliderRef.value, {
        // @ts-ignore
        navigation: {
          prevEl: '.product-gallery-slider__main-button--prev',
          nextEl: '.product-gallery-slider__main-button--next',
        },
        loop: false,
        speed: 400,
        slidesPerView: 1,
        threshold: 2,
        spaceBetween: 8,
        roundLengths: true,
        thumbs: {
          swiper: thumbsSlider.value,
        },
        pagination: {
          el: '.product-gallery-slider__main-pagination',
          type: 'bullets',
        },
      });

      mainSlider.value.on('activeIndexChange', (swiper) => {
        emit('slide:change', swiper.realIndex);
      });
    };

    const updateSwiper = () => {
      thumbsSlider.value.update();
      mainSlider.value.update();
    };

    watch(
      () => props.currentSlideIndex,
      (newVal) => {
        mainSlider.value.slideTo(newVal);
        thumbsSlider.value.slideTo(newVal);
      },
    );

    watch(
      () => props.slides,
      () => nextTick(updateSwiper),
    );

    onMounted(() => {
      initializeSwiper();

      window.addEventListener('resize', updateSwiper);
    });

    onUnmounted(() => window.removeEventListener('resize', updateSwiper));

    return {
      mainSliderRef,
      thumbsSliderRef,
      swiperSlides,
      transformImageUrlToSize,
      ImageSize,
    };
  },
});
