




















import { useCartStore } from '~/stores';
import { SfCheckbox, SfAddressPicker, SfButton, SfLoader, SfHeading } from '@storefront-ui/vue';
import { useUserShipping, useAddresses, userShippingGetters } from '@vue-storefront/magento';
import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import UserShippingForm from './UserShippingForm.vue';
import { useUser } from '~/composables';
import UserShippingAddresses from './UserShippingAddresses.vue';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'UserShippingNewUser',
  components: {
    SfCheckbox,
    SfAddressPicker,
    SfButton,
    SfLoader,
    SfHeading,
    UserAddressDetails,
    UserShippingForm,
    UserShippingAddresses,
  },
  setup(_, { emit }) {
    const { shipping: userShipping, load: loadUserShipping } = useUserShipping();
    const { update, save } = useAddresses();
    const { isAuthenticated } = useUser();
    const shippingAddresses = computed(() => userShippingGetters.getAddresses(userShipping.value));
    const cartStore = useCartStore();
    const { selectedShippingAddress, selectedShippingAddressId, selectedBillingAddressId } = storeToRefs(cartStore);

    const billingSameAsShipping = ref(true);
    const showAddressSelection = ref(false); // Set to true when !billingSameAsShipping

    const handleCheckBillingSameAsShipping = async () => {
      billingSameAsShipping.value = !billingSameAsShipping.value;
      showAddressSelection.value = true;

      if (billingSameAsShipping.value) {
        emit('setAddressOnBilling', selectedShippingAddress.value);
      }
    };

    const saveNewAddress = async ({ form, onComplete, onError }) => {
      try {
        if (isAuthenticated.value) {
          // Save on user account
          const actionMethod = form.id ? update : save;
          const data = await actionMethod({ address: form });
          await onComplete(data);

          // Reload user shipping so we can select the address
          await loadUserShipping();
          const address = shippingAddresses.value.slice(-1)[0];
          emit('setAddressOnShipping', address);

          if (billingSameAsShipping.value) {
            emit('setAddressOnBilling', address);
          }
        } else {
          // guestaddressonshipping?
          emit('setAddressOnShipping', form);

          if (billingSameAsShipping.value) {
            emit('setAddressOnBilling', form);
          }
        }
      } catch (error) {
        onError(error);
      }
    };

    const firstName = computed(() => {
      if (!localStorage.getItem('vsf-checkout')) return null;
      return JSON.parse(localStorage.getItem('vsf-checkout'))['user-account']?.firstname;
    });
    const lastName = computed(() => {
      if (!localStorage.getItem('vsf-checkout')) return null;
      return JSON.parse(localStorage.getItem('vsf-checkout'))['user-account']?.lastname;
    });

    const telephone = computed(() => {
      if (!localStorage.getItem('vsf-checkout')) return null;
      return JSON.parse(localStorage.getItem('vsf-checkout'))['user-account']?.telephone;
    });

    return {
      billingSameAsShipping,
      showAddressSelection,
      handleCheckBillingSameAsShipping,
      userShipping,
      saveNewAddress,
      firstName,
      lastName,
      telephone,
    };
  },
});
