//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration';
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { SfSection, SfLoader, SfButton, SfColorPicker, SfColor, SfSkeleton } from '@storefront-ui/vue';
import { computed, defineComponent, ref, toRef, nextTick, watchEffect } from '@nuxtjs/composition-api';

import ProductCard from '~/components/MyJewellery/ProductCard.vue';
import ProductCardLoading from '~/components/MyJewellery/ProductCardLoading.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import ArrowLeftIcon from '~/assets/icons/arrow-left.svg';
import ArrowRightIcon from '~/assets/icons/arrow-right.svg';

import { addressGetter } from '@vue-storefront/magento';

export default defineComponent({
  name: 'ProductsCarousel',
  components: {
    LazyHydrate,
    Swiper,
    ProductCard,
    ProductCardLoading,
    SfSection,
    SfLoader,
    SfButton,
    SfColorPicker,
    SfColor,
    SvgImage,
    ArrowLeftIcon,
    ArrowRightIcon,
    SfSkeleton,
    addressGetter,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    products: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
    centerInsufficientSlides: {
      type: Boolean,
      required: false,
      default: false,
    },
    addToCartButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOnTopOfPage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const isLoading = toRef(props, 'loading');

    const mappedProducts = computed(() =>
      props.products.filter((product) => {
        const isInStock =
          product?.stock_status === 'IN_STOCK' ||
          (product?.variants?.length > 0 &&
            product?.variants?.some((variant) => variant.product.stock_status === 'IN_STOCK'));

        return product?.sku && isInStock;
      }),
    );

    const swiperWrapper = ref();
    const swiper = ref();
    const swiperInfo = computed(() => {
      return {
        isBeginning: swiper.value?.isBeginning,
        isEnd: swiper.value?.isEnd,
      };
    });

    const initializeSwiper = () => {
      const slidesOffset = props.centerInsufficientSlides && mappedProducts.value?.length < 3 ? 0 : 16;
      swiper.value = new Swiper(swiperWrapper.value, {
        modules: [Navigation],
        speed: 400,
        threshold: 2,
        slidesPerView: 'auto',
        slidesOffsetBefore: slidesOffset,
        slidesOffsetAfter: slidesOffset,
        spaceBetween: 8,
        centerInsufficientSlides: props.centerInsufficientSlides,
        breakpoints: {
          542: {
            slidesPerView: 4,
            slidesOffsetBefore: slidesOffset,
            slidesOffsetAfter: slidesOffset,
            spaceBetween: 8,
          },
          756: {
            slidesPerView: 5,
            slidesOffsetBefore: slidesOffset,
            slidesOffsetAfter: slidesOffset,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            spaceBetween: 20,
          },
        },
      });
    };

    watchEffect(() => {
      if (!isLoading.value && mappedProducts.value.length) {
        nextTick(initializeSwiper);
      }
    });

    /**
     * If carousel is on top of page, load the visible images early, the rest without loading property.
     * If carousel is not on top of page, load all lazy.
     *
     * @param {number} index - The index of the image.
     * @returns {string} The loading type for the image.
     */
    const getImageLoadingType = (index) => {
      if (!props.isOnTopOfPage) return 'lazy';
      if (index < 3) {
        return 'eager';
      }
      if (index > 4) {
        return 'lazy';
      }
      return 'none';
    };

    const showAtcButtons = computed(() => {
      return props.addToCartButtons && !isLoading.value;
    });

    return {
      isLoading,
      mappedProducts,
      swiper,
      swiperWrapper,
      swiperInfo,
      showAtcButtons,
      getImageLoadingType,
    };
  },
});
