var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gift-wrapping"},[_c('div',{staticClass:"sf-accordion has-chevron"},[_c('SfButton',{staticClass:"sf-button--pure sf-accordion-item__header",class:{ 'is-open': _vm.isGiftWrappingOpen },attrs:{"aria-pressed":_vm.isGiftWrappingOpen.toString(),"aria-expanded":_vm.isGiftWrappingOpen.toString(),"data-testid":"accordion-item-giftwrapping"},on:{"click":_vm.toggleGiftWrapping}},[_vm._v("\n      "+_vm._s(_vm.$t('Gift? Add a gift wrap!'))+"\n      "),_c('SfChevron',{staticClass:"sf-accordion-item__chevron",class:{ 'sf-chevron--right': !_vm.isGiftWrappingOpen },attrs:{"tabindex":"0"}})],1),_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.isGiftWrappingOpen)?_c('div',[_c('div',{staticClass:"sf-accordion-item__content"},[_c('div',{staticClass:"gift-wrapping__options"},[_c('SfLoader',{attrs:{"loading":_vm.loading}},[_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.products)?_c('ul',{staticClass:"gift-wrapping__categories"},_vm._l((_vm.products.data),function(category,index){return _c('li',{key:index},[_c('h3',{staticClass:"gift-wrapping__category-title h4"},[_vm._v("\n                      "+_vm._s(_vm.translatedCategoryName[category.categoryName] || category.categoryName)+"\n                    ")]),_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[_c('SfCarousel',{key:index,staticClass:"gift-wrapping__products",attrs:{"settings":_vm.carouselSettings},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var go = ref.go;
return [_c('SfButton',{staticClass:"sf-arrow sf-arrow--prev",on:{"click":go}},[_c('ChevronLeftIcon')],1)]}},{key:"next",fn:function(ref){
var go = ref.go;
return [_c('SfButton',{staticClass:"sf-arrow sf-arrow--next",on:{"click":go}},[_c('ChevronRightIcon')],1)]}}],null,true)},[_vm._v(" "),_vm._v(" "),_vm._l((category.products),function(product,index){return _c('SfCarouselItem',{key:index},[_c('SfProductCard',{staticClass:"sf-product-card--giftwrap",attrs:{"title":product.name,"image":product.media_url.url,"image-width":200,"image-height":300,"badge-label":"","badge-color":"secondary","regular-price":_vm.getNumericPriceFromString(product.special_price ? product.special_price : product.price),"link":_vm.localePath(("/" + (product.product_url.split('/').pop()))),"is-added-to-cart":_vm.isInCart({ product: product })},scopedSlots:_vm._u([{key:"colors",fn:function(){return [_vm._v(_vm._s(null))]},proxy:true},{key:"image",fn:function(imageSlotProps){return [_c('SfButton',_vm._g({staticClass:"sf-button--pure sf-product-card__link",attrs:{"link":imageSlotProps.link,"data-testid":"product-link","aria-label":"Go To Product"}},_vm.$listeners),[(Array.isArray(imageSlotProps.image))?_vm._l((imageSlotProps.image.slice(0, 2)),function(picture,key){return _c('nuxt-img',{key:key,staticClass:"sf-product-card__picture",attrs:{"src":picture,"alt":imageSlotProps.title,"width":imageSlotProps.imageWidth,"height":imageSlotProps.imageHeight}})}):_c('nuxt-img',{staticClass:"sf-product-card__image",attrs:{"src":imageSlotProps.image,"alt":imageSlotProps.title,"width":imageSlotProps.imageWidth,"height":imageSlotProps.imageHeight}})],2)]}},{key:"add-to-cart",fn:function(ref){
var showAddedToCartBadge = ref.showAddedToCartBadge;
var title = ref.title;
return [_c('SfCircleIcon',{staticClass:"sf-product-card__add-button",attrs:{"aria-label":("Add to Cart " + title),"has-badge":showAddedToCartBadge,"data-testid":"product-add-icon"},on:{"click":function($event){return _vm.addGiftItemToCart(product)}}},[_c('span',{staticClass:"sf-product-card__add-button--icons"},[(_vm.cartLoading && _vm.productAddingToCart === product.sku)?_c('transition',{attrs:{"name":"sf-pulse","mode":"out-in"}},[_c('SfLoader',{staticClass:"sf-product-card__add-button-loader",attrs:{"loading":true}})],1):_c('transition',{attrs:{"name":"sf-pulse","mode":"out-in"}},[_c('BasketIcon')],1)],1)])]}}],null,true)})],1)}),_vm._v(" "),_vm._l((category.products.length % 3
                            ? 3 - (category.products.length % 3)
                            : 0),function(item,index){return _c('SfCarouselItem',{key:("fill-" + index)})})],2)],1)],1)}),0):_vm._e()])],1)],1)])]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }