
















































































import { ref, reactive, defineComponent, useContext, useRouter, useRoute } from '@nuxtjs/composition-api';
import { SfModal, SfInput, SfButton, SfCheckbox, SfLoader, SfBar, SfNotification } from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/helpers/customer/regex';
import { useUser } from '~/composables';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'Login',
  components: {
    SfModal,
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    SfBar,
    SfNotification,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const form = ref({
      email: null,
      password: null,
      recaptchaInstance: null,
    });
    const isLogin = ref(true);
    const isThankYouAfterForgotten = ref(false);
    const error = reactive({
      login: null,
    });

    const { app, $recaptcha, $config } = useContext();

    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);

    const router = useRouter();
    const { login, loading, error: userError } = useUser();

    const resetErrorValues = () => {
      error.login = null;
    };

    const handleForm = (fn) => async () => {
      resetErrorValues();

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.getResponse();
        form.value.recaptchaInstance = $recaptcha;

        await fn({
          user: {
            ...form.value,
            recaptchaToken,
          },
        });
      } else {
        await fn({
          user: {
            ...form.value,
          },
        });
      }

      const hasUserErrors = userError.value.login;
      if (hasUserErrors) {
        error.login = userError.value.login?.message;
        return;
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.reset();
      }
    };

    const handleLogin = async () => {
      await handleForm(login)();

      if (userError.value.login) {
        return;
      }
    };

    const redirectToForgotten = async () => {
      await router.push(app.localePath('/customer/account/forgotpassword'));
    };

    return {
      error,
      form,
      loading,
      handleLogin,
      redirectToForgotten,
      isLogin,
      isThankYouAfterForgotten,
      isRecaptchaEnabled,
    };
  },
});
