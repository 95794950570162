import { render, staticRenderFns } from "./UserShippingNewUser.vue?vue&type=template&id=dc25a3ee&"
import script from "./UserShippingNewUser.vue?vue&type=script&lang=ts&"
export * from "./UserShippingNewUser.vue?vue&type=script&lang=ts&"
import style0 from "./UserShippingNewUser.vue?vue&type=style&index=0&id=dc25a3ee&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports