











import { defineComponent, computed, useRoute, ref, useContext, onMounted } from '@nuxtjs/composition-api';
import ProductsCarousel from '~/components/ProductsCarousel.vue';
import { useExponeaStore } from '~/stores';
import useExponeaConstants from '~/constants/exponea';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ProductRecommendations',
  components: {
    ProductsCarousel,
  },
  setup() {
    const route = useRoute();
    const { app } = useContext();
    const exponeaStore = useExponeaStore();
    const { user } = storeToRefs(exponeaStore);
    const show = ref(true);

    const recommendationId = ref('');
    const exponeaRecommendations = computed(() => exponeaStore.getRecommendationById(recommendationId.value));
    const loading = computed(() => !exponeaRecommendations.value.length);

    const title = computed(() => {
      const firstName = user.value.firstname;
      if (!user.value.email) {
        return app.i18n.t('Product recommendations unknown user');
      }
      if (user.value.gender === 'male') {
        if (firstName) {
          return app.i18n.t('Product recommendations with name - male', { firstName });
        }
        return app.i18n.t(`Product recommendations without name - male`);
      }
      if (firstName) {
        return app.i18n.t('Product recommendations with name', { firstName });
      }
      return app.i18n.t(`Product recommendations without name`);
    });

    const recommendationIds = ref({
      default: null,
      known: null,
      men: null,
    });

    const setRecommendationIds = () => {
      const categories = route.value.params.pathMatch?.split('/') || [];

      // Hard coded translations for categories. If a recommendation for a new category is required, add it here.
      const categoryTranslations = {
        accessoires: ['accessoires'],
        bracelets: ['armbanden', 'bracelets', 'armbaender'],
        clothes: ['bekleidung', 'kleding', 'clothing', 'vetements'],
        default: [''],
        earrings: ['oorbellen', 'earrings', 'ohrringe', 'boucles-d-oreilles'],
        jewellery: ['sieraden', 'jewellery', 'schmuck', 'bijoux'],
        necklaces: ['kettingen', 'necklaces', 'ketten', 'colliers'],
        new: ['nieuw', 'new', 'neuheiten', 'nouveautes'],
        rings: ['ringen', 'rings', 'ringe', 'bagues'],
        sale: ['sale', 'promotions'],
      };

      const constantNames = categories
        .map((category) => {
          const translatedCategory = Object.keys(categoryTranslations).find((key) =>
            categoryTranslations[key].includes(category),
          );
          return translatedCategory?.toUpperCase() || 'DEFAULT';
        })
        ?.join('_');

      recommendationIds.value = {
        default: useExponeaConstants()[`CATEGORY_PAGE_${constantNames}_DEFAULT_ID`],
        known: useExponeaConstants()[`CATEGORY_PAGE_${constantNames}_KNOWN_ID`],
        men: useExponeaConstants()[`CATEGORY_PAGE_${constantNames}_MEN_ID`],
      };

      if (!recommendationIds.value.default) {
        show.value = false;
      }
    };

    const setRecommendationId = async () => {
      // use promise to wait until exponea user is loaded
      while (exponeaStore.userLoading) {
        await new Promise((resolve) => setTimeout(resolve, 16));
      }

      if (user.value.gender === 'male') return (recommendationId.value = recommendationIds.value.men);
      if (user.value.email) return (recommendationId.value = recommendationIds.value.known);
      return (recommendationId.value = recommendationIds.value.default);
    };

    const getRecommendations = async () => {
      // If there aren't any recommendations available, no need to get them
      if (recommendationIds.value.default === null) {
        return;
      }

      // setRecommendationIds first, because they can't be loaded async
      await exponeaStore.getRecommendation({ id: await setRecommendationId(), fillWithRandom: true, size: 10 });

      // If no recommendations are available, hide the component
      if (!exponeaRecommendations.value?.length) {
        show.value = false;
      }
    };

    onMounted(() => {
      getRecommendations();
    });

    setRecommendationIds();

    return {
      exponeaRecommendations,
      loading,
      title,
      show,
      recommendationId,
    };
  },
});
