






















import { defineComponent, onMounted, ref, toRef } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
Swiper.use([Navigation]);
Swiper.use([Pagination]);

import ProductSwiperSlide from '~/components/Product/ProductSwiperSlide.vue';
import ChevronSliderIcon from '~/assets/icons/chevron-slider.svg';
import ChevronLeftIcon from '~/assets/icons/chevron-left.svg';
import ChevronRightIcon from '~/assets/icons/chevron-right.svg';
import PlayIcon from '~/assets/icons/play.svg';

export default defineComponent({
  name: 'ProductLightboxSlider',
  components: {
    ChevronSliderIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    PlayIcon,
    ProductSwiperSlide,
    SfButton,
  },
  props: {
    slides: {
      type: Array,
      default: [],
    },
    currentSlideIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const sliderRef = ref(null);
    const lightboxSlider = ref(null);
    const isMobile = ref(true);
    const swiperSlides = toRef(props, 'slides');

    const initializeSwiper = () => {
      lightboxSlider.value = new Swiper(sliderRef.value, {
        // @ts-ignore
        navigation: {
          prevEl: '.product-lightbox-slider-button--prev',
          nextEl: '.product-lightbox-slider-button--next',
        },
        loop: false,
        speed: 400,
        slidesPerView: 1,
        initialSlide: props.currentSlideIndex,
        threshold: 2,
        spaceBetween: 8,
        roundLengths: true,
        pagination: {
          el: '.product-lightbox-slider-pagination',
          type: 'bullets',
        },
      });

      lightboxSlider.value.on('activeIndexChange', (swiper) => {
        emit('slide:change', swiper.realIndex);
      });
    };

    const updateSwiper = () => {
      lightboxSlider.value.update();
    };

    const setIsMobile = (v) => (isMobile.value = v);

    onMounted(() => {
      initializeSwiper();

      window.addEventListener('resize', () => {
        setIsMobile(window.outerWidth < 1024);
        updateSwiper();
      });
    });

    return {
      sliderRef,
      swiperSlides,
    };
  },
});
