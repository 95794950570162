//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FooterTop',
  props: {
    component: null,
    page: null,
  },
};
