






























































import { ref, defineComponent } from '@nuxtjs/composition-api';
import {
  SfLoader,
  SfButton,
  SfProperty,
  SfCollectedProduct,
  SfQuantitySelector,
  SfBadge,
  SfProductCard,
} from '@storefront-ui/vue';
import { cartGetters, productGetters } from '@vue-storefront/magento';
import _debounce from '~/helpers/debounce';

import { useCart } from '~/composables';
import { wishlistStore } from '~/stores';
import { transformImageUrlToSize, ImageSize } from '~/helpers/magentoImage';
import stockStatusEnum from '~/enums/stockStatusEnum';
import BasketIcon from '~/assets/icons/shopping-bag.svg';
import { storeToRefs } from 'pinia';
import type { WishlistItemInterface } from '@vue-storefront/magento-api/lib/types/GraphQL';
import useGtm from '~/composables/useGTM';

export default defineComponent({
  name: 'WishlistItem',
  components: {
    SfBadge,
    SfButton,
    SfCollectedProduct,
    SfLoader,
    SfProductCard,
    SfProperty,
    SfQuantitySelector,
    BasketIcon,
  },
  props: {
    wishlistItem: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { removeItem, updateItem, setWishlist, addItemToCart } = wishlistStore();
    const { wishlist } = storeToRefs(wishlistStore());
    const { load: loadCart } = useCart();
    const { trackCartChange } = useGtm();

    const itemAddingToCart = ref(null);

    const getBundles = () => props.wishlistItem?.product?.items?.map((b) => b.title).flat() || [];

    const delayedUpdateItemQty = _debounce(({ quantity }) => {
      if (quantity === 0) removeItem({ product: props.wishlistItem.product });
      else
        updateItem({
          wishlistItems: [
            {
              wishlist_item_id: props.wishlistItem.id,
              quantity: quantity,
            },
          ],
        });
    }, 500);

    const invokeAddWishlistItemToCart = async () => {
      itemAddingToCart.value = props.wishlistItem.id;

      await addItemToCart({
        wishlistItemIds: [props.wishlistItem?.id],
        callback: (success) => {
          trackCartChange('addToCart', {
            product: props.wishlistItem.product,
            sku: props.wishlistItem.product.sku,
            variant: props.wishlistItem.configured_variant?.sku,
            quantity: 1,
          });
          emit('itemAddedToCart', { name: props.wishlistItem?.product.name, success });
        },
      });

      itemAddingToCart.value = null;

      loadCart();
    };

    const invokeUpdateItemQty = ({ quantity }) => {
      const wishlistItemIndex = wishlist.value?.items_v2.items.findIndex((item) => item.id === props.wishlistItem.id);

      // SF-UI issue, doesn't set "zero" quantity -> Check for removal:
      if (quantity === 1 && props.wishlistItem?.quantity === quantity) {
        quantity = 0;
      }

      // Set wishlist to match result after response from GraphQL.
      const newWishlist = { ...wishlist.value };
      newWishlist.items_v2.items[wishlistItemIndex] = <WishlistItemInterface>{
        ...props.wishlistItem,
        quantity,
      };

      setWishlist(newWishlist);
      delayedUpdateItemQty({ quantity });
    };

    const isInStock = (product) => product?.product && cartGetters.getStockStatus(product) === stockStatusEnum.inStock;

    return {
      getBundles,
      isInStock,
      cartGetters,
      productGetters,
      invokeUpdateItemQty,
      invokeAddWishlistItemToCart,
      itemAddingToCart,
      transformImageUrlToSize,
      ImageSize,
    };
  },
});
