//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfButton, SfInput, SfLoader, SfChevron } from '@storefront-ui/vue';
import { cartGetters } from '@vue-storefront/magento';
import { computed, onMounted, watch, ref, defineComponent } from '@nuxtjs/composition-api';
import { useCart } from '~/composables/useCart';
import TimesIcon from '~/assets/icons/times.svg';
import ArrowRightIcon from '~/assets/icons/arrow-right.svg';

export default defineComponent({
  name: 'CouponCode',
  components: {
    SfButton,
    SfInput,
    SfLoader,
    SfChevron,
    TimesIcon,
    ArrowRightIcon,
  },
  setup() {
    const { cart, applyCoupon, removeCoupon, error, loading } = useCart();
    const couponCode = ref('');
    const couponIsApplied = computed(() => cartGetters.getAppliedCoupon(cart.value)?.code);

    const setCartCoupon = () => {
      couponCode.value = couponIsApplied.value;
    };

    const handleCoupon = async () => {
      await (couponIsApplied.value
        ? removeCoupon({ currentCart: cart.value })
        : applyCoupon({ couponCode: couponCode.value }));
    };

    const showCoupon = ref(false);

    // Giftcards always start with 6064365921
    const isGiftCardCode = computed(() => couponCode.value?.startsWith('6064365921'));
    const errorMessage = computed(() => error.value?.applyCoupon?.message);

    onMounted(setCartCoupon);
    watch(couponIsApplied, setCartCoupon);

    return {
      handleCoupon,
      couponIsApplied,
      couponCode,
      error,
      errorMessage,
      loading,
      showCoupon,
      isGiftCardCode,
    };
  },
});
