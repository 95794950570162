//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfTabs, SfLink } from '@storefront-ui/vue';
import { computed, defineComponent, onMounted, useContext, ref } from '@nuxtjs/composition-api';
import { useUser } from '~/composables';
import MyNewsletter from './MyNewsletter.vue';
import TabMenu from '~/components/MyAccount/TabMenu.vue';

export default defineComponent({
  name: 'MyProfileOverview',

  components: {
    SfTabs,
    SfLink,
    MyNewsletter,
    TabMenu,
  },

  setup() {
    const { load, loading, user } = useUser();

    const formatAddress = (a) => {
      if (!a) return;
      return `${a.firstname} ${a.lastname}
        ${a.street.join(' ')}
        ${a.city}
        ${a.postcode}
        ${a.country_code}
        T: ${a.telephone}`.trim();
    };

    const { localePath } = useContext();
    const getLink = (title) => localePath('/customer/account/' + (title || '').toLowerCase().replace(' ', '-'));

    const billingAddress = computed(() => {
      if (!user.value || user.value?.addresses?.length < 1) return;
      return user.value.addresses.find((address) => address.default_billing);
    });

    const shippingAddress = computed(() => {
      if (!user.value || user.value?.addresses?.length < 1) return;
      return user.value.addresses.find((address) => address.default_shipping);
    });

    const openTab = ref(1);

    return {
      loading,
      user,
      formatAddress,
      billingAddress,
      shippingAddress,
      getLink,
      openTab,
    };
  },
});
