























import { defineComponent, useAsync, useContext } from '@nuxtjs/composition-api';
import {
  getBynderAsset,
  getBynderImageUrl,
  getBynderImageName,
  getCalculatedWidth,
  getSvgOrThumbnailImageUrl,
} from '@/helpers/bynderImage';
import { useCache } from '~/composables';
import logger from '~/utilities/logger';

export default defineComponent({
  methods: {
    getBynderAsset,
    getBynderImageUrl,
    getBynderImageName,
    getCalculatedWidth,
    getSvgOrThumbnailImageUrl,
  },
  setup() {
    const context = useContext();
    const locale = context.app?.i18n?.locale || 'de';
    const { get: getCache, set: setCache } = useCache('footer');

    const paymentMethods = useAsync(() => {
      const cached = getCache(`footerPaymentMethods_${locale}`);
      if (cached) {
        return cached;
      }

      return context.$axios
        .get(`${context.$brxmEndpointResolver.resolveDocumentsEndPoint()}/footer/footerpaymentmethods`)
        .then((res) => res.data)
        .then((data) => {
          const result = data?.content[data?.document?.$ref.substring(9)].data || {};
          setCache(`footerPaymentMethods_${locale}`, result);
          return result;
        })
        .catch((error) => {
          logger.warn('Failed to fetch footerPaymentMethods ', error);
          return {};
        });
    });

    return { paymentMethods };
  },
});
