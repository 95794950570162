





























import { defineComponent } from '@nuxtjs/composition-api';

import HTMLContent from '~/components/HTMLContent.vue';
import Breadcrumbs from './Breadcrumbs.vue';
import Video from './Video.vue';
import BynderAsset from './BynderAsset.vue';

export default defineComponent({
  name: 'BlogPostHeader',
  computed: {
    document() {
      return this.page?.getDocument();
    },
    content() {
      const { document } = this.component?.getModels();
      return this.page.getContent(document);
    },
    model() {
      const data = this.content?.getData();

      return (
        (data && {
          ...data,
          image: data.image,
        }) ||
        {}
      );
    },
    mediaType() {
      return this.model?.mediaType?.selectionValues[0]?.key;
    },
    video() {
      return this.page.getContent(this.model?.video);
    },
    isPreview() {
      return this.page?.isPreview();
    },
    postTitle() {
      return this.model?.title || this.document.getData().title;
    },
    postDescription() {
      return this.model?.text?.value || '';
    },
    postDate() {
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = this.document.getData().date && new Date(this.document.getData().date);
      return date?.toLocaleDateString(this.$i18n.locale || 'en', options);
    },
    authorName() {
      return (this.model?.author && this.$t('By: {author}', { author: this.model?.author })) || false;
    },
    readingTime() {
      return (
        (this.model?.readingTime &&
          this.$t('Reading time: {readingTime} minutes', { readingTime: this.model?.readingTime })) ||
        false
      );
    },
    hideDetails() {
      return this.model?.hideDetails;
    },
    pageMeta() {
      return this.page?.getDocument()?.getData();
    },
    hasStoreAddress() {
      /** Store detail page - physical address */
      return this.pageMeta?.streetName;
    },
    storeAddress() {
      return `${[this.pageMeta?.streetName, this.pageMeta?.houseNumber, this.pageMeta?.addition]
        .filter((s) => s.length)
        .join(' ')
        .trim()}, ${this.pageMeta?.postalCode} ${this.pageMeta?.city}`;
    },
    storeCountry() {
      return ['nl', 'netherlands', 'nederland'].includes(this.pageMeta?.country?.toLowerCase())
        ? null
        : this.pageMeta?.country;
    },
  },
  components: {
    Breadcrumbs,
    HTMLContent,
    Video,
    BynderAsset,
  },
  props: {
    component: null,
    page: null,
  },
  mounted() {
    this.video;
  },
});
