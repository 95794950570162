


















import { defineComponent, useRoute, onMounted } from '@nuxtjs/composition-api';

import { useBloomreachComponents, useBloomreach } from '~/composables';
import { usePageStore, wishlistStore } from '~/stores';
import { getBynderImageObject } from '~/helpers/bynderImage';

import AppFooter from '~/components/AppFooter.vue';
import BlogPost from '~/components/MyJewellery/PageLayout/BlogPost.vue';
import CustomerService from '~/components/MyJewellery/PageLayout/CustomerService.vue';
import OneColumn from '~/components/MyJewellery/PageLayout/OneColumn.vue';
import Shop from '~/components/MyJewellery/PageLayout/Shop.vue';
import TwoColumn from '~/components/MyJewellery/PageLayout/TwoColumn.vue';
import TopHeader from '~/components/MyJewellery/TopHeader.vue';
import TopNavMenu from '~/components/TopNav/TopNavMenu.vue';

// Catalog types
import CATEGORY from '~/components/Category/Category.vue';
import PRODUCT from '~/components/Product/Product.vue';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'Catalog',
  components: {
    AppFooter,
    TopHeader,
    TopNavMenu,
    BlogPost,
    CustomerService,
    OneColumn,
    Shop,
    TwoColumn,
    CATEGORY,
    PRODUCT,
  },
  async asyncData(context) {
    const { getPage } = useBloomreach(context);
    const result = await getPage().catch((err) => {
      return {};
    });

    const currentLocale = context.app.i18n.locale;

    return {
      ...result,
      currentLocale,
    };
  },
  head() {
    const image = getBynderImageObject(this.pageData?.image);
    const ogImage =
      (image && [
        {
          hid: 'og:image',
          name: 'og:image',
          content: image.url || null,
        },
        {
          hid: 'og:image:width',
          name: 'og:image:width',
          content: image.width || 600,
        },
        {
          hid: 'og:image:height',
          name: 'og:image:height',
          content: image.height || 600,
        },
      ]) ||
      [];
    return {
      link: [
        ...(this.hrefLangs || []),
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.pageData?.canonical || `${this.$config.storeUrl}${this.$route.fullPath}` || null,
        },
      ],
      htmlAttrs: {
        lang: this.currentLocale,
      },
      meta: [
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: process.env.NUXT_APP_NAME || 'My Jewellery',
        },
        {
          hid: 'robots',
          name: 'robots',
          content: `${this.pageData?.robotsIndex?.selectionValues[0].label || 'index'}, ${
            this.pageData?.robotsFollow?.selectionValues[0].label || 'follow'
          }`.trim(),
        },
        ...ogImage,
      ],
    };
  },
  setup() {
    const { mapping } = useBloomreachComponents();
    const route = useRoute();
    const { addToWishlistAfterLogin } = wishlistStore();
    const { pageType, routeData, loaded } = storeToRefs(usePageStore());

    /**
     * Sets page to scroll position if user is redirected from (login) page
     * @todo: remove when login page is Vue, routeBehaviour will handle this
     */
    const checkForScrollPosition = async () => {
      if (document.referrer.includes('redirectToReferer=1')) {
        const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions'));
        const scrollPosition = scrollPositions[route.value.fullPath];
        await new Promise<void>((resolve) => {
          let timePassed = 0;
          const interval = setInterval(() => {
            timePassed += 100;
            if (loaded.value || timePassed > 2500) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });

        window.scrollTo({ top: scrollPosition, behavior: 'auto' });
      }
    };

    const pageComponent = (pageType.value || '').toLowerCase();

    onMounted(() => {
      addToWishlistAfterLogin();
      checkForScrollPosition();
    });

    return {
      routeData,
      mapping,
      pageComponent,
    };
  },
});
