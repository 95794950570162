//
//
//
//
//
//

export default {
  props : {
    endDate: {
      type: Date,
      default() {
        return new Date();
      },
    },
    negative: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      now: new Date(),
      timer: null,
    }
  },
  computed: {
    days() {
      return Math.trunc((this.endDate - this.now) / 1000 / 3600 / 24);
    },
    hours() {
      return Math.trunc((this.endDate - this.now) / 1000 / 3600) % 24;
    },
    minutes() {
      return Math.trunc((this.endDate - this.now) / 1000 / 60) % 60;
    },
    seconds() {
      return Math.trunc((this.endDate - this.now)/1000) % 60;
    }
  },
  watch: {
    endDate: {
      immediate: true,
      handler(newVal) {
        if (this.timer) {
          clearInterval(this.timer);
        }

        this.timer = setInterval(() => {
          this.now = new Date();
          if (this.negative) {
            return;
          }

          if (this.now > newVal) {
            this.now = newVal;
            this.$emit('endTime');
            clearInterval(this.timer);
          }
        }, 1000);
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
}
