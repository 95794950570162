var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.reviewSent && !_vm.error.addReview)?_c('div',[_c('SfNotification',{staticClass:"reviews-notification",attrs:{"message":_vm.$t('Your review was submitted!'),"type":"success","icon":"","visible":_vm.reviewSent},on:{"click:close":function($event){_vm.reviewSent = false}}})],1):(_vm.error.addReview)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.error.addReview))])]):_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{staticClass:"form",attrs:{"id":"add-review-form"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.submitForm(reset))}}},[_c('p',{staticClass:"form__rating-title"},[_vm._v(_vm._s(_vm.$t('Your rating')))]),_vm._v(" "),_vm._l((_vm.ratingMetadata),function(metadata){return _c('div',{key:metadata.id,staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required","name":"rating"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('RatingInput',{attrs:{"id":metadata.name,"name":("ratings[" + (metadata.id) + "]"),"rating-id":metadata.id,"values":metadata.values,"error-message":_vm.$t(errors[0])},model:{value:(_vm.selectedRatings[metadata.id]),callback:function ($$v) {_vm.$set(_vm.selectedRatings, metadata.id, $$v)},expression:"selectedRatings[metadata.id]"}})]}}],null,true)})],1)}),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[(!_vm.isAuthenticated)?_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:2","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"name","label":_vm.$t('Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.nickname),callback:function ($$v) {_vm.$set(_vm.form, "nickname", $$v)},expression:"form.nickname"}})]}}],null,true)}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:2","name":"summary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"summary","label":_vm.$t('Title'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.summary),callback:function ($$v) {_vm.$set(_vm.form, "summary", $$v)},expression:"form.summary"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:2","name":"summary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfTextarea',{attrs:{"label":_vm.$t('Review'),"name":"review","rows":5,"wrap":"soft","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.text),callback:function ($$v) {_vm.$set(_vm.form, "text", $$v)},expression:"form.text"}})]}}],null,true)})],1),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),_c('SfButton',{staticClass:"form__button sf-button--full-width"},[_vm._v(_vm._s(_vm.$t('Add review')))])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }