






















import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { defineComponent, computed, ref, useContext, watch } from '@nuxtjs/composition-api';

import { useUiState } from '~/stores';

import ChevronRightIcon from '~/static/icons/chevron-right-alt.svg';
import StoreSwitcher from '~/components/StoreSwitcher.vue';
import TopNavMenuItem from './TopNavMenuItem.vue';
import TopNavNestedMenu from './TopNavNestedMenu.vue';
import TopNavCtaItem from './TopNavCtaItem.vue';
import TopNavUspItem from './TopNavUspItem.vue';
import { storeToRefs } from 'pinia';
import { useMenu } from '~/composables/useMenu';
import useTopNavStore from '~/stores/useTopNavStore';

export default defineComponent({
  name: 'TopNavMenu',
  components: {
    ChevronRightIcon,
    StoreSwitcher,
    TopNavMenuItem,
    TopNavNestedMenu,
    TopNavCtaItem,
    TopNavUspItem,
  },
  props: {
    component: {
      type: Object as () => BrComponent,
      required: true,
    },
    page: {
      type: Object as () => Page,
      required: true,
    },
  },
  setup(props) {
    const { app } = useContext();
    const { isMobileMenuOpen } = storeToRefs(useUiState());
    const { uspItems, ctaItems, ctaUspsLoaded } = storeToRefs(useTopNavStore());

    const { loadMobileHeaderItems } = useTopNavStore();

    const { menu } = useMenu(props);

    const isDesktop = computed(() => app.$device.isDesktop);

    const isExpanded = ref(false);

    watch(isMobileMenuOpen, () => {
      isMobileMenuOpen.value && !isDesktop.value && !ctaUspsLoaded.value && loadMobileHeaderItems();
    });

    return {
      uspItems,
      ctaItems,
      isExpanded,
      menu,
    };
  },
});
