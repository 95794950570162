//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfTabs, SfTable, SfButton, SfLink, SfPagination, SfSelect, SfLoader, SfProperty } from '@storefront-ui/vue';
import { ref, computed, defineComponent, useRoute, useFetch, onMounted } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { orderGetters } from '@vue-storefront/magento';
import { useUiHelpers } from '~/composables';
import { useUserOrder } from '~/composables/useUserOrder';
import OrderDetails from './OrderDetails.vue';

export default defineComponent({
  name: 'OrderHistory',
  components: {
    LazyHydrate,
    SfButton,
    SfLink,
    SfPagination,
    SfProperty,
    SfSelect,
    SfTable,
    SfTabs,
    SfLoader,
    OrderDetails,
  },
  setup() {
    const { search, loading } = useUserOrder();
    const route = useRoute();
    const rawCustomerOrders = ref(null);
    const currentOrder = computed(() =>
      rawCustomerOrders.value?.items?.find((o) => o.number === route.value.query?.orderId),
    );

    const th = useUiHelpers();
    const {
      query: { page, itemsPerPage },
    } = route.value;

    onMounted(async () => {
      rawCustomerOrders.value = await search({
        currentPage: Number.parseInt(page, 10) || 1,
        pageSize: Number.parseInt(itemsPerPage, 10) || 10,
      });
    });

    const tableHeaders = ['Order No.', 'Date', 'Total price', 'Status'];

    const getStatusTextClass = (order) => {
      switch (order.status) {
        case 'open':
          return 'text-warning';
        case 'complete':
          return 'text-success';
        default:
          return '';
      }
    };

    const pagination = computed(() => orderGetters.getPagination(rawCustomerOrders.value));

    return {
      currentOrder,
      getStatusTextClass,
      orderGetters,
      getDate: orderGetters.getDate,
      getPrice: orderGetters.getPrice,
      orders: computed(() => rawCustomerOrders.value?.items ?? []),
      rawCustomerOrders,
      pagination,
      tableHeaders,
      th,
      loading,
    };
  },
});
