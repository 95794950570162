//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfRadio, SfLoader, SfButton } from '@storefront-ui/vue';
import { ref, onMounted, defineComponent } from '@nuxtjs/composition-api';
import { usePaymentProvider, useCart, useMakeOrder } from '~/composables';
import { useCartStore } from '~/stores';
import { storeToRefs } from 'pinia';
import AdyenPaymentProvider from './AdyenPaymentProvider.vue';
import PaypalExpressIcon from '~/assets/icons/paymentProviders/paypal_express.svg';
import SchemeIcon from '~/assets/icons/paymentProviders/scheme.svg';
import logger from '~/utilities/logger';

export default defineComponent({
  name: 'VsfPaymentProvider',
  components: {
    AdyenPaymentProvider,
    SfRadio,
    SfLoader,
    SfButton,
  },
  setup() {
    const { order, make, loading: loadingOrder } = useMakeOrder();
    const { load, save, loading: loadingPaymentProvider } = usePaymentProvider();
    const { load: loadCart, setCart } = useCart();
    const cartStore = useCartStore();
    const { paymentMethods } = storeToRefs(cartStore);
    const selectedMethod = ref(null);

    onMounted(async () => {
      await loadCart();
      await load();
    });

    const definePaymentMethods = async (paymentMethod) => {
      try {
        selectedMethod.value = paymentMethod;
        await save({
          paymentMethod: {
            code: paymentMethod,
          },
        });
        await loadCart();
      } catch (e) {
        logger.error(e);
        selectedMethod.value = null;
      }
    };

    const processOrder = async () => {
      try {
        await make();
      } catch (error) {
        logger.error(error);
      }
    };

    const icon = (type) => {
      switch (type) {
        case 'paypal_express':
          return PaypalExpressIcon;
        default:
          return SchemeIcon;
      }
    };

    return {
      paymentMethods,
      selectedMethod,
      definePaymentMethods,
      loadingPaymentProvider,
      processOrder,
      loadingOrder,
      icon,
    };
  },
});
