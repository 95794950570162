


















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductSwiperSlideVideo',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup({ item }) {
    const videoUrl = `${item.video.url}?autoplay=1&loop=1`;

    return { videoUrl };
  },
});
