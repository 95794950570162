//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';

import CardItem from '~/components/MyJewellery/Banner/CardItem.vue';

export default defineComponent({
  name: 'LatestArticles',
  components: {
    CardItem,
  },
  props: {
    banners: null,
  },
  methods: {
    getCategory(banner) {
      return banner?.hideCategory ? '' : banner.categoryManually ? banner.categoryManually : banner.category || '';
    },
  },
});
