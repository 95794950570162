//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
import Breadcrumbs from '@/components/MyJewellery/Breadcrumbs.vue';

export default defineComponent({
  name: 'TwoColumn',
  props: {
    page: null,
  },
  components: {
    Breadcrumbs,
  },
});
