



















import ChatIcon from '~/assets/icons/chat.svg';
import { defineComponent, computed, onMounted, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CtaChat',
  components: {
    ChatIcon,
  },
  props: {
    component: null,
    page: null,
  },
  head: {},
  setup({ component, page }) {
    const { $config } = useContext();
    const document = computed(() => {
      const { document } = component?.getModels();
      return page?.getContent(document);
    });
    const content = computed(() => {
      return component?.model?.meta?.paramsInfo || {};
    });
    const isPreview = computed(() => {
      return page?.isPreview();
    });
    const openChat = () => {
      if (!window.document.querySelector('[data-kustomer-api-key]')) {
        window.addEventListener('kustomerLoaded', () => {
          window.Kustomer.start({}, () => {
            window.Kustomer.open();
          });
        });

        var script = window.document.createElement('script');
        script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
        script.setAttribute('data-kustomer-api-key', $config.kustomerApiKey);
        window.document.body.appendChild(script);
      } else {
        window.Kustomer.open();
      }
    };

    onMounted(() => {
      if (window.location.search.includes('email=chat')) {
        openChat();
      }
    });

    return { document, content, isPreview, openChat };
  },
});
