























import { computed, defineComponent } from '@nuxtjs/composition-api';
import StarIcon from '~/assets/icons/star.svg';

export default defineComponent({
  name: 'RatingInput',
  components: {
    StarIcon,
  },
  props: {
    name: {
      type: String,
      default: 'rating',
    },
    value: {
      type: String,
      default: '',
    },
    ratingId: {
      type: String,
      default: '',
    },
    values: {
      type: Array,
      default: [],
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const uid = Math.floor(Math.random() * Date.now()).toString(8);
    const ratingValues = computed(() => props.values.reverse());

    return {
      uid,
      ratingValues,
    };
  },
});
