














import { computed, defineComponent, ref, watchEffect } from '@nuxtjs/composition-api';
import ProductSwiperSlideVideo from './ProductSwiperSlideVideo.vue';
import ProductSwiperSlidePicture from './ProductSwiperSlidePicture.vue';
import { useUiState } from '~/stores';

export default defineComponent({
  name: 'ProductSwiperSlide',
  components: {
    ProductSwiperSlideVideo,
    ProductSwiperSlidePicture,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { toggleProductLightBox } = useUiState();

    const itemHasBeenShown = ref(false);
    const component = computed(() => {
      if (!props.item) return null;
      if (props.visible || itemHasBeenShown.value) {
        itemHasBeenShown.value = true;
        return props.item.video ? 'ProductSwiperSlideVideo' : 'ProductSwiperSlidePicture';
      }
      return null;
    });

    const url = ref(props.item.url);

    return { component, toggleProductLightBox, url };
  },
});
