




























import { defineComponent, computed, ref, onMounted } from '@nuxtjs/composition-api';
import { SfLoader, SfButton } from '@storefront-ui/vue';

import { wishlistStore, useProductStore } from '~/stores';
import { storeToRefs } from 'pinia';
import ProductVariantMatrix from '~/components/Product/ProductVariantMatrix.vue';
import ProductActions from '../Product/ProductActions.vue';
import { getSelectedProductVariant } from '~/helpers/product/productGetters';

export default defineComponent({
  name: 'WishlistVariant',
  components: {
    ProductVariantMatrix,
    SfLoader,
    SfButton,
    ProductActions,
  },
  setup() {
    const { products: storeProducts, activeProductSku, productLoading } = storeToRefs(useProductStore());
    const { productCustomOptionsCanAddToCartHandler } = useProductStore();
    const { showWishlistModal } = storeToRefs(wishlistStore());
    const { addProductToWishlist } = wishlistStore();

    const product = computed(() => {
      return storeProducts.value[activeProductSku.value];
    });
    const sku = computed(() => product.value?.sku);
    const addingProductToWishlist = ref(false);

    const productVariantMatrix = ref();

    const productCustomOptionsCanAddToCart = computed(() =>
      productCustomOptionsCanAddToCartHandler(activeProductSku.value),
    );

    const canAddProductToWishlist = computed(() => {
      const selectedOptions = Object.values(productVariantMatrix.value?.productConfiguration || {}).sort();
      if (!selectedOptions.length) return false;
      return (
        !!product.value?.variants?.find((variant) =>
          variant.attributes.every((attribute) => selectedOptions.includes(attribute.uid)),
        ) && productCustomOptionsCanAddToCart.value
      );
    });

    const addToWishlistImediately = computed(
      () =>
        showWishlistModal.value &&
        ((product.value?.configurable_options?.length === 1 &&
          product.value?.configurable_options?.[0]?.values?.length === 1) ||
          product.value?.__typename === 'SimpleProduct'),
    );

    const addToWishlist = async () => {
      addingProductToWishlist.value = true;

      await addProductToWishlist(productVariantMatrix.value.productConfiguration);
      showWishlistModal.value = false;

      addingProductToWishlist.value = false;
    };

    const selectedProduct = computed(() =>
      getSelectedProductVariant(product.value, productVariantMatrix.value?.productConfiguration),
    );

    onMounted(() => {
      if (addToWishlistImediately.value) addToWishlist();
    });

    return {
      productVariantMatrix,
      addingProductToWishlist,
      addToWishlist,
      showWishlistModal,
      product,
      sku,
      canAddProductToWishlist,
      selectedProduct,
    };
  },
});
