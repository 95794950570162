//
//
//
//
//
//
//

import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import TimesIcon from '~/assets/icons/times.svg';

export default defineComponent({
  name: 'ShinyCombination',
  components: {
    TimesIcon,
  },
  setup() {
    const hideShineyCombination = ref(false);
    const close = () => {
      hideShineyCombination.value = true;
      sessionStorage.setItem('hideShineyCombination', true);
    };

    onMounted(() => (hideShineyCombination.value = sessionStorage.getItem('hideShineyCombination')));

    return { hideShineyCombination, close };
  },
});
