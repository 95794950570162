




























import { computed, defineComponent } from '@nuxtjs/composition-api';
import { BrManageContentButton } from '@bloomreach/vue-sdk';

export default defineComponent({
  name: 'BrContent',
  components: {
    BrManageContentButton,
  },
  props: {
    component: {
      type: Object,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const document = computed(() => {
      const documentRef = props.component.getModels()?.document;
      return documentRef && props.page.getContent(documentRef);
    });

    const data = computed(() => {
      return document.value?.getData();
    });

    const image = computed(() => {
      return data.value?.image && props.page.getContent(data.value?.image);
    });

    const date = computed(() => {
      return data.value?.date ?? data.value?.publicationDate;
    });

    const formatDate = (date: number) => {
      return new Date(date).toDateString();
    };

    return {
      data,
      date,
      document,
      image,
      formatDate,
    };
  },
});
