

















import { defineComponent } from '@nuxtjs/composition-api';
import Breadcrumbs from '@/components/MyJewellery/Breadcrumbs.vue';
import LeftMenu from '../LeftMenu.vue';

export default defineComponent({
  name: 'CustomerService',
  props: {
    page: null,
  },
  components: {
    LeftMenu,
    Breadcrumbs,
  },
  computed: {
    pageData(): object | null {
      return this.page
        ? {
            ...this.page?.page?.[this.page?.document?.$ref?.substring(6)]?.data,
            ...this.page?.page?.[this.page?.document?.$ref?.substring(6)]?.links?.site,
          } || {}
        : null;
    },

    isPreview() {
      return this.page?.isPreview();
    },
  },
});
