//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfAccordion, SfHeading } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import HTMLContent from '~/components/HTMLContent.vue';

export default defineComponent({
  name: 'Accordion',
  components: {
    HTMLContent,
    LazyHydrate,
    SfAccordion,
    SfHeading,
  },
  props: {
    component: null,
    document: null,
    page: null,
  },
  computed: {
    computedDocument() {
      if (this.document) return this.document;

      const { document } = this.component?.getModels();
      return this.page.getContent(document);
    },
    isPreview() {
      return this.page?.isPreview();
    },
    data() {
      return this.computedDocument?.getData() || {};
    },
    title() {
      return this.data?.title;
    },
    items() {
      const { faqItems } = this.data;
      const items = faqItems?.map((item) => this.getItemContent(item)).filter((item) => item && item.answer);

      return items;
    },
    backgroundColor() {
      return this.data?.backgroundColor;
    },
  },
  methods: {
    getItemContent(ref) {
      return this.page.getContent(ref)?.getData() || {};
    },
  },
});
