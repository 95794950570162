//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, onBeforeMount, computed, useRoute, useContext } from '@nuxtjs/composition-api';
import { reviewGetters, useReview, userGetters, useUser } from '@vue-storefront/magento';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { min, oneOf, required } from 'vee-validate/dist/rules';
import { SfInput, SfButton, SfNotification, SfSelect, SfTextarea } from '@storefront-ui/vue';
import { usePageStore } from '~/stores';
import RatingInput from '~/components/Product/RatingInput';
import StarIcon from '~/assets/icons/star.svg';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('oneOf', {
  ...oneOf,
  message: 'Invalid country',
});
const BASE_FORM = (id) => ({
  nickname: '',
  ratings: {},
  sku: id,
  summary: '',
  text: '',
});

export default defineComponent({
  name: 'ProductAddReview',
  components: {
    RatingInput,
    SfButton,
    SfInput,
    SfNotification,
    SfSelect,
    SfTextarea,
    StarIcon,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const route = useRoute();
    const {
      params: { id },
    } = route.value;
    const { routeData } = usePageStore();
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);
    const { loadReviewMetadata, metadata, error, addReview } = useReview(`productReviews-${id}`);
    const { isAuthenticated, user } = useUser();

    const reviewSent = ref(false);
    const selectedRatings = ref([]);

    const form = ref(BASE_FORM(routeData?.sku));

    const ratingMetadata = computed(() => reviewGetters.getReviewMetadata([...metadata.value]));

    const formSubmitValue = computed(() => {
      const nickname = isAuthenticated.value ? userGetters.getFullName(user.value) : form.value.nickname;
      const ratings = ratingMetadata.value.map((item) => ({
        id: item.id,
        value_id: selectedRatings.value[item.id],
      }));

      return {
        ...form.value,
        nickname,
        ratings,
        recaptchaToken: '',
      };
    });

    const submitForm = (reset) => async () => {
      if (
        !(
          formSubmitValue.value.ratings?.[0]?.value_id ||
          formSubmitValue.value.ratings?.[0]?.id ||
          formSubmitValue.value.nickname ||
          formSubmitValue.value.summary ||
          formSubmitValue.value.sku ||
          formSubmitValue.value.text
        )
      ) {
        return;
      }

      try {
        if (isRecaptchaEnabled.value) {
          $recaptcha.init();
        }

        if (isRecaptchaEnabled.value) {
          const recaptchaToken = await $recaptcha.getResponse();
          formSubmitValue.value.recaptchaToken = recaptchaToken;
        }

        await addReview(formSubmitValue.value);

        reviewSent.value = true;

        reset();

        if (isRecaptchaEnabled.value) {
          $recaptcha.reset();
        }
      } catch {
        reviewSent.value = false;
      }
    };

    onBeforeMount(async () => {
      await loadReviewMetadata();
    });

    return {
      error,
      form,
      formSubmitValue,
      isAuthenticated,
      ratingMetadata,
      reviewSent,
      selectedRatings,
      submitForm,
      isRecaptchaEnabled,
    };
  },
});
