//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, defineComponent, onMounted, useContext } from '@nuxtjs/composition-api';
import { productGetters } from '@vue-storefront/magento';
import { encodeBase64 } from '~/plugins/base64';

import { useProduct } from '~/composables';
import LazyHydrate from 'vue-lazy-hydration';
import ProductsCarousel from '../ProductsCarousel.vue';

export default defineComponent({
  name: 'ProductCarousel',
  components: {
    LazyHydrate,
    ProductsCarousel,
  },
  props: {
    component: null,
    page: null,
  },
  setup(props) {
    const context = useContext();

    const { document } = props.component?.getModels();
    const content = ref(props.page.getContent(document));
    const pageData = ref(content.value?.getData());

    const isPreview = ref(props.page.isPreview());
    const color = ref(pageData.value?.color?.selectionValues[0]?.key);
    const title = ref(pageData.value?.title);
    const buttonLabel = ref(pageData.value?.buttonLabel);
    const externalLink = ref(pageData.value?.ctaUrl);
    const internalLink = ref(props.page?.getContent(pageData.value?.ctaLink)?.getUrl());

    const category = ref(context.$encodeBase64(pageData?.value?.categoryManual));
    const sortValue = ref(pageData?.value?.categorySort);
    const skus = ref(pageData?.value?.productsManual);
    const productFilters = ref(
      pageData.value?.productFilters?.map((item) => props.page.getContent(item).getData() || {}),
    );

    const cacheId = `products=${skus.value?.join(',') || ''}&category=${category.value || ''}`;

    const { getProductList } = useProduct(cacheId);

    const productsLoading = ref(false);
    const products = ref([]);
    const doSearch = async () => {
      // Check if anything is selected, otherwise bail.
      if (!skus.value?.length && !category.value?.length && !productFilters.value?.length) {
        return;
      }

      productsLoading.value = true;

      const searchQuery = {
        pageSize: category.value?.length ? 10 : 50,
        currentPage: 0,
        customQuery: {
          products: 'products',
          metadata: {},
        },
        filter: {
          ...getFilterStatement(skus, category, productFilters),
        },
        sort: {
          ...getSortStatement(sortValue?.value?.selectionValues[0]?.key, skus?.value?.[0] !== ''),
        },
      };

      const productsResult = await getProductList(searchQuery);

      products.value = productGetters
        .getFiltered(productsResult?.items, { master: true })
        .sort((a, b) => skus.value?.indexOf(a?.sku) - skus.value?.indexOf(b?.sku));

      productsLoading.value = false;
    };

    const showAddToCartButtons = ref(pageData.value?.addToCartButtons);

    onMounted(doSearch);

    return {
      content,
      pageData,
      isPreview,
      color,
      title,
      buttonLabel,
      externalLink,
      internalLink,
      products,
      productsLoading,
      cacheId,
      showAddToCartButtons,
    };
  },
});

function getFilterStatement(skus, category, productFilters) {
  if (productFilters.value) {
    return createCustomFilter(productFilters);
  } else if (skus?.value?.[0] !== '') {
    return {
      sku: {
        in: skus?.value,
      },
    };
  } else {
    return {
      category_uid: {
        eq: category?.value,
      },
    };
  }
}

function createCustomFilter(productFilters) {
  const result = {};

  productFilters.value.map((filter) => {
    const key = filter.filterType.selectionValues[0].key;
    let value = filter[key] ? filter[key].selectionValues[0].key : filter['value'];
    if (key === 'category_uid') {
      value = encodeBase64(value);
    }

    result[key] = {
      in: [value],
    };
  });

  return result;
}

function getSortStatement(sortValue, isSkuSearch) {
  if (!isSkuSearch && sortValue && sortValue === 'priceAscending') {
    return { price: 'ASC' };
  } else if (!isSkuSearch && sortValue && sortValue === 'priceDescending') {
    return { price: 'DESC' };
  } else {
    return {};
  }
}
