import { render, staticRenderFns } from "./TopHeader.vue?vue&type=template&id=eaa7fae0&scoped=true&"
import script from "./TopHeader.vue?vue&type=script&lang=ts&"
export * from "./TopHeader.vue?vue&type=script&lang=ts&"
import style0 from "./TopHeader.vue?vue&type=style&index=0&id=eaa7fae0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eaa7fae0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopBar: require('/builds/my-jewellery/code/frontend/components/TopBar.vue').default,AppHeader: require('/builds/my-jewellery/code/frontend/components/AppHeader.vue').default,TopNav: require('/builds/my-jewellery/code/frontend/components/TopNav/TopNav.vue').default})
