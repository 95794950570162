

























































































import { SfOverlay, SfHeader, SfButton, SfBadge, SfLink } from '@storefront-ui/vue';
import { computed, ref, defineComponent, onMounted } from '@nuxtjs/composition-api';
import { useCart, useUiHelpers } from '~/composables';
import { useCustomerStore, wishlistStore, useUiState } from '~/stores';

import HeaderLogo from '~/components/HeaderLogo.vue';
import StoreSwitcher from '~/components/StoreSwitcher.vue';

import ShoppingBagIcon from '~/assets/icons/shopping-bag.svg';
import BarsIcon from '~/assets/icons/bars.svg';
import UserIcon from '~/assets/icons/user.svg';
import CheckIcon from '~/assets/icons/checkmark-alt.svg';
import WishlistIcon from '~/assets/icons/wishlist.svg';
import { storeToRefs } from 'pinia';

export default defineComponent({
  components: {
    SfHeader,
    SfOverlay,
    HeaderLogo,
    StoreSwitcher,
    SfLink,
    SfButton,
    SfBadge,
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () => import(/* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'),
    BarsIcon,
    CheckIcon,
    ShoppingBagIcon,
    UserIcon,
    WishlistIcon,
  },
  props: {
    simplified: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { toggleCartSidebar, toggleWishlistSidebar, toggleMobileMenu } = useUiState();
    const { toggleMobileMenuOpenClassOnBody } = useUiHelpers();

    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadWishlistIds } = wishlistStore();
    const { wishlistIds } = storeToRefs(wishlistStore());
    const { isLoggedIn } = storeToRefs(useCustomerStore());

    const isSearchOpen = ref(false);
    const result = ref(null);
    const wishlistItemsQty = computed(() => wishlistIds.value?.items_count);
    const accountIcon = computed(() => (isLoggedIn.value ? 'profile_fill' : 'profile'));
    const searchLoading = ref(false);
    const cartTotalItems = computed(() => cart.value?.total_quantity ?? 0);

    onMounted(async () => {
      await loadCartTotalQty();
      if (isLoggedIn.value) loadWishlistIds();
    });

    const toggleNavMenu = () => {
      toggleMobileMenu();
      toggleMobileMenuOpenClassOnBody();
    };

    return {
      accountIcon,
      cartTotalItems,
      isLoggedIn,
      isSearchOpen,
      result,
      toggleNavMenu,
      toggleCartSidebar,
      toggleMobileMenu,
      toggleWishlistSidebar,
      wishlistItemsQty,
      searchLoading,
    };
  },
});
