//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfList, SfPrice, SfButton, SfQuantitySelector } from '@storefront-ui/vue';
import { productGetters } from '@vue-storefront/magento';
import { computed, watch, ref, defineComponent, useRoute } from '@nuxtjs/composition-api';
import { useCart } from '~/composables/useCart';
import { useImage, useProduct } from '~/composables';
import { storeToRefs } from 'pinia';
import { usePageStore, useProductStore } from '~/stores';

export default defineComponent({
  name: 'GroupedProductSelector',
  components: {
    SfList,
    SfPrice,
    SfButton,
    SfQuantitySelector,
  },
  props: {
    canAddToCart: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update-price'],
  setup(props, { emit }) {
    const { product } = storeToRefs(useProductStore());
    const { routeData } = usePageStore();
    const route = useRoute();

    const productId = routeData?.sku || route.value?.params?.id;
    const { productLoading } = useProduct(`product-${productId}`);
    const groupedItems = computed(() => productGetters.getGroupedProducts(product.value));

    const { addItem } = useCart();
    const loading = ref(false);

    const addToCart = async () => {
      try {
        loading.value = true;

        const groupedItemsFiltered = groupedItems.value.filter((p) => p.qty);
        if (groupedItemsFiltered.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const p of groupedItemsFiltered) {
            // eslint-disable-next-line no-await-in-loop
            await addItem({ product: p.product, quantity: p.qty });
          }
        }
        loading.value = false;
      } catch {
        loading.value = false;
      }
    };

    watch(
      groupedItems,
      (newValue) => {
        const getProductPrice = (p) => {
          const { regular, special } = productGetters.getPrice(p);

          return regular > special ? regular : special;
        };

        const price = newValue.reduce((acc, curr) => curr.qty * getProductPrice(curr.product) + acc, 0);

        emit('update-price', price);
      },
      {
        deep: true,
      },
    );

    const { getMagentoImage } = useImage();

    return {
      addToCart,
      groupedItems,
      loading,
      productLoading,
      product,
      productGetters,
      getMagentoImage,
    };
  },
});
