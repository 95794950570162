



























import { defineComponent } from '@nuxtjs/composition-api';
import { PropType } from 'vue';
import { SfButton } from '@storefront-ui/vue';

import { Store } from '~/types/interfaces/storeGrid';
import BynderAsset from '../BynderAsset.vue';
import { TransformationTypes } from '~/helpers/bynderImage';

export default defineComponent({
  components: {
    SfButton,
    BynderAsset,
  },
  props: {
    store: {
      type: Object as PropType<Store[]>,
      required: true,
    },
  },
  computed: {
    transformationTypes() {
      return TransformationTypes;
    },
    formattedAddress() {
      return `${this.store.address.streetName} ${this.store.address.houseNumber} ${this.store.address.addition}`.trim();
    },
    formattedCity() {
      return `${this.store.address.postalCode} ${this.store.address.city}`.trim();
    },
    firstAdjustedOpeningHours() {
      return this.store.adjustedOpeningHours.slice(0, 1);
    },
    asset() {
      return {
        data: this.store.document.model.data.bookingImage,
        size: '(min-width: 1024px) 300px, 50vw',
        ratio: 1,
      };
    },
  },
  setup() {},
});
