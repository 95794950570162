import { render, staticRenderFns } from "./TopNavMenu.vue?vue&type=template&id=09a99bbe&scoped=true&"
import script from "./TopNavMenu.vue?vue&type=script&lang=ts&"
export * from "./TopNavMenu.vue?vue&type=script&lang=ts&"
import style0 from "./TopNavMenu.vue?vue&type=style&index=0&id=09a99bbe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09a99bbe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StoreSwitcher: require('/builds/my-jewellery/code/frontend/components/StoreSwitcher.vue').default,TopNavMenuItem: require('/builds/my-jewellery/code/frontend/components/TopNav/TopNavMenuItem.vue').default,TopNavCtaItem: require('/builds/my-jewellery/code/frontend/components/TopNav/TopNavCtaItem.vue').default,TopNavUspItem: require('/builds/my-jewellery/code/frontend/components/TopNav/TopNavUspItem.vue').default})
