//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfButton, SfLoader } from '@storefront-ui/vue';
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import ChevronDownIcon from '~/assets/icons/chevron-down-24.svg';
import TrashIcon from '~/assets/icons/trash.svg';

export default defineComponent({
  name: 'QuantitySelector',
  components: {
    SfLoader,
    SfButton,
    ChevronDownIcon,
    TrashIcon,
  },
  props: {
    qty: {
      type: [Number, String],
      default: 1,
    },
    isInStock: {
      type: Boolean,
      default: true,
    },
    isDeleting: {
      type: Boolean,
      default: false,
    },
    isUpdatingQuantity: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isDeleting = ref(false);
    const quantity = computed({
      get() {
        return props.qty;
      },
      set(value) {
        if (value === 0) {
          isDeleting.value = true;
        }
        emit('input', value);
      },
    });

    return { quantity };
  },
});
