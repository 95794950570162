








import { Document, Pagination as BrPagination, Page, Component } from '@bloomreach/spa-sdk';
import { defineComponent } from '@nuxtjs/composition-api';

import { Store, StoreDocument, StoreAddress, StoreOpeningHours } from '~/types/interfaces/storeGrid';
import getBynderImageUrl from '~/helpers/bynderImage';
import StoreGridItem from '~/components/MyJewellery/StoreGridItem.vue';

interface BrProps {
  page: Page;
  component: Component;
}

export default defineComponent({
  props: {
    component: null,
    page: null,
  },
  components: {
    StoreGridItem,
  },
  setup(props) {
    const { page, component } = <BrProps>props;
    const isPreview = page.isPreview();
    const { pagination: paginationRef } = component.getModels();
    const pagination = paginationRef && page.getContent<BrPagination>(paginationRef);

    const documents = pagination?.getItems().map((ref) => page.getContent<Document>(ref)) || [];

    const stores: Store[] = documents.map((doc) => {
      const {
        storeName,
        storeImage,
        displayName,
        streetName,
        houseNumber,
        addition,
        postalCode,
        city,
        country,
        bookingUrl,
        bookingImage,
        openingHours: openingHoursRefs,
        adjustedOpeningHours: adjustedOpeningHoursRefs,
      } = doc.getData<StoreDocument>();

      const openingHours = openingHoursRefs
        .map((ref) => page.getContent<Document>(ref))
        .filter((x) => !!x)
        .map((doc: Document) => {
          const { day, hours, note } = doc.getData<StoreOpeningHours>() || {};
          return <StoreOpeningHours>{ day, hours, note };
      });

      const adjustedOpeningHours = adjustedOpeningHoursRefs
        .map((ref) => page.getContent<Document>(ref))
        .filter((x) => !!x)
        .map((doc: Document) => {
          const { day, hours, note } = doc.getData<StoreOpeningHours>() || {};
          return <StoreOpeningHours>{ day, hours, note };
      });

      return <Store>{
        name: displayName,
        address: <StoreAddress>{
          streetName,
          houseNumber,
          addition,
          postalCode,
          city,
          country,
        },
        storeName,
        storeImage: getBynderImageUrl(storeImage),
        storeUrl: doc.getUrl(),
        bookingUrl,
        bookingImage,
        openingHours,
        adjustedOpeningHours,
        document: doc,
      };
    });

    return {
      pagination,
      stores,
      isPreview,
    };
  },
});
