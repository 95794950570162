var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination grid-container"},[_c('div',{staticClass:"load-less-button-container"},[(_vm.showTopPagination)?_c('SfButton',{staticClass:"sf-button--hollow pagination__prev-button",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.showPrevious()}}},[_vm._v("\n      "+_vm._s(_vm.$t('Show previous products'))+"\n    ")]):_vm._e()],1),_vm._v(" "),_c('div',[(_vm.showTopPagination)?_c('p',[_vm._v("\n      "+_vm._s(_vm.$t('Page {currentPage} of {totalPages}', {
          currentPage: _vm.pagination.prependedPage,
          totalPages: _vm.pagination.totalPages,
        }))+"\n    ")]):_vm._e()]),_vm._v(" "),_vm._t("default"),_vm._v(" "),_c('SfPagination',{attrs:{"current":_vm.pagination.currentPage,"total":_vm.pagination.totalPages,"pageParamName":"p"},scopedSlots:_vm._u([{key:"points",fn:function(){return [_vm._v(_vm._s(null))]},proxy:true},{key:"number",fn:function(){return [_vm._v(_vm._s(null))]},proxy:true},{key:"prev",fn:function(){return [_vm._v("\n      "+_vm._s(null)+"\n    ")]},proxy:true},{key:"next",fn:function(ref){
        var isDisabled = ref.isDisabled;
return [(!isDisabled && _vm.canGoNext)?_c('SfButton',{staticClass:"sf-button--hollow pagination__next-button",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.showMore()}}},[_vm._v("\n        "+_vm._s(_vm.$t('Show more products'))+"\n      ")]):_c('span')]}}])}),_vm._v(" "),_c('div',[(_vm.pagination.currentProducts)?_c('p',[_vm._v("\n      "+_vm._s(_vm.$t('Page {currentPage} of {totalPages}', {
          currentPage: _vm.pagination.currentPage,
          totalPages: _vm.pagination.totalPages,
        }))+"\n    ")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }