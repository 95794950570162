




































































































































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import {
  SfAccordion,
  SfButton,
  SfCarousel,
  SfChevron,
  SfCircleIcon,
  SfColorPicker,
  SfLoader,
  SfProductCard,
  SfRadio,
} from '@storefront-ui/vue';
import { useCart, useGiftWrapProducts, useUser } from '~/composables';
import getNumericPriceFromString from '~/helpers/stringHelpers';
import ChevronLeftIcon from '~/assets/icons/chevron-left.svg';
import ChevronRightIcon from '~/assets/icons/chevron-right.svg';
import BasketIcon from '~/assets/icons/shopping-bag.svg';

export default defineComponent({
  components: {
    SfAccordion,
    SfButton,
    SfCarousel,
    SfChevron,
    SfCircleIcon,
    SfColorPicker,
    SfLoader,
    SfProductCard,
    SfRadio,
    BasketIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  setup(_, { emit }) {
    const { isAuthenticated } = useUser();
    const { loading: cartLoading, isInCart, addItem: addItemToCart } = useCart();
    const { load: loadProducts, loading, products } = useGiftWrapProducts();
    const productAddingToCart = ref('');
    const isGiftWrappingOpen = ref(false);
    const { i18n } = useContext();

    const translatedCategoryName = {
      Kaarten: i18n.t('Cards'),
      'Gift bags': i18n.t('Gift bags'),
      'Gift boxes': i18n.t('Gift boxes'),
      'Sac cadeau': i18n.t('Gift bags'),
      'Pochette cadeau': i18n.t('Gift boxes'),
    };

    const addGiftItemToCart = async (product: any) => {
      productAddingToCart.value = product.sku;
      await addItemToCart({
        // @ts-ignore
        product: { sku: product.sku, __typename: 'SimpleProduct' },
        quantity: 1,
      }).catch((err) => {});
      productAddingToCart.value = '';
      emit('added-item', product);
    };

    const loadGiftWrapProducts = () => {
      if (!products.value && !loading.value) loadProducts();
    };

    const toggleGiftWrapping = () => {
      loadGiftWrapProducts();
      isGiftWrappingOpen.value = !isGiftWrappingOpen.value;
    };

    const carouselSettings = {
      type: 'slider',
      perView: 3,
      bound: true,
      slidePerPage: true,
      rewind: false,
      gap: 8,
      breakpoints: { 1023: { perView: 3 } },
    };

    return {
      loading,
      cartLoading,
      products,
      isAuthenticated,
      isInCart,
      addItemToCart,
      addGiftItemToCart,
      getNumericPriceFromString,
      loadGiftWrapProducts,
      carouselSettings,
      productAddingToCart,
      isGiftWrappingOpen,
      toggleGiftWrapping,
      translatedCategoryName,
    };
  },
});
