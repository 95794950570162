










import { computed, defineComponent, ref, onMounted, nextTick, useContext } from '@nuxtjs/composition-api';
import HTMLContent from '~/components/HTMLContent.vue';
import { SfLoader } from '@storefront-ui/vue';
import logger from '~/utilities/logger';

export default defineComponent({
  name: 'TikTok',
  components: { HTMLContent, SfLoader },
  computed: {
    params() {
      return this.component?.model?.meta?.paramsInfo;
    },
    isPreview() {
      return this.page?.isPreview();
    },
    title() {
      return this.getParam('title', false);
    },
    tiktokUrl() {
      return this.getParam('url', false);
    },
  },
  methods: {
    getParam(key: string | number, fallback = false) {
      return this.params[key] || fallback;
    },
  },
  props: {
    component: null,
    page: null,
  },
  setup(props) {
    const htmlContent = ref('');
    const url = computed(() => {
      return props?.component?.model?.meta?.paramsInfo?.url;
    });

    const get = async () => {
      const context = useContext();
      htmlContent.value = await context.$axios
        .get('https://www.tiktok.com/oembed?url=' + url.value)
        .then((res) => res.data)
        .then((data) => {
          nextTick(() => {
            htmlContent.value = data.html;
            embedTiktok();
          });
          return data.html;
        })
        .catch((error) => {
          logger.warn('Failed to fetch tiktok embed', error);
          return {};
        });
    };

    onMounted(() => {
      get();
    });

    return {
      htmlContent,
    };
  },
});

function embedTiktok() {
  const scriptIdentifier = 'tiktokScript';
  document.getElementById(scriptIdentifier)?.remove();
  let script = document.createElement('script');
  script.setAttribute('id', scriptIdentifier);
  script.setAttribute('src', 'https://www.tiktok.com/embed.js');
  document.head.appendChild(script);
}
