








import { defineComponent, useContext } from '@nuxtjs/composition-api';

import VisaLogo from '~/static/logos/visa.svg';
import Klarna from '~/static/logos/klarna.svg';
import PaypalLogo from '~/static/logos/paypal.svg';

export default defineComponent({
  name: 'ProductBrands',
  components: {
    VisaLogo,
    Klarna,
    PaypalLogo,
  },
  setup() {
    const context = useContext();
    const locale = context.app?.i18n?.locale;

    return {
      locale,
      logos: [
        { logo: VisaLogo, width: 44, height: 14 },
        { logo: Klarna, width: 48, height: 24 },
        { logo: PaypalLogo, width: 56, height: 15 },
      ],
    };
  },
});
