































































import { computed, defineComponent, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { reviewGetters } from '@vue-storefront/magento';
import { SfButton, SfLoader, SfPagination, SfReview } from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';
import { useReview, useProduct } from '~/composables';
import { usePageStore, useProductStore, useUiState } from '~/stores';

import ProductAddReviewForm from '~/components/Product/ProductAddReviewForm.vue';
import Sidebar from '~/components/Sidebar/Sidebar.vue';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ProductReviewsSidebar',
  components: {
    LazyHydrate,
    ProductAddReviewForm,
    SfButton,
    SfLoader,
    SfReview,
    SfPagination,
    Sidebar,
  },
  setup() {
    const { routeData } = usePageStore();
    const { products } = storeToRefs(useProductStore());
    const pageSize = 10;
    const totalReviews = computed(() => products.value[routeData?.sku.value]?.review_count || 0);
    const { search: searchReviews, loading: reviewsLoading } = useReview();
    const uiState = useUiState();
    const { toggleProductReviewsSidebar } = uiState;
    const { isProductReviewsSidebarOpen } = storeToRefs(uiState);
    const { getReviewId, getReviewAuthor, getReviewDate, getReviewMessage } = reviewGetters;
    const showAddReviewForm = ref(false);
    const reviews = ref(null);
    const totalPages = computed(() => Math.ceil(totalReviews.value / pageSize));
    const currentPage = ref(1);
    const route = useRoute();

    const productId = routeData?.sku || route.value?.params?.id;

    const { loading } = useProduct(`product-${productId}`);

    const getSearchQuery = () => ({
      pageSize,
      currentPage: currentPage.value,
      filter: {
        sku: {
          eq: routeData?.sku,
        },
      },
    });

    const fetchReviews = async (query = getSearchQuery()) => {
      const productReviews = await searchReviews(query);
      const baseReviews = Array.isArray(productReviews) ? productReviews[0] : productReviews;
      reviews.value = [...(reviews.value ?? []), ...reviewGetters.getItems(baseReviews)];
      showAddReviewForm.value = Boolean(!reviews.value?.length);
    };

    const fetchReviewsNextPage = async () => {
      currentPage.value = Math.min(currentPage.value + 1, totalPages.value);
      await fetchReviews();
    };

    watch(isProductReviewsSidebarOpen, async () => {
      if (isProductReviewsSidebarOpen.value && !reviews.value) await fetchReviews();
    });

    return {
      productId,
      reviews,
      loading,
      reviewsLoading,
      showAddReviewForm,
      isProductReviewsSidebarOpen,
      toggleProductReviewsSidebar,
      getReviewId,
      getReviewAuthor,
      getReviewDate,
      getReviewMessage,
      currentPage,
      totalPages,
      fetchReviewsNextPage,
    };
  },
});
