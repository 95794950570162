var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.option)?_c('div',{staticClass:"product-variant product-variant--color"},[_c('div',{staticClass:"product-variant__heading"},[_c('SfProperty',{attrs:{"name":_vm.$t('Color'),"value":_vm.selectedVariant.label}})],1),_vm._v(" "),_c('div',{staticClass:"product-variant__colors"},_vm._l((_vm.option.values),function(color){return _c('SfColor',{key:color.uid,staticClass:"product-variant__color",class:{
        'product-variant__color--out-of-stock': _vm.getDisabledForOption(color.out_of_stock) || _vm.disabled,
      },attrs:{"color":color.swatch_data.value.indexOf('#') === 0
          ? color.swatch_data.value
          : ("url(https://www.my-jewellery.com/media/attribute/swatch/swatch_image/30x20" + (_vm.productGetters.getSwatchData(
              color.swatch_data
            )) + ")"),"selected":_vm.selectedVariant.uid === color.uid,"disabled":_vm.disabled,"data-sku":_vm.selectedProductSku(color.uid),"data-id":_vm.selectedProductId(color.uid),"data-label":color.label,"data-attribute_code":_vm.option.attribute_code},on:{"click":function () { return _vm.selectVariant(color); }},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('SfBadge',{staticClass:"sf-color__badge",class:{ 'sf-color__badge--selected': _vm.selectedVariant.uid === color.uid }},[_c('CheckmarkIcon',{style:({
              color: _vm.getContrast(color.swatch_data.value.indexOf('#') === 0 ? color.swatch_data.value : null),
            })}),_vm._v(" "),(_vm.getDisabledForOption(color.out_of_stock))?_c('BellIcon',{attrs:{"id":"bell-icon"}}):_vm._e()],1)]},proxy:true}],null,true)})}),1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }