var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product__actions"},[_vm._t("default",function(){return [_c('SfNotification',{attrs:{"visible":_vm.errorNoVariantSelected && !_vm.canAddProductToCart,"message":_vm.$t('Please select {names}', {
          names: _vm.productNames,
        }),"persistent":false}}),_vm._v(" "),(_vm.product.stock_status === 'OUT_OF_STOCK')?[_c('ProductSoldOutForm',[_c('AddToWishlist',{staticClass:"form-button product__add-to-wishlist sf-button--outline product__add-to-wishlist-button",attrs:{"type":"button","product":_vm.product}})],1)]:(_vm.product.__typename === 'GroupedProduct')?[_c('GroupedProductSelector',{attrs:{"can-add-to-cart":_vm.canAddProductToCart},on:{"update-price":function($event){_vm.basePrice = $event}},scopedSlots:_vm._u([{key:"add-to-cart-button",fn:function(ref){
        var addToCart = ref.addToCart;
return [_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('product_add-to-cart'),expression:"'product_add-to-cart'"}],staticClass:"color-primary sf-button grouped_items--add-to-cart",attrs:{"disabled":!_vm.canAddProductToCart || _vm.isLoadingAddToCart || _vm.hasJustAddedToCart,"data-sku":_vm.selectedProduct.sku,"data-id":_vm.selectedProduct.id},on:{"click":function($event){return _vm.handleCustomAddToCart(addToCart)}}},[_vm._v("\n            "+_vm._s(_vm.addToCartButtonText)+"\n            "),(_vm.isLoadingAddToCart)?_c('div',{staticClass:"adding-to-cart"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')]):_vm._e()])]}}])})]:(_vm.product.__typename === 'BundleProduct')?[_c('BundleProductSelector',{attrs:{"can-add-to-cart":_vm.canAddProductToCart},on:{"update-price":function($event){_vm.basePrice = $event}},scopedSlots:_vm._u([{key:"add-to-cart-button",fn:function(ref){
        var addToCart = ref.addToCart;
return [_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('product_add-to-cart'),expression:"'product_add-to-cart'"}],staticClass:"color-primary bundle_products--add-to-cart",class:{ 'is-adding-to-cart--button': _vm.isLoadingAddToCart },attrs:{"disabled":!_vm.canAddProductToCart || _vm.isLoadingAddToCart || _vm.hasJustAddedToCart,"data-sku":_vm.selectedProduct.sku,"data-id":_vm.selectedProduct.id},on:{"click":function($event){return _vm.handleCustomAddToCart(addToCart)}}},[_vm._v("\n            "+_vm._s(_vm.addToCartButtonText)+"\n            "),(_vm.isLoadingAddToCart)?_c('div',{staticClass:"adding-to-cart"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')]):_vm._e()])]}}])})]:_c('SfAddToCart',{directives:[{name:"e2e",rawName:"v-e2e",value:('product_add-to-cart'),expression:"'product_add-to-cart'"}],staticClass:"product__add-to-cart",scopedSlots:_vm._u([{key:"quantity-select-input",fn:function(){return [_vm._v(_vm._s(null))]},proxy:true},{key:"add-to-cart-btn",fn:function(){return [_c('SfButton',{staticClass:"sf-add-to-cart__button sf-button",class:{
            'is-disabled--button': !_vm.canAddProductToCart || _vm.isLoadingAddToCart || _vm.hasJustAddedToCart,
          },attrs:{"data-sku":_vm.selectedProduct.sku,"data-id":_vm.selectedProduct.id},on:{"click":function($event){return _vm.addItemToCart()}}},[_vm._v("\n          "+_vm._s(_vm.addToCartButtonText)+"\n          "),(_vm.isLoadingAddToCart)?_c('div',{staticClass:"adding-to-cart"},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')]):_vm._e()]),_vm._v(" "),(!_vm.isAtcModal)?_c('ProductBrands'):_vm._e()]},proxy:true}])})]}),_vm._v(" "),_vm._t("additional-actions",function(){return [(_vm.product.stock_status !== 'OUT_OF_STOCK')?_c('div',{staticClass:"product__additional-actions"},[(!_vm.isAtcModal)?_c('AddToWishlist',{staticClass:"form-button product__add-to-wishlist sf-button--outline product__add-to-wishlist-button",attrs:{"product":_vm.product,"selectedVariant":_vm.selectedProduct}}):_vm._e()],1):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }