//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
import { computed } from '@vue/composition-api';

export default defineComponent({
  name: 'SaleLabel',
  props: {
    originalPrice: {
      type: [String, Number],
      default: null,
      required: false,
    },
    discountPrice: {
      type: [String, Number],
      default: null,
      required: false,
    },
  },
  setup({ originalPrice, discountPrice }) {
    const saleLabel = computed(() => {
      if (!discountPrice || discountPrice === '' || !originalPrice) return;
      const originalPriceFloat =
        typeof originalPrice === 'string'
          ? parseFloat(originalPrice.replace(',', '.').replace(/^\D+/g, ''))
          : originalPrice;
      const discountPriceFloat =
        typeof discountPrice === 'string'
          ? parseFloat(discountPrice.replace(',', '.').replace(/^\D+/g, ''))
          : discountPrice;
      const percentageDiscount = Math.round(((originalPriceFloat - discountPriceFloat) / originalPriceFloat) * 100);

      if (percentageDiscount === 0) {
        return;
      }

      return `-${percentageDiscount}%`;
    });

    return {
      saleLabel,
    };
  },
});
