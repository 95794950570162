




import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useProductStore, useCartStore } from '~/stores';
import { SfButton } from '@storefront-ui/vue';
import PlusIcon from '~/assets/icons/plus.svg';
import { useProduct } from '~/composables';
import { storeToRefs } from 'pinia';
import logger from '~/utilities/logger';
import type { Product } from '@/types/interfaces/product';

export default defineComponent({
  components: {
    PlusIcon,
    SfButton,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { getProductDetails } = useProduct();
    const { setProduct } = useProductStore();
    const { activeProductSku } = storeToRefs(useProductStore());
    const { showAtcModal } = storeToRefs(useCartStore());

    const loading = ref(false);

    const toggleAtcModal = async () => {
      loading.value = true;
      const sku = props.product?.sku;
      activeProductSku.value = sku;

      showAtcModal.value = true;

      // Otherwise, fetch it
      const result = await getProductDetails({
        filter: {
          sku: {
            eq: sku,
          },
        },
      });

      if (!result) {
        return logger.error('Product not found');
      }
      await setProduct(result?.items[0] as Product);
      loading.value = false;
    };

    return {
      toggleAtcModal,
    };
  },
});
