







import { defineComponent } from '@nuxtjs/composition-api';
import { SfRating } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ProductCardRating',
  components: {
    SfRating,
  },
  props: {
    rating: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const starIconPath =
      'M11.963,0l2.732,8.407h8.84l-7.152,5.2,2.732,8.407-7.152-5.2-7.152,5.2L7.543,13.6.392,8.407h8.84Z';

    return { starIconPath };
  },
});
