import { render, staticRenderFns } from "./FiltersSortingSidebar.vue?vue&type=template&id=988e4aac&"
import script from "./FiltersSortingSidebar.vue?vue&type=script&lang=ts&"
export * from "./FiltersSortingSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./FiltersSortingSidebar.vue?vue&type=style&index=0&id=988e4aac&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Sidebar: require('/builds/my-jewellery/code/frontend/components/Sidebar/Sidebar.vue').default})
