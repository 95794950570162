
















import { useUserShipping } from '@vue-storefront/magento';
import { useCart } from '~/composables';
import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { CustomerAddress } from '@vue-storefront/magento-api';
import { useCartStore } from '~/stores';
import { storeToRefs } from 'pinia';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { useShipping, useBilling } from '~/composables';

import { SfLoader } from '@storefront-ui/vue';
import UserShippingExistingUser from './UserShippingExistingUser.vue';
import UserShippingNewUser from './UserShippingNewUser.vue';

export default defineComponent({
  name: 'UserShipping',
  components: {
    SfLoader,
    UserShippingExistingUser,
    UserShippingNewUser,
  },
  setup() {
    const { shipping: userShipping, load: loadUserShipping, loading: userShippingLoading } = useUserShipping();
    const { save: saveShipping } = useShipping();
    const { save: saveBilling } = useBilling();
    const cartStore = useCartStore();
    const { cart, load: loadCart } = useCart();
    const { selectedShippingAddressId, selectedBillingAddressId } = storeToRefs(cartStore);
    const hasExistingAddresses = ref();
    const loading = ref(true);

    const setAddressOnShipping = async (address: CustomerAddress) => {
      // Format data
      const shippingDetailsData = {
        ...address,
        customerAddressId: address.id || null,
        region: address.region.region_code,
        region_id: address.region.region_id,
      };
      delete shippingDetailsData.default_billing;
      delete shippingDetailsData.default_shipping;

      await mergeItem('checkout', { shipping: shippingDetailsData });
      await saveShipping({ shippingDetails: shippingDetailsData });

      if (!selectedBillingAddressId.value) {
        await setAddressOnBilling(address);
      }

      loadCart();
      loadUserShipping();
    };

    const setAddressOnBilling = async (address) => {
      const billingSameAsShipping = computed(() => selectedShippingAddressId === selectedBillingAddressId);

      const billingDetailsData = {
        billingDetails: {
          ...address,
          customerAddressId: address.id || null,
          region: address.region?.region_code,
          region_id: address.region?.region_id,
          sameAsShipping: billingSameAsShipping.value,
        },
      };

      delete billingDetailsData.billingDetails.default_billing;
      delete billingDetailsData.billingDetails.default_shipping;

      await saveBilling(billingDetailsData);
      await mergeItem('checkout', { billing: billingDetailsData });
    };

    onMounted(async () => {
      if (!cart.value.id) {
        await loadCart();
      }
      await loadUserShipping();
      hasExistingAddresses.value = userShipping.value?.addresses?.length > 0 || false;
      loading.value = false;
    });

    return {
      hasExistingAddresses,
      loading,
      setAddressOnShipping,
      setAddressOnBilling,
    };
  },
});
