







import { defineComponent, onMounted, useContext, useRouter } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'LogoutSuccess',
  setup() {
    const { localePath, app, $brxmEndpointResolver } = useContext();
    const router = useRouter();
    onMounted(() => {
      router.push(`${localePath('/customer/account/login')}`);
    });
  },
});
