



























import BoutiqueIcon from '~/assets/icons/boutique.svg';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CtaBoutique',
  components: {
    BoutiqueIcon,
  },
  computed: {
    content() {
      const { document } = this.component?.getModels();
      const content = this.page?.getContent(document);
      return content?.model?.data || {};
    },
    document() {
      const { document } = this.component?.getModels();
      return this.page?.getContent(document);
    },
    internalLink() {
      return this.page?.getContent(this.content.ctaLink)?.getUrl();
    },
    externalLink() {
      return this.content?.ctaUrl || false;
    },
    isPreview() {
      return this.page?.isPreview();
    },
  },
  props: {
    component: null,
    page: null,
  },
});
