//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfHeading, SfButton } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import CardItem from './CardItem.vue';

export default defineComponent({
  name: 'RecommendedArticles',
  components: {
    LazyHydrate,
    SfHeading,
    SfButton,
    CardItem,
  },
  props: {
    banners: null,
    params: null,
    bannerType: null,
  },
  data: () => ({
    currentIndex: 0,
    intersectionObserver: null,
  }),
  computed: {
    bgColor() {
      return this.bannerType?.match(/gray|pink|sand|white/) || 'white';
    },
    styles() {
      return {
        backgroundColor: `var(--${this.bgColor}-background-color)`,
      };
    },
    cardStyles() {
      return {
        backgroundColor: this.bgColor === 'white' ? 'var(--sand-background-color)' : 'var(--white-color)',
      };
    },
  },
  methods: {
    slideTo(index) {
      const left = this.$refs.card[index].$el.offsetLeft;

      this.$refs.grid.scrollTo({
        top: 0,
        left,
        behavior: 'smooth',
      });
      this.currentIndex = index;
    },
    getCategory(banner) {
      return banner?.hideCategory ? '' : banner.categoryManually ? banner.categoryManually : banner.category || '';
    },
  },
  mounted() {
    this.intersectionObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const index = [...entry.target.parentElement.children].indexOf(entry.target);
          this.currentIndex = index;
        }
      },
      {
        threshold: [0.25, 1],
      },
    );

    this.$refs.card.forEach((card) => {
      this.intersectionObserver.observe(card.$el);
    });
  },
  unmounted() {
    this.intersectionObserver?.disconnect();
  },
});
