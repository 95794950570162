import { render, staticRenderFns } from "./CartVariant.vue?vue&type=template&id=2c407f11&scoped=true&"
import script from "./CartVariant.vue?vue&type=script&lang=ts&"
export * from "./CartVariant.vue?vue&type=script&lang=ts&"
import style0 from "./CartVariant.vue?vue&type=style&index=0&id=2c407f11&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c407f11",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductVariantMatrix: require('/builds/my-jewellery/code/frontend/components/Product/ProductVariantMatrix.vue').default,ProductActions: require('/builds/my-jewellery/code/frontend/components/Product/ProductActions.vue').default})
