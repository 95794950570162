






































import { defineComponent } from '@nuxtjs/composition-api';
import { SfCard, SfHeading, SfLink } from '@storefront-ui/vue';

import HTMLContent from '~/components/HTMLContent.vue';
import { truncateWords } from '@/helpers/stringHelpers';
import BynderAsset from '../BynderAsset.vue';

import ChevronRightIcon from '~/assets/icons/chevron-right.svg';

export default defineComponent({
  name: 'CardItem',
  props: {
    asset: String || Boolean,
    title: String || Boolean,
    category: String || Boolean,
    description: String || Boolean,
    image: String || Boolean,
    link: String || Boolean,
    content: Object,
  },
  components: {
    HTMLContent,
    SfCard,
    SfHeading,
    SfLink,
    BynderAsset,
    ChevronRightIcon,
  },
  methods: {
    truncateWords,
  },
  computed: {
    bynderAsset() {
      return {
        data: this.asset,
        size: '(max-width: 1023px) 100vw, 400px',
        ratio: 1,
      };
    },
    placeholderImage() {
      // To provide the SfCard with its required image
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    },
  },
});
