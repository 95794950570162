









import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api';
import { useRelatedProducts } from '@vue-storefront/magento';
import { onSSR } from '@vue-storefront/core';
import { usePageStore } from '~/stores';

const GIFTCARD_HOLDER_UID = 'NDk2';

export default defineComponent({
  name: 'RelatedProducts',
  setup() {
    const { routeData } = usePageStore();
    const route = useRoute();

    const id = routeData?.sku || route.value?.params?.id;
    const { search, products, loading } = useRelatedProducts(id);

    const filteredProducts = computed(() => {
      return products.value.filter((product) => product?.categories?.[0]?.uid !== GIFTCARD_HOLDER_UID);
    });

    onSSR(async () => {
      const baseSearchQuery = {
        filter: {
          sku: {
            eq: id,
          },
        },
      };
      await search(baseSearchQuery);
    });

    return {
      products,
      filteredProducts,
      loading,
    };
  },
});
