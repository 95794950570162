//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfRadio, SfModal, SfInput } from '@storefront-ui/vue';
import { computed, defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { useCartStore } from '~/stores';
import ChevronDownIcon from '~/assets/icons/chevron-down-24.svg';
import PickupLocationButton from './PickupLocationButton.vue';

export default defineComponent({
  name: 'PickupLocations',
  components: {
    SfRadio,
    SfModal,
    SfInput,
    ChevronDownIcon,
    PickupLocationButton,
  },
  setup() {
    const { shipping, selectedShippingAddress } = useCartStore();
    const showPickupLocations = ref(false);
    const isModalVisible = ref(false);
    const postalCodeSearch = ref('');

    const pickupLocations = computed(() => {
      return shipping.pickup_locations || [];
    });

    const filteredPickupLocations = computed(() => {
      if (!postalCodeSearch.value) return pickupLocations.value;
      return pickupLocations.value.filter((location) => {
        return (
          location.address.postalCode.slice(0, postalCodeSearch.value.length).toLowerCase() ===
          postalCodeSearch.value.toLowerCase()
        );
      });
    });

    onMounted(() => {
      if (selectedShippingAddress?.company) showPickupLocations.value = true;
    });

    return {
      pickupLocations,
      showPickupLocations,
      isModalVisible,
      postalCodeSearch,
      filteredPickupLocations,
    };
  },
});
