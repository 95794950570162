import { render, staticRenderFns } from "./ProductVariantSelectionModal.vue?vue&type=template&id=5a63995b&scoped=true&"
import script from "./ProductVariantSelectionModal.vue?vue&type=script&lang=ts&"
export * from "./ProductVariantSelectionModal.vue?vue&type=script&lang=ts&"
import style0 from "./ProductVariantSelectionModal.vue?vue&type=style&index=0&id=5a63995b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a63995b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WishlistVariant: require('/builds/my-jewellery/code/frontend/components/Wishlist/WishlistVariant.vue').default,CartVariant: require('/builds/my-jewellery/code/frontend/components/Cart/CartVariant.vue').default})
