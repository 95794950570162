








import { Document, Pagination as BrPagination, Page, Component } from '@bloomreach/spa-sdk';
import { defineComponent } from '@nuxtjs/composition-api';

import { Store, StoreDocument, StoreAddress } from '~/types/interfaces/storeGrid';
import getBynderImageUrl from '~/helpers/bynderImage';
import PiercingStoreGridItem from './GridItem.vue';

interface BrProps {
  page: Page;
  component: Component;
}

export default defineComponent({
  props: {
    component: null,
    page: null,
  },
  components: {
    PiercingStoreGridItem,
  },
  setup(props) {
    const { page, component } = <BrProps>props;
    const isPreview = page.isPreview();
    const { pagination: paginationRef } = component.getModels();
    const pagination = paginationRef && page.getContent<BrPagination>(paginationRef);
    const documents = pagination?.getItems().map((ref) => page.getContent<Document>(ref)) || [];
    const maxSize = props.component?.model?.meta?.paramsInfo?.customSize ?? 1000;
    const stores: Store[] = documents
      ?.map((doc) => {
        const {
          storeName,
          storeImage,
          displayName,
          streetName,
          houseNumber,
          addition,
          postalCode,
          city,
          country,
          bookingUrl,
          bookingImage,
        } = doc.getData<StoreDocument>();

        return <Store>{
          name: displayName,
          address: <StoreAddress>{
            streetName,
            houseNumber,
            addition,
            postalCode,
            city,
            country,
          },
          storeName,
          storeImage: getBynderImageUrl(storeImage),
          storeUrl: doc.getUrl(),
          bookingUrl: page.getContent(bookingUrl)?.getUrl(),
          bookingImage: getBynderImageUrl(bookingImage),
          document: doc,
        };
      })
      .filter((s) => s.bookingImage)
      .slice(0, maxSize);

    const cities = stores
      .map((store) => {
        return store.address.city;
      })
      .reduce(function (acc, curr) {
        return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
      }, {});

    const piercingStores = stores.map((store) => ({
      ...store,
      displayStreetName: cities?.[store.address.city] > 1 || false, // allStores.find((s) => s.address.city === s),
    }));

    return {
      pagination,
      stores: piercingStores,
      isPreview,
    };
  },
});
