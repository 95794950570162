

































import { computed, ref } from '@nuxtjs/composition-api';
import { SfRange } from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';
import { range } from '~/helpers/arrayHelpers';

export default {
  name: 'PriceRangeSlider',
  components: {
    LazyHydrate,
    SfRange,
  },
  props: {
    prices: {
      type: Array,
      default: [],
    },
    selected: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const step = 1; // Steps
    const height = 80; // Height of the SVG element
    const sortedPrices = computed(() =>
      (props.prices as [{ label: string; count: number }])
        .map((p) => ({ price: parseFloat(p.label), count: p.count }))
        .sort((a, b) => a.price - b.price),
    );
    const highestPrice = ref(150); // computed(() => Math.max(...allPrices.value));
    const priceSteps = computed(() => range(0, highestPrice.value, step));
    const highestPriceStep = computed(() => Math.max(...priceSteps.value));

    const rangeMinMax = ref(
      props.selected ? (props.selected as string)?.split(',') : [0, priceSteps.value?.[priceSteps.value?.length - 1]],
    );

    const priceStepsCounts = computed(() => {
      let counts = Array(priceSteps.value.length).fill(0);
      priceSteps.value.reduce((a, v, i) => {
        counts[i] = sortedPrices.value
          .filter((p) => p.price > priceSteps.value[Math.max(i - 1, 0)] && p.price <= v)
          .reduce((s, c) => s + c.count, 0);
      });
      return counts;
    });

    const highestCount = computed(() => Math.max(...priceStepsCounts.value));

    const between = (x, min, max) => x >= min && x <= max;

    const getHardEdgeStep = (step) => {
      return `${step},${height} `;
    };

    const activeRange = computed(
      () =>
        priceStepsCounts.value
          .map(
            (count, index) =>
              `${index * step === rangeMinMax.value[0] ? getHardEdgeStep(index * step) : ''}${index * step},${
                between(index * step, rangeMinMax.value[0], rangeMinMax.value[1])
                  ? Math.min(Math.max(height * ((highestCount.value - count) / highestCount.value), 3), height - 2)
                  : height
              }`,
          )
          .join(' ') + ` ${highestPriceStep.value},${height} 0,${height}`,
    );

    const inactiveRange = computed(
      () =>
        priceStepsCounts.value
          .map(
            (count, index) =>
              `${index * step},${Math.min(
                Math.max(height * ((highestCount.value - count) / highestCount.value), 3),
                height - 2,
              )}`,
          )
          .join(' ') + ` ${highestPriceStep.value},${height} 0,${height}`,
    );

    const rangeSize = computed(() => priceStepsCounts.value.length * step - step);

    const changeRange = (r, shouldEmit = false) => {
      rangeMinMax.value = r;
      if (shouldEmit) emit('change', r);
    };

    return {
      highestPriceStep,
      rangeSize,
      rangeMinMax,
      activeRange,
      inactiveRange,
      height,
      changeRange,
      sortedPrices,
      priceStepsCounts,
    };
  },
};
