//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onSSR } from '@vue-storefront/core';
import LazyHydrate from 'vue-lazy-hydration';
import {
  SfLoader,
  SfTabs,
  SfButton,
  SfLink,
  SfProductCard,
  SfProductCardHorizontal,
  SfPagination,
  SfSelect,
  SfProperty,
} from '@storefront-ui/vue';
import { computed, defineComponent, useRouter, useRoute, useContext, ref } from '@nuxtjs/composition-api';
import { useCart, productGetters, wishlistGetters } from '@vue-storefront/magento';
import { useUiHelpers, useImage } from '~/composables';
import { wishlistStore, useUiState } from '~/stores';
import SvgImage from '~/components/General/SvgImage.vue';
import TabMenu from '~/components/MyAccount/TabMenu.vue';
import TrashIcon from '~/assets/icons/trash.svg';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'MyWishlist',
  components: {
    SfLoader,
    SfTabs,
    SfButton,
    SfLink,
    SfProductCard,
    SfProductCardHorizontal,
    SfPagination,
    SfSelect,
    SfProperty,
    LazyHydrate,
    SvgImage,
    TabMenu,
    TrashIcon,
  },
  setup() {
    const { loadWishlist, removeItem } = wishlistStore();
    const { loading, wishlist } = storeToRefs(wishlistStore());
    const route = useRoute();
    const { app } = useContext();
    const {
      query: { page, itemsPerPage },
    } = route.value;
    const router = useRouter();
    const th = useUiHelpers();
    const uiState = useUiState();
    const { addItem: addItemToCartBase, isInCart } = useCart();

    const products = computed(() => wishlist.value?.[0]?.items_v2.items);
    const pagination = computed(() => wishlistGetters.getPagination(wishlist[0]));

    const addItemToCart = async ({ product, quantity }) => {
      // eslint-disable-next-line no-underscore-dangle
      const productType = product?.__typename;

      switch (productType) {
        case 'SimpleProduct':
          await addItemToCartBase({
            product,
            quantity,
          }).catch((err) => {});
          break;
        case 'BundleProduct':
        case 'ConfigurableProduct':
          const path = `${productGetters.getSlug(product, product.categories[0])}`;
          await router.push(`${app.localePath(path)}`);
          break;
        default:
          throw new Error(`Product Type ${productType} not supported in add to cart yet`);
      }
    };

    const removeItemFromWishlist = async (product) => {
      await removeItem({ product });
    };

    const { getMagentoImage, imageSizes } = useImage();

    const openTab = ref(1);

    onSSR(async () => {
      await loadWishlist({
        searchParams: {
          currentPage: Number.parseInt(page, 10) || 1,
          pageSize: Number.parseInt(itemsPerPage, 10) || 10,
        },
      });
    });

    return {
      ...uiState,
      addItemToCart,
      removeItemFromWishlist,
      isInCart,
      loading,
      pagination,
      productGetters,
      products,
      th,
      wishlist,
      getMagentoImage,
      imageSizes,
      openTab,
    };
  },
});
