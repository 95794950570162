//
//
//
//
//
//

import { ref, defineComponent, onMounted, onUnmounted } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'LazyLoad',
  setup() {
    const lazyLoadContainer = ref();
    const observer = ref();
    const show = ref(false);

    onMounted(() => {
      observer.value = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              observer.value.disconnect();
              show.value = true;
            }
          });
        },
        {
          root: null,
          rootMargin: '100px',
          threshold: 0.1,
        },
      );

      observer.value.observe(lazyLoadContainer.value);
    });

    onUnmounted(() => {
      observer.value.disconnect();
    });

    return {
      show,
      lazyLoadContainer,
    };
  },
});
