







import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { defineComponent } from '@nuxtjs/composition-api';
import NestedLeftMenu from './NestedLeftMenu.vue';
import { useMenu } from '~/composables/useMenu';

export default defineComponent({
  name: 'LeftMenu',
  components: {
    NestedLeftMenu,
  },
  props: {
    component: {
      type: Object as () => BrComponent,
      required: true,
    },
    page: {
      type: Object as () => Page,
      required: true,
    },
  },
  setup(props) {
    const { menu } = useMenu(props);
    return {
      menu,
    };
  },
});
