










import { defineComponent, computed, onMounted, ref } from '@nuxtjs/composition-api';
import { useExponeaStore } from '~/stores';
import useExponeaConstants from '~/constants/exponea';

export default defineComponent({
  name: 'LastViewedProducts',
  setup() {
    const exponeaStore = useExponeaStore();
    const { PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID, PRODUCT_PAGE_BESTSELLERS_ID } = useExponeaConstants();
    const lastViewed = ref([]);
    const bestSellers = ref([]);
    const recommendationId = ref(PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID);

    const isLoadingLastViewed = ref(true);

    const loading = computed(() => {
      if (isLoadingLastViewed.value) return true;
      if (lastViewed.value.length === 0) {
        recommendationId.value = PRODUCT_PAGE_BESTSELLERS_ID;
        return exponeaStore.getRecommendationLoadingStateById(PRODUCT_PAGE_BESTSELLERS_ID);
      }
      return exponeaStore.getRecommendationLoadingStateById(PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID);
    });

    const getLastViewedProducts = async () => {
      isLoadingLastViewed.value = true;
      // This recommendation updates on every page visit, so force load
      await exponeaStore.getRecommendation({
        id: PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID,
        fillWithRandom: false,
        size: 6,
        force: true,
      });

      if (!lastViewed.value.length) {
        await exponeaStore.getRecommendation({ id: PRODUCT_PAGE_BESTSELLERS_ID, fillWithRandom: true, size: 6 });
      }

      lastViewed.value = exponeaStore.getRecommendationById(PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID) || [];

      if (!lastViewed.value.length) {
        bestSellers.value = exponeaStore.getRecommendationById(PRODUCT_PAGE_BESTSELLERS_ID);
      }

      isLoadingLastViewed.value = false;
    };

    onMounted(async () => await getLastViewedProducts());

    return {
      lastViewed,
      bestSellers,
      loading,
      recommendationId,
    };
  },
});
