







































































import LazyHydrate from 'vue-lazy-hydration';
import { SfBadge, SfButton, SfLink, SfSkeleton } from '@storefront-ui/vue';
import { computed, defineComponent, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { useBloomreachDiscoveryStore, usePageStore, useUiState } from '~/stores';

import FiltersSortingSidebar from '~/components/Category/FiltersSortingSidebar.vue';
import ProductListingCampaign from '~/components/Category/ProductListingCampaign.vue';
import ProductListingUsps from '~/components/Category/ProductListingUsps.vue';
import ProductCard from '~/components/MyJewellery/ProductCard.vue';
import BestsellerProducts from '~/components/Products/BestsellerProducts.vue';
import ProductCardLoading from '~/components/MyJewellery/ProductCardLoading.vue';
import Pagination from '~/components/Category/Pagination.vue';
import FilterIcon from '~/assets/icons/filter.svg';
import ProductRecommendations from '~/components/Category/ProductRecommendations.vue';
import { onSSR } from '@vue-storefront/core';

const PRODUCTS_BEFORE_EVERY_USPS = 12;
const PRODUCTS_BEFORE_EVERY_CAMPAIGN = 4;

export default defineComponent({
  name: 'ProductList',
  components: {
    SfButton,
    SfSkeleton,
    SfLink,
    SfBadge,
    LazyHydrate,
    FiltersSortingSidebar,
    ProductCard,
    ProductCardLoading,
    FilterIcon,
    BestsellerProducts,
    Pagination,
    ProductListingCampaign,
    ProductListingUsps,
    ProductRecommendations,
  },
  setup() {
    const bloomreachDiscoveryStore = useBloomreachDiscoveryStore();
    const { loading, products, appliedFilters, pagination, itemsPerPage, currentCampaign, selectedSortOption } =
      storeToRefs(bloomreachDiscoveryStore);
    const { loaded: pageStoreLoaded } = storeToRefs(usePageStore());
    const uiState = useUiState();
    const { toggleFilterSidebar } = uiState;
    const { isTopHeaderVisible, isHeaderNotificationVisible } = storeToRefs(uiState);

    const totalSelectedFiltersCount = computed(() => {
      return Object.keys(appliedFilters.value).reduce((acc, key) => {
        if (key === 'price') return acc + 1;
        return acc + appliedFilters.value[key].length;
      }, 0);
    });

    const firstPage = ref(1);

    const initProductList = async () => {
      // Determine what images have to be early loaded
      firstPage.value = pagination.value.currentPage;
      pageStoreLoaded.value = true;
    };

    const productList = computed(() => {
      let list = [];
      let index = 0;
      const pageLength = currentCampaign.value ? itemsPerPage.value + 3 : itemsPerPage.value + 2; // +1 for index, +1 for campaign (if there is one) and +1 for USPs
      const currentPage = products.value.length / itemsPerPage.value;

      products.value.forEach((product) => {
        list.push({
          id: product.pid,
          component: 'ProductCard',
          item: {
            product: { ...product },
          },
          index,
          imageLoadingType: 'lazy',
          className: index % 4 === 0 ? 'item-first-column' : '',
        });
        index++;

        // If there is a campaign, show it after every 4 products, once per page
        if (
          currentCampaign.value &&
          (index - PRODUCTS_BEFORE_EVERY_CAMPAIGN) % (itemsPerPage.value + 1) === 0 &&
          index < pageLength * currentPage
        ) {
          list.push({
            id: `campaign-${index}`,
            component: 'ProductListingCampaign',
            item: {
              campaign: currentCampaign.value,
            },
            index,
            className: index % 4 === 0 ? 'item-first-column' : '',
          });
          index++;
        }

        if (index % PRODUCTS_BEFORE_EVERY_USPS === 0) {
          list.push({
            id: `usp-${index}`,
            component: 'ProductListingUsps',
            index,
          });
        }
      });

      return list.map((item, index) => {
        if (item.component !== 'ProductCard') return item;

        let loadingType = 'lazy';

        // First four items per page
        if ([0, 1, 2, 3].some((i) => (index - i) % pageLength === 0)) {
          loadingType = 'eager';
        }

        // Next four items per page
        if ([4, 5, 6, 7].some((i) => (index - i) % pageLength === 0)) {
          loadingType = 'none';
        }

        return {
          ...item,
          imageLoadingType: loadingType,
        };
      });
    });

    onSSR(initProductList);

    onMounted(() => window.addEventListener('popstate', initProductList));

    onUnmounted(() => window.removeEventListener('popstate', initProductList));

    return {
      loading,
      pagination,
      products,
      appliedFilters,
      selectedSortOption,
      itemsPerPage,
      toggleFilterSidebar,
      totalSelectedFiltersCount,
      isTopHeaderVisible,
      isHeaderNotificationVisible,
      productList,
    };
  },
});
