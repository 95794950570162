







































import { computed, defineComponent, PropType, ref, reactive, onMounted } from '@nuxtjs/composition-api';
import { SfBadge, SfButton, SfColor, SfComponentSelect, SfProperty, SfSelect, SfSkeleton } from '@storefront-ui/vue';
import { ConfigurableProductOptions } from '~/stores/useProductStore/useProductStore';
import { useProductStore } from '~/stores';
import { getProductOptionsNames } from '~/helpers/product/productGetters';
import ProductVariantGiftcardPrice from '~/components/Product/ProductVariantGiftcardPrice.vue';
import ProductRelatedColors from '~/components/Product/ProductRelatedColors.vue';
import ProductVariantColor from '~/components/Product/ProductVariantColor.vue';
import ProductVariantSize from '~/components/Product/ProductVariantSize.vue';
import ProductVariantSelect from '~/components/Product/ProductVariantSelect.vue';
import ProductVariantEngraving from '~/components/Product/ProductVariantEngraving.vue';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ProductVariantMatrix',
  components: {
    SfBadge,
    SfButton,
    SfColor,
    SfComponentSelect,
    SfProperty,
    SfSelect,
    SfSkeleton,
    ProductVariantGiftcardPrice,
    ProductRelatedColors,
    ProductVariantColor,
    ProductVariantSize,
    ProductVariantSelect,
    ProductVariantEngraving,
  },
  props: {
    type: {
      type: String as PropType<'wishlist' | 'cart'>,
      required: true,
    },
    showProductRelatedColors: {
      type: Boolean,
      default: true,
    },
    sku: {
      type: String,
      required: true,
    },
    pid: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup({ sku }) {
    const { configurableOptions, products } = storeToRefs(useProductStore());
    const { updateGiftcardConfiguration, updateConfigurableOptions, updateProductMedia } = useProductStore();

    const productConfiguration = ref({});

    const options = computed(() => {
      let options = configurableOptions.value[sku];

      return options
        ?.filter((option) => {
          if (option.attribute_code === 'size' || option.attribute_code === 'base_color') {
            return option.values?.length > 1;
          }
          return option;
        })
        .map((option) => {
          const component = () => {
            switch (option.attribute_code) {
              case 'base_color':
              case 'color':
                return 'ProductVariantColor';
              case 'size':
                return 'ProductVariantSize';
              case 'giftcard_price':
                return 'ProductVariantGiftcardPrice';
              default:
                return 'ProductVariantSelect';
            }
          };

          return {
            ...option,
            component: component(),
          };
        });
    });

    const customizableOptions = computed(() => (products.value[sku]?.options as ConfigurableProductOptions[]) || []);

    const productNames = ref(getProductOptionsNames(products.value[sku]));

    const updateProductConfiguration = ({ key, value }) => {
      productConfiguration.value = {
        ...productConfiguration.value,
        [key]: value,
      };

      updateGiftcardConfiguration(key, value);
      updateConfigurableOptions(productConfiguration.value);

      updateProductMedia(productConfiguration.value);
    };

    onMounted(() => {
      products.value[sku]?.configurable_options?.forEach((option) => {
        const hasOnlyOneAvailableOption = option.values.length === 1;

        updateProductConfiguration({
          key: option.attribute_uid,
          value: hasOnlyOneAvailableOption ? option.values[0].uid : null,
        });
      });
    });

    return {
      productConfiguration,
      options,
      configurableOptions,
      customizableOptions,
      productNames,
      updateProductConfiguration,
    };
  },
});
