//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfHeading, SfButton, SfCallToAction, SfLoader, SfLink } from '@storefront-ui/vue';
import { ref, onMounted, computed } from '@nuxtjs/composition-api';
import CartPreview from '~/components/Checkout/CartPreview.vue';
import { useUserOrder } from '~/composables/useUserOrder';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import DeliveredIcon from '~/assets/icons/checkout-delivered.svg';
import ReceivedIcon from '~/assets/icons/checkout-received.svg';
import ShippedIcon from '~/assets/icons/checkout-shipped.svg';
import FaqIcon from '~/assets/icons/checkout-faq.svg';
import ChatIcon from '~/assets/icons/checkout-chat.svg';
import MailIcon from '~/assets/icons/checkout-mail.svg';
import logger from '~/utilities/logger';

export default {
  components: {
    SfHeading,
    SfButton,
    SfCallToAction,
    SfLoader,
    SfLink,
    CartPreview,
    UserAddressDetails,
    DeliveredIcon,
    ReceivedIcon,
    ShippedIcon,
    FaqIcon,
    ChatIcon,
    MailIcon,
  },
  setup() {
    const { search, loading, error: orderError } = useUserOrder();
    const orderNumber = ref();
    const customerOrders = ref(null);
    const order = computed(() => customerOrders.value?.items?.find((o) => o.number === orderNumber.value));
    const error = computed(() => orderError.value.search);

    onMounted(async () => {
      const params = new URLSearchParams(window.location.search);
      const orderNumberParam = params.get('order');
      const merchantReference = params.get('merchantReference');
      if (orderNumberParam) orderNumber.value = orderNumberParam;
      if (merchantReference) orderNumber.value = merchantReference;

      if (orderNumber.value) {
        return (customerOrders.value = await search({
          filter: { number: { eq: orderNumber.value } },
        }));
      }

      logger.error('No way to identify order');
    });

    return {
      orderNumber,
      order,
      loading,
      error,
    };
  },
};
