















































import { ref, defineComponent, computed } from '@nuxtjs/composition-api';
import { SfCheckbox, SfInput, SfButton, SfLoader } from '@storefront-ui/vue';
import useExponeaApi from '~/composables/useExponeaApi';
import useExponeaConstants from '~/constants/exponea';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
import CheckmarkIcon from '~/assets/icons/checkmark-alt.svg';
import { useUiState } from '~/stores';
import logger from '~/utilities/logger';
import { storeToRefs } from 'pinia';

extend('email', {
  ...email,
  message: 'The email field must be a valid email',
});

export default defineComponent({
  name: 'ProductSoldOutForm',
  components: {
    SfInput,
    SfCheckbox,
    SfButton,
    SfLoader,
    CheckmarkIcon,
    ValidationObserver,
    ValidationProvider,
  },
  setup(_, { emit }) {
    const { soldOutForm } = storeToRefs(useUiState());

    const { SIGNUP_SOURCE_SOLD_OUT_REGISTER, SIGNUP_SOURCE_COMING_SOON_REGISTER } = useExponeaConstants();
    const signupSource = computed(() =>
      soldOutForm.value.type === 'soldOut' ? SIGNUP_SOURCE_SOLD_OUT_REGISTER : SIGNUP_SOURCE_COMING_SOON_REGISTER,
    );
    const { post } = useExponeaApi();
    const loading = ref(false);
    const done = ref(false);

    const form = ref({
      email: '',
      newsletter_signup: false,
      visible: false,
    });

    const submitRegisterForComingBackReminderForm = async () => {
      const { email, newsletter_signup } = form.value;

      const body = {
        customer_ids: { registered: email },
        properties: {
          action: 'new',
          productId: soldOutForm.value.productId, // Magento product ID
          status: 'registered',
          signup_source: signupSource.value,
          optionLabel: soldOutForm.value.optionLabel,
          email,
        },
        event_type: signupSource.value,
      };

      const newsletterBody = {
        customer_ids: { registered: email },
        properties: {
          action: 'new',
          email,
          signup_source: signupSource.value,
          consent_list: [
            {
              action: 'accept',
              category: 'newsletter',
              valid_until: 'unlimited',
            },
            {
              action: 'accept',
              category: 'personalised_email',
              valid_until: 'unlimited',
            },
          ],
        },
        event_type: 'double_opt_in',
      };

      loading.value = true;

      try {
        await post('/customers/events', body, 'track');
        if (newsletter_signup) {
          await post('/customers/events', newsletterBody, 'track');
        }
        pushEventToGa();
      } catch (err) {
        logger.error(err);
      } finally {
        loading.value = false;
        done.value = true;

        window.setTimeout(() => {
          emit('toggleSoldOutForm');
          done.value = false;
        }, 1500);
      }
    };

    const pushEventToGa = () => {
      window.dataLayer.push({
        event: 'GAEvent',
        eventCategory: 'Marketing Automation',
        eventAction: 'Contact Signup',
        eventLabel: signupSource,
        eventValue: undefined,
      });
    };

    return {
      form,
      loading,
      done,
      soldOutForm,
      submitRegisterForComingBackReminderForm,
    };
  },
});
