












import { SfTopBar } from '@storefront-ui/vue';
import Checkmark from '../assets/icons/checkmark.svg';
import { defineComponent, useAsync, useContext } from '@nuxtjs/composition-api';
import { useCache } from '~/composables';
import logger from '~/utilities/logger';

export default defineComponent({
  components: {
    SfTopBar,
    Checkmark,
  },
  setup() {
    const context = useContext();
    const locale = context.app?.i18n?.locale || 'de';
    const { get: getCache, set: setCache } = useCache('topBar');

    const usps = useAsync(() => {
      const messages = getCache(`headerUsps_${locale}`);
      if (messages) return messages;

      return context.$axios
        .get(`${context.$brxmEndpointResolver.resolveDocumentsEndPoint()}/header/headerusp`)
        .then((res) => res.data)
        .then((data) => {
          const content = data?.content[data?.document?.$ref.substring(9)].data || {};

          // Get the messages out of the object
          const messages = Object.keys(content)
            .filter((key) => key.includes('message'))
            .sort()
            .map((key) => content[key]);

          setCache(`headerUsps_${locale}`, messages);

          return messages;
        })
        .catch((error) => {
          logger.warn('Failed to fetch Header USPs', error);
          return {};
        });
    });

    return { usps };
  },
});
