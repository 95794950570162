//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfBreadcrumbs, SfContentPages, SfList, SfLink, SfLoader, SfButton } from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  useContext,
  useRoute,
  useRouter,
  ref,
} from '@nuxtjs/composition-api';
import { unMapMobileObserver } from '@storefront-ui/vue/src/utilities/mobile-observer.js';
import { useBloomreach, useUser } from '~/composables';
import { useCustomerStore } from '~/stores';

import AppFooter from '~/components/AppFooter.vue';
import MyProfile from './MyAccount/MyProfile.vue';
import MyProfileOverview from './MyAccount/MyProfileOverview.vue';
import AddressesDetails from './MyAccount/AddressesDetails.vue';
import MyWishlist from './MyAccount/MyWishlist.vue';
import OrderHistory from './MyAccount/OrderHistory.vue';
import MyReviews from './MyAccount/MyReviews.vue';
import Breadcrumbs from '@/components/MyJewellery/Breadcrumbs.vue';
import TopHeader from '~/components/MyJewellery/TopHeader.vue';
import DropdownBox from '~/components/DropdownBox.vue';

export default defineComponent({
  name: 'MyAccount',
  components: {
    AppFooter,
    AddressesDetails,
    MyProfile,
    MyProfileOverview,
    MyReviews,
    MyWishlist,
    OrderHistory,
    SfBreadcrumbs,
    SfContentPages,
    SfList,
    SfLink,
    SfLoader,
    SfButton,
    Breadcrumbs,
    TopHeader,
    DropdownBox,
  },
  data: () => ({
    skipAuth: ['login', 'register', 'logoutSuccess'],
  }),
  async asyncData(context) {
    const { getPage } = useBloomreach(context);
    const { page, pageData, hrefLangs, configuration } = await getPage('');

    return {
      page,
      pageData,
      hrefLangs,
      configuration,
    };
  },
  head() {
    return {
      title: this.$t('My Account'),
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('My Account'),
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: process.env.NUXT_APP_NAME || 'My Jewellery',
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
  setup() {
    const context = useContext();
    const { localePath, app } = useContext();
    const { mapping } = useBloomreach(context);
    const route = useRoute();
    const router = useRouter();
    const { logout } = useUser();
    const getSlug = (title) => (title ?? '').toLowerCase().replace(' ', '-');
    const getLink = (title) => localePath('/customer/account/' + getSlug(title || ''));
    const contentPages = ref();
    const pages = computed(() => {
      const items = contentPages.value?.items;
      if (!items) return;
      return items.map((item) => {
        return { title: item.title, url: getLink(item.title) };
      });
    });
    const activePage = computed(() => {
      const { path } = route.value;
      const { pageName } = route.value.params;
      if (path.indexOf('wishlist') > -1) {
        return app.i18n.t('My wishlist');
      }
      if (pageName) {
        return (pageName.charAt(0).toUpperCase() + pageName.slice(1)).replace('-', ' ');
      }
      return app.i18n.t('Account dashboard');
    });
    const activePageSlug = computed(() => getSlug(activePage.value));
    const isAuthenticated = computed(() => customerStore.isLoggedIn);
    const routeParams = computed(() => {
      const params = route.value.params;
      return JSON.stringify(params);
    });
    const changeActivePage = async (title) => {
      if (app.i18n.t(title) === app.i18n.t('Log out')) {
        await Promise.all([logout()]);
        await router.push(`${localePath('/customer/account/logoutSuccess')}`);
        return;
      }
      const slugifiedTitle = (title || '').toLowerCase().replace(' ', '-');
      const transformedPath = `/customer/account/${slugifiedTitle}`;
      await router.push(`${localePath(transformedPath)}`);
    };
    const customerStore = useCustomerStore();
    const checkAuthentication = () => {
      if (!customerStore.isLoggedIn) {
        router.push(`${app.localePath('/customer/account/login')}`);
      }
    };
    customerStore.$subscribe(() => {
      checkAuthentication();
    });
    onBeforeMount(() => {
      checkAuthentication();
    });
    onBeforeUnmount(() => {
      unMapMobileObserver();
    });
    return {
      mapping,
      activePage,
      activePageSlug,
      changeActivePage,
      routeParams,
      contentPages,
      pages,
      getSlug,
      isAuthenticated,
    };
  },
});
