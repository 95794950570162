






















import { defineComponent, ref, onMounted, useContext, computed } from '@nuxtjs/composition-api';
import ProductSwiperSlider from './ProductSwiperSlider.vue';
import ProductLightboxSlider from './ProductLightboxSlider.vue';
import Lightbox from './ProductGalleryLightbox.vue';
import ProductLabels from './ProductLabels.vue';
import { useProductStore, useUiState } from '~/stores';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ProductGallerySlider',
  components: {
    ProductSwiperSlider,
    ProductLightboxSlider,
    Lightbox,
    ProductLabels,
  },
  props: {
    sku: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      app: { $device },
    } = useContext();
    const uiState = useUiState();
    const { toggleProductLightBox } = uiState;
    const { isProductLightboxOpen, currentSlideIndex } = storeToRefs(uiState);
    const isMobile = ref(!$device.isDesktop);
    const { media: storeMedia } = storeToRefs(useProductStore());
    const media = computed(() => storeMedia.value[props.sku] || []);

    const slideChange = (slideIndex: number) => {
      currentSlideIndex.value = slideIndex;
    };

    onMounted(() => {
      currentSlideIndex.value = 0;
    });

    return {
      currentSlideIndex,
      slideChange,
      isProductLightboxOpen,
      toggleProductLightBox,
      isMobile,
      media,
    };
  },
});
