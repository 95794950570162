import { render, staticRenderFns } from "./ProductReviewsSidebar.vue?vue&type=template&id=9ff342fe&scoped=true&"
import script from "./ProductReviewsSidebar.vue?vue&type=script&lang=ts&"
export * from "./ProductReviewsSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./ProductReviewsSidebar.vue?vue&type=style&index=0&id=9ff342fe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ff342fe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductAddReviewForm: require('/builds/my-jewellery/code/frontend/components/Product/ProductAddReviewForm.vue').default,Sidebar: require('/builds/my-jewellery/code/frontend/components/Sidebar/Sidebar.vue').default})
