var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"page-footer",attrs:{"id":"footer"}},[_c('FooterTop'),_vm._v(" "),_c('div',{staticClass:"footer__bottom-section"},[_c('div',{staticClass:"footer__bottom-section--wrapper container"},[_c('div',{staticClass:"footer__bottom-section__column need-help footer__collapsed",attrs:{"id":"exponea-footer-bottom-need-help"}},[_c('br-component',{attrs:{"component":"footer1-menu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var page = ref.page;
return [_c('FooterMenu',{attrs:{"component":component,"page":page}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"footer__bottom-section__column about-my-jewellery footer__collapsed",attrs:{"id":"exponea-footer-bottom-about-my-jewellery"}},[_c('br-component',{attrs:{"component":"footer2-menu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var page = ref.page;
return [_c('FooterMenu',{attrs:{"component":component,"page":page}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"footer__bottom-section__column payment-providers footer__collapsed",attrs:{"id":"exponea-footer-bottom-payment-providers"}},[_c('div',{staticClass:"footer__bottom-section__column-inner"},[_c('h4',{domProps:{"innerHTML":_vm._s(_vm.$t('Delivery & Payment'))}}),_vm._v(" "),_c('FooterPaymentProviders')],1)]),_vm._v(" "),_c('div',{staticClass:"footer__bottom-section__column conditions-privacy-reviews",attrs:{"id":"exponea-footer-bottom-conditions-privacy-reviews"}},[_c('div',{staticClass:"footer__bottom-section__column-inner"},[_c('br-component',{attrs:{"component":"footer-legal-menu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var page = ref.page;
return [_c('FooterMenu',{attrs:{"component":component,"page":page}})]}}])})],1)]),_vm._v(" "),_c('div',{staticClass:"footer__bottom-section__column socials",attrs:{"id":"exponea-footer-bottom-socials"}},[_c('div',{staticClass:"footer__bottom-section__column-inner"},[_c('br-component',{attrs:{"component":"footer3-menu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var page = ref.page;
return [_c('FooterMenu',{attrs:{"component":component,"page":page}})]}}])})],1)]),_vm._v(" "),_c('button',{staticClass:"down",attrs:{"id":"back-to-top","aria-label":_vm.$t('Back to top')}},[_c('svg',{attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"24px","height":"24px","viewBox":"0 0 24 24","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M5.6,11.3c0.2,0.1,0.6,0.1,0.8,0l5.1-5.2v12.9c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V6.1l5.1,5.2c0.2,0.2,0.5,0.2,0.7,0c0.2-0.2,0.2-0.5,0-0.7l-6-6c-0.2-0.2-0.5-0.2-0.7,0l-6,6C5.5,10.8,5.5,11.1,5.6,11.3z"}})])]),_vm._v(" "),_c('div',{staticClass:"footer__bottom-section__column my-jewellery-logo",attrs:{"id":"exponea-footer-bottom-my-jewellery-logo"}},[_c('nuxt-link',{attrs:{"to":((_vm.localePath('/')) + "/"),"aria-label":_vm.$t('Back to homepage')}},[_c('Logo',{staticClass:"footer__logo",attrs:{"alt":"MyJewellery"}})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }