




























































import { SfProperty, SfCollectedProduct } from '@storefront-ui/vue';
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { cartGetters } from '@vue-storefront/magento';
import { useCart } from '~/composables';

import _debounce from '~/helpers/debounce';
import { transformImageUrlToSize, ImageSize } from '~/helpers/magentoImage';
import stockStatusEnum from '~/enums/stockStatusEnum';

import QuantitySelector from './QuantitySelector.vue';

import ProductSpecialNote from '../Product/ProductSpecialNote.vue';

import { useCartStore } from '~/stores';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'CartSidebarItem',
  components: {
    SfProperty,
    SfCollectedProduct,
    QuantitySelector,
    ProductSpecialNote,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const cartStore = useCartStore();
    const { cart } = storeToRefs(cartStore);
    const { removeItem, updateItemQty, loading } = useCart();
    const isDeleting = ref(false);
    const isUpdatingQuantity = ref(false);

    const getBundles = (product) => product.bundle_options?.map((b) => b.values).flat() || [];

    const actionRemoveItem = async (product) => {
      isDeleting.value = true;
      await removeItem({ product });
      isDeleting.value = false;
    };

    const delayedUpdateItemQty = _debounce((params) => {
      if (params.quantity === 0) removeItem(params);
      else updateItemQty(params);
    }, 500);

    const invokeUpdateItemQty = (params) => {
      isUpdatingQuantity.value = true;
      const { quantity, product } = params;
      const cartItem = cart.value?.items.find((item) => item.uid === product.uid);

      // SF-UI issue, doesn't set "zero" quantity -> Check for removal:
      if (quantity === 1 && cartItem?.quantity === quantity) {
        params.quantity = 0;
      }

      delayedUpdateItemQty(params);
    };

    const isInStock = (product) => {
      if (product.__typename === 'ConfigurableCartItem') {
        return product?.configured_variant?.stock_status === stockStatusEnum.inStock;
      }
      return product?.product?.stock_status === stockStatusEnum.inStock;
    };

    const isDisabled = ({ product }) => {
      const testIfGiftCardHolder = (sku) => /^MJ071\d{2}-1700-OS$/.test(sku);
      if (product.sku === 'mjgiftcard' || testIfGiftCardHolder(product.sku)) return true;
      return false;
    };

    watch(loading, (newVal) => {
      if (newVal === false) {
        isUpdatingQuantity.value = false;
      }
    });

    return {
      actionRemoveItem,
      invokeUpdateItemQty,
      cartGetters,
      getBundles,
      isInStock,
      transformImageUrlToSize,
      ImageSize,
      isDeleting,
      isUpdatingQuantity,
      isDisabled,
    };
  },
});
