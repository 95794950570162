//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfHeading, SfInput, SfButton, SfSelect, SfLoader } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { required, min, digits } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import CartPreview from '~/components/Checkout/CartPreview.vue';
import { useCartStore } from '~/stores';

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('digits', {
  ...digits,
  message: 'Please provide a valid phone number',
});

export default defineComponent({
  name: 'ShippingStep',
  components: {
    SfHeading,
    SfInput,
    SfButton,
    SfSelect,
    SfLoader,
    ValidationProvider,
    ValidationObserver,
    UserShipping: () => import('~/components/Checkout/UserShipping.vue'),
    VsfShippingProvider: () => import('~/components/Checkout/VsfShippingProvider.vue'),
    CartPreview,
  },
  setup() {
    const cartStore = useCartStore();
    const shippingAddresses = computed(() => cartStore.cart.shipping_addresses || []);
    const isShippingAddressCompleted = computed(() => shippingAddresses.value.length > 0);

    return {
      isShippingAddressCompleted,
    };
  },
});
