import { render, staticRenderFns } from "./ProductGallerySlider.vue?vue&type=template&id=8fc1ea3a&"
import script from "./ProductGallerySlider.vue?vue&type=script&lang=ts&"
export * from "./ProductGallerySlider.vue?vue&type=script&lang=ts&"
import style0 from "./ProductGallerySlider.vue?vue&type=style&index=0&id=8fc1ea3a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductSwiperSlider: require('/builds/my-jewellery/code/frontend/components/Product/ProductSwiperSlider.vue').default,ProductLightboxSlider: require('/builds/my-jewellery/code/frontend/components/Product/ProductLightboxSlider.vue').default,ProductLabels: require('/builds/my-jewellery/code/frontend/components/Product/ProductLabels.vue').default})
