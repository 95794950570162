




































import { defineComponent } from '@nuxtjs/composition-api';
import { focusTrap, clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isClient } from '@storefront-ui/vue/src/utilities/helpers';
import { PropType } from 'vue';
import { SfLoader, SfOverlay } from '@storefront-ui/vue';
import useUiState from '~/stores/useUiState';
import SidebarTitle from './SidebarTitle.vue';
import SidebarCloseButton from './SidebarCloseButton.vue';
import TimesIcon from '~/assets/icons/times.svg';

type SidebarPosition = 'left' | 'right';

export default defineComponent({
  name: 'Sidebar',
  components: {
    SfLoader,
    SfOverlay,
    SidebarTitle,
    SidebarCloseButton,
    TimesIcon,
  },
  directives: { focusTrap, clickOutside },
  props: {
    id: {
      type: String,
      default: Math.random().toString(36).slice(2, 8),
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    position: {
      type: String as PropType<SidebarPosition>,
      default: 'right',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      transition: this.position,
      isOpen: this.visible,
      // originalMetaViewportContent: 'width=device-width, initial-scale=1',
    };
  },
  methods: {
    close() {
      this.isOpen = false;
      this.$emit('close');
    },
    checkPersistence() {
      if (!this.persistent) this.close();
    },
    keydownHandler(e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) return;
      const { closeAllSidebars } = useUiState();
      closeAllSidebars();
    },
    visible: {
      handler(value) {
        if (!isClient) return;
        if (value) {
          this.isOpen = true;
          this.transition = this.position;
          this.$nextTick(() => {
            const sidebarContent = document.getElementsByClassName('mjsf-sidebar__aside')[0];
            disableBodyScroll(sidebarContent);
          });
          document.addEventListener('keydown', this.keydownHandler);
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener('keydown', this.keydownHandler);
          this.isOpen = false;
        }
      },
      immediate: true,
    },
    isOpen: {
      // handle out animation for async load component
      handler(value) {
        if (!isClient) return;
        if (!value) {
          this.transition = this.position === 'right' ? 'left' : 'right';
        }
      },
    },
  },
});
