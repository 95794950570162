







import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';
import InfoIcon from '~/assets/icons/info.svg';
import { useProductStore } from '~/stores';

export default defineComponent({
  name: 'ProductSpecialNote',
  components: {
    InfoIcon,
    SfLoader,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup({ product }) {
    const productStore = useProductStore();
    const specialNoteId = computed(() => product?.special_note ?? 0);
    const specialNote = computed(() => productStore.getAttributeById(specialNoteId.value.toString(), 'special_note'));

    return {
      specialNoteId,
      specialNote,
    };
  },
});
