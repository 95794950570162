


























import { defineComponent, useContext, ref, onMounted, onBeforeUnmount, computed } from '@nuxtjs/composition-api';
import FAQ from '~/components/MyJewellery/Faq.vue';

export default defineComponent({
  name: 'SeoText',
  components: { FAQ },
  props: {
    component: null,
    page: null,
  },
  setup(props) {
    const { app } = useContext();
    const isMobile = ref(!app.$device.isDesktop);

    const isInvisible = ref(true);
    const onResize = () => {
      isMobile.value = window.outerWidth < 1024;
    };

    const { document } = props.component?.getModels();

    const isFullWidth = ref(props.page.getContent(document)?.getData()?.fullWidth);

    const columnCount = ref(isFullWidth.value ? 1 : 2);
    const content = ref(props.page.getContent(document));
    const isPreview = ref(props.page?.isPreview());
    const textPart1 = ref(props.page.getContent(document)?.getData()?.part1?.value);
    const textPart2 = ref(props.page.getContent(document)?.getData()?.part2?.value);
    const textPart3 = ref(props.page.getContent(document)?.getData()?.part3?.value);
    const textPart4 = ref(props.page.getContent(document)?.getData()?.part4?.value);
    const faqRef = ref(props.page.getContent(document)?.getData()?.faq?.$ref);
    const hideSecondPartMobile = ref(props.page.getContent(document)?.getData()?.hideSecondPartMobile);
    const hideSecondPartDesktop = ref(props.page.getContent(document)?.getData()?.hideSecondPartDesktop);

    const showButton = computed(
      () =>
        (textPart1.value && textPart3.value) ||
        (textPart1.value && textPart2.value && isMobile.value && hideSecondPartMobile.value) ||
        (textPart1.value && textPart2.value && !isMobile.value && hideSecondPartDesktop.value),
    );

    onMounted(() => {
      window.addEventListener('resize', onResize);
      onResize();
    });

    onBeforeUnmount(() => window.removeEventListener('resize', onResize));

    return {
      isMobile,
      isInvisible,
      isPreview,
      columnCount,
      content,
      textPart1,
      textPart2,
      textPart3,
      textPart4,
      faqRef,
      hideSecondPartMobile,
      hideSecondPartDesktop,
      showButton,
    };
  },
});
