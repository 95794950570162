import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=293abf39&"
import script from "./Sidebar.vue?vue&type=script&lang=ts&"
export * from "./Sidebar.vue?vue&type=script&lang=ts&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=293abf39&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarCloseButton: require('/builds/my-jewellery/code/frontend/components/Sidebar/SidebarCloseButton.vue').default,SidebarTitle: require('/builds/my-jewellery/code/frontend/components/Sidebar/SidebarTitle.vue').default})
