import { render, staticRenderFns } from "./SizeChartSidebar.vue?vue&type=template&id=d64e19a6&scoped=true&"
import script from "./SizeChartSidebar.vue?vue&type=script&lang=ts&"
export * from "./SizeChartSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./SizeChartSidebar.vue?vue&type=style&index=0&id=d64e19a6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d64e19a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HTMLContent: require('/builds/my-jewellery/code/frontend/components/HTMLContent.vue').default,Sidebar: require('/builds/my-jewellery/code/frontend/components/Sidebar/Sidebar.vue').default})
