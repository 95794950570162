











































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { Document, Page } from '@bloomreach/spa-sdk';
import { StoreOpeningHours } from '~/types/interfaces/storeGrid';

import Breadcrumbs from '@/components/MyJewellery/Breadcrumbs.vue';

interface BrProps {
  page: Page;
}

export default defineComponent({
  name: 'StoreDetail',
  components: { Breadcrumbs },
  props: {
    page: null,
  },
  setup(props) {
    const { page } = <BrProps>props;
    const pageMeta = computed(() => props.page?.getDocument()?.getData() || {});

    const storeAddress = computed(() =>
      [pageMeta.value?.streetName, pageMeta.value?.houseNumber, pageMeta.value?.addition]
        .filter((s) => s?.length)
        .join(' ')
        .trim(),
    );
    const storePostalCodeCity = computed(() => `${pageMeta.value?.postalCode} ${pageMeta.value?.city}`.trim());
    const storeCountry = computed(() =>
      ['nl', 'netherlands', 'nederland'].includes(pageMeta.value?.country?.toLowerCase())
        ? null
        : pageMeta.value?.country,
    );

    const openingHours =
      pageMeta.value?.openingHours?.map((ref) => page?.getContent<Document>(ref).getData<StoreOpeningHours>()) || [];
    const adjustedOpeningHours =
      pageMeta.value?.adjustedOpeningHours?.map((ref) =>
        page?.getContent<Document>(ref).getData<StoreOpeningHours>(),
      ) || [];

    return {
      openingHours,
      adjustedOpeningHours,
      storeAddress,
      storeCountry,
      storePostalCodeCity,
    };
  },
});
