









import { computed, defineComponent, watch, ref } from '@nuxtjs/composition-api';
import { sizeChartStore, usePageStore, useProductStore } from '~/stores';
import { storeToRefs } from 'pinia';
import Sidebar from '~/components/Sidebar/Sidebar.vue';
import HTMLContent from '~/components/HTMLContent.vue';

export default defineComponent({
  name: 'SizeChartSidebar',
  components: {
    Sidebar,
    HTMLContent,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { loading, sizeChartItems, currentSizeChartRef, currentSizeChart: sizeChart } = storeToRefs(sizeChartStore());
    const { loadSizeChartItems, loadSizeChart } = sizeChartStore();
    const { products, activeProductSku } = storeToRefs(useProductStore());
    const { routeData } = usePageStore();
    const categories = computed(() =>
      products.value[activeProductSku.value || routeData?.sku.value]?.categories?.map((c) => c.id),
    );

    watch(
      () => props.isOpen,
      async (isOpen) => {
        if (!isOpen) return;
        await loadSizeChartItems();
        currentSizeChartRef.value = '';

        for (const categoryId of [...categories.value].reverse()) {
          const match = sizeChartItems.value.find((item) => parseInt(item.categoryId) === categoryId);
          if (match) {
            loadSizeChart(match.documentRef);
            break;
          }
        }
      },
    );

    return {
      loading,
      sizeChart,
    };
  },
});
