






















import { SfProperty, SfInput, SfNotification } from '@storefront-ui/vue';
import CheckmarkIcon from '~/assets/icons/checkmark-alt.svg';
import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import { useProductStore } from '~/stores';
import { storeToRefs } from 'pinia';
import Vue from 'vue';

export default defineComponent({
  name: 'ProductVariantEngraving',
  components: {
    SfProperty,
    SfInput,
    SfNotification,
    CheckmarkIcon,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const errorUseOnlyLetters = ref(false);
    const { productCustomOptionsConfiguration } = storeToRefs(useProductStore());
    const maxAmountOfCharacters = computed(() => props.option?.field_option?.max_characters || 4);

    const productConfiguration = computed({
      get: () => {
        // @todo: set to uid when MYJE-4300 is fixed
        // return productCustomOptionsConfiguration[props.option.uid];
        return productCustomOptionsConfiguration[props.option.option_id];
      },
      set: (value) => {
        errorUseOnlyLetters.value = false;
        // @todo: set to uid when MYJE-4300 is fixed
        // Vue.set(productCustomOptionsConfiguration.value, props.option.uid, value);
        Vue.set(productCustomOptionsConfiguration.value, props.option.option_id, value?.toUpperCase());
      },
    });

    const checkValue = (event: KeyboardEvent) => {
      const value = event.key;
      if (!/^[A-Za-z]+$/.test(value)) {
        if (productConfiguration.value?.length >= maxAmountOfCharacters.value) return;
        errorUseOnlyLetters.value = true;
        event.preventDefault();
      }
    };

    return {
      productConfiguration,
      checkValue,
      maxAmountOfCharacters,
      errorUseOnlyLetters,
    };
  },
});
