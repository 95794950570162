var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",attrs:{"id":"my-account-login"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"column"},[_c('h1',[_vm._v(_vm._s(_vm.$t('Customer login')))])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"column"},[_c('h2',{staticClass:"h3"},[_vm._v(_vm._s(_vm.$t('Existing customers')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('You already have an account. We like it! Use your email address to log in.')))]),_vm._v(" "),_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"login-form",attrs:{"id":"login-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleLogin)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Your email'),"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"type":"password","has-show-password":false},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),_c('SfNotification',{attrs:{"visible":!!_vm.error.login,"message":_vm.$t(_vm.error.login),"persistent":false,"type":"danger"}}),_vm._v(" "),_c('div',{staticClass:"button-group"},[_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-submit'),expression:"'login-modal-submit'"}],staticClass:"form__button",attrs:{"type":"submit","disabled":_vm.loading}},[_c('span',[_vm._v(_vm._s(_vm.$t('Sign in')))]),_vm._v(" "),_c('SfLoader',{staticClass:"form__button-loader",class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}})],1),_vm._v(" "),_c('SfButton',{staticClass:"sf-button--outline",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.redirectToForgotten.apply(null, arguments)}}},[_vm._v("\n              "+_vm._s(_vm.$t('Forgotten password?'))+"\n            ")])],1)],1)]}}])})],1),_vm._v(" "),_c('div',{staticClass:"column has-background"},[_c('h2',{staticClass:"h3"},[_vm._v(_vm._s(_vm.$t('New customer')))]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t(
            "Let's create an account. It has many advantages, such as order tracking, fast checkout and registration of multiple addresses."
          ))+"\n      ")]),_vm._v(" "),_c('SfButton',{staticClass:"sf-button--full-width",attrs:{"link":_vm.localePath('/customer/account/create')}},[_vm._v(_vm._s(_vm.$t('Create an account')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }