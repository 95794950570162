import { render, staticRenderFns } from "./AnnouncementHeader.vue?vue&type=template&id=37a8d61f&scoped=true&"
import script from "./AnnouncementHeader.vue?vue&type=script&lang=ts&"
export * from "./AnnouncementHeader.vue?vue&type=script&lang=ts&"
import style0 from "./AnnouncementHeader.vue?vue&type=style&index=0&id=37a8d61f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a8d61f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Countdown: require('/builds/my-jewellery/code/frontend/components/Countdown.vue').default})
