//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfHeading, SfButton, SfRadio, SfLoader } from '@storefront-ui/vue';
import { computed, defineComponent, onMounted, watch } from '@nuxtjs/composition-api';
import { useShippingProvider } from '~/composables';
import { useCartStore } from '~/stores';
import PickupLocations from './PickupLocations.vue';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'VsfShippingProvider',
  components: {
    SfHeading,
    SfButton,
    SfRadio,
    SfLoader,
    PickupLocations,
  },
  setup() {
    // const { cart } = useCart();
    const cartStore = useCartStore();
    const {
      cart,
      selectedShippingMethod,
      shipping,
      selectedShippingAddress,
      availableShippingMethods,
      hasShippingAddress,
    } = storeToRefs(cartStore);
    const {
      load: loadShippingProvider,
      save: saveShippingProvider,
      error: errorShippingProvider,
      loading: loadingShippingProvider,
    } = useShippingProvider();

    const isLoading = computed(() => loadingShippingProvider.value);
    const isShippingMethodStepCompleted = computed(
      () =>
        cart.value.shipping_addresses?.[0]?.selected_shipping_method?.method_code &&
        !isLoading.value &&
        !loadingShippingProvider.save,
    );

    watch(selectedShippingAddress.value, () => {
      if (availableShippingMethods.value) {
        loadShippingProvider();
      }
    });

    const selectShippingMethod = (method) => {
      const shippingData = {
        token: shipping.value.token,
        method: method,
      };

      saveShippingProvider({
        shippingMethod: shippingData,
      });
    };

    const shippingMethods = computed(() => {
      return shipping.value.shipping_options || [];
    });

    onMounted(async () => {
      if (hasShippingAddress.value) {
        loadShippingProvider();
      }
    });

    return {
      errorShippingProvider,
      isLoading,
      isShippingMethodStepCompleted,
      loadingShippingProvider,
      selectedShippingMethod,
      selectShippingMethod,
      shippingMethods,
      selectedShippingAddress,
    };
  },
});
