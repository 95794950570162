//
//
//
//
//
//
//

import { defineComponent, ref, useRoute, useContext } from '@nuxtjs/composition-api';
import Breadcrumbs from '@/components/MyJewellery/Breadcrumbs.vue';

export default defineComponent({
  name: 'OneColumn',
  props: {
    page: null,
  },
  components: { Breadcrumbs },
  setup() {
    const route = useRoute();
    const { app } = useContext();

    const isHome = ref(route.value.path.replace('/' + app.i18n.locale, '').length <= 1);

    return {
      isHome,
    };
  },
});
