











import { computed, ref, defineComponent, onMounted, useContext, useRoute } from '@nuxtjs/composition-api';
import { SfSection } from '@storefront-ui/vue';
import type { Product } from '@/types/interfaces/product';
import { usePageStore } from '~/stores';

export default defineComponent({
  components: {
    SfSection,
  },
  props: {
    product: {
      type: Object as () => Product,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { app } = useContext();
    const { routeData } = usePageStore();
    const route = useRoute();
    const isEmpty = ref(false);

    const productSku = routeData?.sku || route.value?.params?.id;
    const skus = computed(
      () =>
        props.product?.configurable_options
          ?.find((o) => o.attribute_code === 'size')
          ?.values.map((v) => `${productSku}-${v.swatch_data.value}`) || [],
    );

    onMounted(() => {
      // @ts-ignore
      window.photoSlurpWidgetSettings = window.photoSlurpWidgetSettings || {};
      // @ts-ignore
      window.photoSlurpWidgetSettings['41e670ef-4011-46f9-ac38-f9c92c87b49f'] = {
        lang: app.i18n.locale,
        widgetId: '41e670ef-4011-46f9-ac38-f9c92c87b49f',
        albumId: 474,
        widgetType: 'carousel',
        pageLimit: 10,
        autoscrollLimit: 5,
        socialIcons: false,
        visibleProducts: 4,
        thumbOverlay: true,
        varyingThumbSizes: true,
        showSubmit: false,
        noteAddPicsText: '',
        productId: [productSku, ...skus.value],
      };

      var script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.src = '//static.photoslurp.com/widget/v3/loader.js';
      var entry = document.getElementsByTagName('script')[0];
      entry.parentNode.insertBefore(script, entry);

      const target = document.querySelector('.product__related-photoslurp ps-widget');
      const observer = new MutationObserver(() => {
        if (target.classList.contains('ps-widget-empty')) {
          isEmpty.value = true;
        }
      });

      observer.observe(target, { attributes: true });
    });

    return { skus, isEmpty };
  },
});
