































































































import { defineComponent } from '@nuxtjs/composition-api';
import { SfTabs, SfTable, SfButton, SfLink, SfPagination, SfSelect, SfLoader, SfProperty } from '@storefront-ui/vue';
import { orderGetters } from '@vue-storefront/magento';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import ChevronLeftIcon from '~/static/icons/chevron-left-alt.svg';

export default defineComponent({
  name: 'OrderDetails',
  components: {
    SfButton,
    SfLink,
    SfPagination,
    SfProperty,
    SfSelect,
    SfTable,
    SfTabs,
    SfLoader,
    ChevronLeftIcon,
    UserAddressDetails,
  },
  props: {
    order: {
      type: [Object, null],
      default: null,
    },
  },
  setup(props) {
    const getVisibleDiscounts = (discounts) => discounts.filter((d) => d?.amount > 0);

    return {
      orderGetters,
      getVisibleDiscounts,
    };
  },
});
