






import { defineComponent, useContext, useMeta } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductTrustedShopsWidget',
  head: {},
  setup() {
    const context = useContext();
    const locale = context.app?.i18n?.locale;
    if (locale === 'de') {
      useMeta({
        script: [
          {
            hid: 'trusted-shops-widget',
            src: `https://integrations.etrusted.com/applications/widget.js/v2`,
            async: true,
            defer: true,
            type: 'text/javascript',
            charset: 'utf-8',
          },
        ],
      });
    }
  },
});
