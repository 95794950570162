






























































import { defineComponent } from '@nuxtjs/composition-api';
import { PropType } from 'vue';
import { SfButton } from '@storefront-ui/vue';

import { Store } from '~/types/interfaces/storeGrid';
import formatUrl from '~/helpers/formatUrl';
import ChevronRightIcon from '~/assets/icons/chevron-right.svg';
import ClockIcon from '~/assets/icons/clock.svg';
import TimesIcon from '~/assets/icons/times.svg';
import BynderAsset from './BynderAsset.vue';

export default defineComponent({
  components: {
    SfButton,
    ChevronRightIcon,
    ClockIcon,
    TimesIcon,
    BynderAsset,
  },
  data: () => ({
    showOpeningHours: false,
  }),
  props: {
    store: {
      type: Object as PropType<Store[]>,
      required: true,
    },
  },
  methods: {
    formatUrl,
  },
  computed: {
    formattedAddress() {
      return `${this.store.address.streetName} ${this.store.address.houseNumber} ${this.store.address.addition}`.trim();
    },
    formattedCity() {
      return `${this.store.address.postalCode} ${this.store.address.city}`.trim();
    },
    firstAdjustedOpeningHours() {
      return this.store.adjustedOpeningHours.slice(0, 1);
    },
    asset() {
      return {
        data: this.store.document.model.data.storeImage,
        size: '(max-width: 375px) 100vw, 400px',
        ratio: 0.5575,
      };
    },
  },
});
