
































































import { SfHeading, SfProperty } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useCart } from '~/composables';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import CouponCode from '../Cart/CouponCode.vue';
import { useCartStore } from '~/stores';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'CartPreview',
  components: {
    SfHeading,
    SfProperty,
    CouponCode,
  },
  setup() {
    const cartStore = useCartStore();
    const { cart, products, hasDiscounts, getDiscounts, selectedShippingMethod } = storeToRefs(cartStore);
    const { removeItem, updateItemQty } = useCart();

    const discountsAmount = computed(() => -1 * getDiscounts.value?.reduce((a, el) => el?.value + a, 0));
    const productsMapped = computed(() => {
      return products.value?.map((item) => {
        const price_regular = item.product.price_range.maximum_price.regular_price.value;
        const price_final = item.product.price_range.maximum_price.final_price.value;
        const has_discount = price_regular !== price_final;
        const quantity = item.quantity;
        const thumbnail_url = item.configured_variant?.thumbnail?.url;
        const name = item.product.name;
        const configured_variants = item.configurable_options?.map((option) => option.value_label).join(' | ');
        return {
          price_regular,
          price_final,
          has_discount,
          quantity,
          thumbnail_url,
          name,
          configured_variants,
        };
      });
    });

    // const shippingMethodName = computed(() => cart.value.shipping_addresses[0]?.selected_shipping_method?.method_title);
    const shippingMethodName = null;

    return {
      cart,
      hasDiscounts,
      productsMapped,
      removeItem,
      updateItemQty,
      getShippingMethodPrice,
      selectedShippingMethod,
      shippingMethodName,
      discountsAmount,
    };
  },
});
