var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"price-range-slider"},[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('svg',{staticClass:"area-chart",attrs:{"viewBox":("0 0 " + _vm.rangeSize + " " + _vm.height),"preserveAspectRatio":"none"}},[_c('polygon',{staticClass:"line line--inactive",attrs:{"points":_vm.inactiveRange}}),_vm._v(" "),_c('polygon',{staticClass:"line",attrs:{"points":_vm.activeRange}})])]),_vm._v(" "),_c('SfRange',{attrs:{"data-testid":"priceRangeSlider","data-ref":"rangeSlider","value":_vm.rangeMinMax,"config":{
      connect: true,
      tooltips: true,
      format: {
        to: function (value) {
          return parseInt(value);
        },
        from: function (value) {
          return parseInt(value);
        },
      },
      step: 5,
      range: { min: 0, max: _vm.highestPriceStep },
      start: _vm.rangeMinMax,
    }},on:{"change":function ($event) { return _vm.changeRange($event, true); },"drag":function ($event) { return _vm.changeRange($event); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }