












































































import { SfMegaMenu, SfLink, SfList, SfScrollable, SfMenuItem, SfLoader } from '@storefront-ui/vue';
import { computed, defineComponent, useContext, onMounted, onBeforeUnmount, useRouter } from '@nuxtjs/composition-api';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ProductCard from '~/components/MyJewellery/ProductCard.vue';
import ContentSearchResult from './ContentSearchResult.vue';

export default defineComponent({
  name: 'SearchResults',
  components: {
    SfMegaMenu,
    SfLink,
    SfList,
    ProductCard,
    SfScrollable,
    SfMenuItem,
    SfLoader,
    ContentSearchResult,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object,
      default: () => ({}),
    },
    maxNumSuggestions: {
      type: Number,
      default: 5,
    },
    maxNumCategories: {
      type: Number,
      default: 8,
    },
  },
  setup(props) {
    const { app } = useContext();
    const router = useRouter();

    const customContentSearch = require(`./contentSearchResults_${app.i18n.locale.substring(0, 2)}.json`);
    const customContentSearchResults = computed(() => {
      if (props.result?.query?.length >= 3) {
        return customContentSearch.filter((item) =>
          item.keywords.some((keyword) => keyword.toLowerCase() === props.result.query.toLowerCase()),
        );
      }
    });

    const products = computed(() =>
      props.result?.products?.slice(0, 6 - (customContentSearchResults.value?.length || 0)),
    );
    const categories = computed(() =>
      props.result?.categories?.sort((a, b) => b.count - a.count).slice(0, props.maxNumCategories),
    );
    const autoCorrectQuery = computed(() => props.result?.autoCorrectQuery);
    const didYouMean = computed(() => props.result?.didYouMean);
    const suggestions = computed(() => props.result?.suggestions?.slice(0, props.maxNumSuggestions) || {});
    const hasSearchSuggestions = computed(() =>
      Boolean(products.value?.length || suggestions.value?.length || customContentSearchResults.value?.length),
    );

    const onViewportChanged = () => {
      const viewport = (event?.target || window.visualViewport) as VisualViewport;
      document.documentElement.style.setProperty('--viewport-height', `${viewport.height}px`);
    };

    onMounted(() => {
      const searchEl = document.querySelector('.search');
      disableBodyScroll(searchEl);

      window.visualViewport.addEventListener('scroll', onViewportChanged);
      window.visualViewport.addEventListener('resize', onViewportChanged);
      onViewportChanged();
    });

    onBeforeUnmount(() => {
      clearAllBodyScrollLocks();
      document.body.classList.remove('no-scroll');
    });

    const showCategoryPage = async (categoryId) => {
      const { data } = await app.$vsf.$magento.api.categorySearch({
        filters: {
          ids: { eq: categoryId },
        },
      });

      if (data) {
        const category = data?.categoryList?.[0];

        if (category) {
          await router.push(app.localePath(`/${category.url_path}${category.url_suffix}`));
        }
      }
    };

    const handleShowSearchResults = async (query: string) => {
      await router
        .push({
          path: `${app.localePath('/catalogsearch/result/')}`,
          query: { q: query },
        })
        .catch(() => {});
    };

    return {
      hasSearchSuggestions,
      autoCorrectQuery,
      didYouMean,
      products,
      categories,
      suggestions,
      customContentSearchResults,
      showCategoryPage,
      handleShowSearchResults,
    };
  },
});
