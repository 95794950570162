//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfBanner, SfLink } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';

import { truncateWords } from '@/helpers/stringHelpers';
import BynderAsset from '../BynderAsset.vue';

export default defineComponent({
  name: 'HighlightedArticle',
  components: {
    SfBanner,
    SfLink,
    BynderAsset,
  },
  props: {
    banners: null,
  },
  computed: {
    columns() {
      return this.banners.length >= 2 ? (this.banners.length % 2 === 0 ? 2 : 3) : 2; // When carrot of two equals zero, show two columns. Otherwise show three.
    },
  },
  methods: {
    truncateWords,
  },
});
