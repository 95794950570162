





















import { PageModel } from '@bloomreach/spa-sdk';
import { defineComponent, ref, useContext, computed, onMounted } from '@nuxtjs/composition-api';
import BynderAsset from '../MyJewellery/BynderAsset.vue';
import { useBreadcrumbsStore, usePageStore } from '~/stores';
import { useBloomreachPage } from '~/composables';
import { storeToRefs } from 'pinia';
import logger from '~/utilities/logger';
import { CategoryId, Sku } from '~/composables/useBloomreachPage';

interface BrxPDP {
  type: string;
  data: {
    name: string;
    displayName: string;
    text: {
      value: string;
    };
    title: string;
    image: string;
    ctaLink: {
      $ref: string;
      href: string;
    };
    ctaUrl: string;
    productIDs: string;
    categoryIDs: string;
    localeString: string;
    contentType: string;
    id: string;
  };
}

export default defineComponent({
  name: 'ProductRelatedArticle',
  components: { BynderAsset },
  props: {
    sku: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { bloomreachPages } = storeToRefs(usePageStore());
    const { app } = useContext();
    const breadcrumbsStore = useBreadcrumbsStore();
    const { breadcrumbs } = storeToRefs(breadcrumbsStore);

    function getPDPDocuments(page: PageModel): BrxPDP[] {
      if (!page?.page) return [];
      const pageObjects: BrxPDP[] = Object.values(page.page);
      return pageObjects.filter((object) => object?.data?.contentType === 'brxsaas:mjPDP');
    }

    function getBreadcrums() {
      const breadcrumbsArray = breadcrumbs.value.map((breadcrumb) => breadcrumb.id).reverse();
      return [props.sku, ...breadcrumbsArray];
    }

    function resolveInPageRef(page: PageModel, ref: string) {
      const uid = ref?.replace('/page/', '');
      return page.page[uid];
    }

    const blogArticle = computed(() => {
      for (const item of getBreadcrums()) {
        if (!item) continue;
        const storeKey = item + 'q';
        const page: PageModel = bloomreachPages.value[storeKey];
        const documents = getPDPDocuments(page);
        const document = documents.length > 0 ? documents[0] : undefined;
        if (document?.data?.ctaLink?.$ref) {
          const ctaLink = resolveInPageRef(page, document?.data?.ctaLink?.$ref);
          document.data.ctaLink.href = ctaLink?.links?.site?.href;
        }
        if (document) {
          return document?.data;
        }
      }
      return undefined;
    });
    const attributes = computed(() => {
      if (blogArticle.value?.ctaUrl) {
        return {
          href: blogArticle.value?.ctaUrl,
        };
      }
      return {
        to: app.localePath(blogArticle.value?.ctaLink?.href),
      };
    });

    return {
      blogArticle,
      attributes,
    };
  },
});
