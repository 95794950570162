import { render, staticRenderFns } from "./default.vue?vue&type=template&id=255b127d&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=255b127d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartSidebar: require('/builds/my-jewellery/code/frontend/components/Cart/CartSidebar.vue').default,LocaleSidebar: require('/builds/my-jewellery/code/frontend/components/LocaleSidebar.vue').default,WishlistSidebar: require('/builds/my-jewellery/code/frontend/components/Wishlist/WishlistSidebar.vue').default,ProductSoldOutForm: require('/builds/my-jewellery/code/frontend/components/Product/ProductSoldOutForm.vue').default,Notification: require('/builds/my-jewellery/code/frontend/components/Notification.vue').default})
