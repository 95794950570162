









import { defineComponent } from '@vue/composition-api';
import { SfButton, SfLoader } from '@storefront-ui/vue';
import { useUiHelpers } from '~/composables';
import { useUiState } from '~/stores';

import PinIcon from '~/assets/icons/pin.svg';
import PhoneIcon from '~/assets/icons/phone.svg';
import { useContext, useRouter } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TopNavCtaItems',
  components: {
    PinIcon,
    PhoneIcon,
    SfButton,
    SfLoader,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { toggleMobileMenu } = useUiState();
    const { toggleMobileMenuOpenClassOnBody } = useUiHelpers();
    const { localePath } = useContext();
    const router = useRouter();

    const openCta = (headerLink: string) => {
      router.push(localePath(headerLink));
      toggleMobileMenu();
      toggleMobileMenuOpenClassOnBody();
    };

    return {
      openCta,
    };
  },
});
