//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfButton } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TabMenu',

  components: {
    SfButton,
  },

  props: {
    openTab: Number,
    tabs: Array,
  },

  emits: ['setTab'],

  setup(_, ctx) {
    const setTab = (index) => {
      ctx.emit('setTab', index);
    };
    return { setTab };
  },
});
