

































import { ref, computed, defineComponent, useContext, useRoute, onBeforeUnmount } from '@nuxtjs/composition-api';
import cacheControl from '~/helpers/cacheControl';
import { slugify } from '~/helpers/stringHelpers';
import { storeToRefs } from 'pinia';
import { usePageStore, useBloomreachDiscoveryStore, useCategoryStore, pcpUspsStore } from '~/stores';

import Breadcrumbs from '~/components/MyJewellery/Breadcrumbs.vue';
import CategoryListHorizontal from '~/components/Category/CategoryListHorizontal.vue';
import ProductList from '~/components/ProductList.vue';
import { onSSR } from '@vue-storefront/core';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import Vue from 'vue';
import { CategoryFacet } from '~/composables/useBloomreachDiscoveryApi/bloomreachDiscoveryApi';

export default defineComponent({
  name: 'CategoryPage',
  components: {
    CategoryListHorizontal,
    Breadcrumbs,
    ProductList,
  },
  head() {
    return {
      title: this.pageData?.title,
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.pageData?.title,
        },
        {
          hid: 'description',
          name: 'description',
          content: this.pageData?.description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.pageData?.description,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.pageData?.metaKeywords,
        },
      ],
    };
  },
  middleware: cacheControl({
    'max-age': 60,
    'stale-when-revalidate': 5,
  }),
  props: {
    page: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const bloomreachDiscoveryStore = useBloomreachDiscoveryStore();
    const { pagination, searchResponse, currentPage, prependedPage } = storeToRefs(bloomreachDiscoveryStore);
    const { doSearch } = bloomreachDiscoveryStore;
    const context = useContext();
    const { routeData, pageData, previousPage } = storeToRefs(usePageStore());
    const { loading: loadingCategoriesFromMagento } = storeToRefs(useCategoryStore());
    const { loadPcpUsps } = pcpUspsStore();
    const { getCategoryList } = useCategoryStore();

    const page = props.page?.page || props.page.model?.page;
    const items: any = page ? Object.values(page) : [];
    const hasHeaderComponent = items.filter((item) => item?.label === 'Top' && item.children)?.length > 0;

    // Subcategories
    const subcategories = ref([]);

    const activeCategoryId = computed(() => routeData.value?.id);
    const activeCategory = computed(() => {
      const categoryFacet = searchResponse.value?.facet_counts?.facets?.find(
        (facet) => facet.name === 'category',
      ) as CategoryFacet;

      return categoryFacet?.value?.find((c) => c.cat_id === activeCategoryId.value?.toString());
    });
    const activeCategoryName = computed(() => {
      const displayName =
        // @ts-ignore
        pageData.value?.title !== '-' && pageData.value?.displayName !== '-' && pageData.value?.displayName
          ? pageData.value?.displayName
          : false;
      return pageData.value?.pageTitle || activeCategory.value?.cat_name || routeData.value?.name || displayName || '';
    });

    const generateBreadcrumbs = () => {
      breadcrumbs.value = activeCategory.value?.tree_path
        .split('/')
        .filter((c) => c !== '')
        .map((c) => {
          const [id, name] = c.split(',');
          const categoryFacet = searchResponse.value?.facet_counts.facets?.find(
            (facet) => facet.name === 'category',
          ) as CategoryFacet;
          const cat = categoryFacet?.value.find((c) => c.cat_id === id);
          return {
            link: context.app.localePath(`/${parseCategoryTreePath(cat.tree_path)}.html`),
            text: cat.cat_name,
            id: cat.cat_id,
          };
        });

      const categoryIds = breadcrumbs.value?.map((breadcrumb) => breadcrumb.id).reverse();
      loadPcpUsps(categoryIds);
    };

    // Breadcrumbs
    const breadcrumbs = ref([]);

    const fetchSubcategories = async () => {
      const crumbs = breadcrumbs.value?.map((breadcrumb) => breadcrumb.id);
      let categoryList = [];
      let ids = crumbs?.length ? crumbs : [activeCategoryId.value];

      while (categoryList.length === 0 && ids.length > 0) {
        const id = ids.pop();
        categoryList = await getCategoryList(context, {
          parent_id: { in: [id] },
        });
      }

      if (!categoryList) {
        return console.warn('No category list found');
      }

      subcategories.value = categoryList.filter((category) => category?.is_anchor && category.product_count > 0);
    };

    // Category
    const parseCategoryTreePath = (path) => {
      return path
        .split('/')
        .filter((c) => c !== '')
        .map((item) => slugify(item.split(',').pop()))
        .join('/');
    };

    onBeforeUnmount(() => {
      Vue.set(previousPage.value, 'category_breadcrumbs', breadcrumbs.value);
    });

    const totalProducts = computed(() => pagination.value?.totalProducts || 0);

    const { addTags } = useCache();
    const route = useRoute();

    onSSR(async () => {
      currentPage.value = prependedPage.value = parseInt(route.value.query?.p?.toString()) || 1;
      await doSearch({ setProducts: true });
      addTags([{ prefix: CacheTagPrefix.Category, value: route.value?.path.replace(/\/+$/, '') }]);
    });

    generateBreadcrumbs();
    fetchSubcategories();

    return {
      activeCategoryId,
      activeCategoryName,
      breadcrumbs,
      generateBreadcrumbs,
      subcategories,
      loadingCategoriesFromMagento,
      totalProducts,
      pageData,
      hasHeaderComponent,
    };
  },
});
