






import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Identifier',
  props: {
    component: null,
    page: null,
  },
  components: {},
  computed: {
    identifier() {
      return this.component?.getProperties()?.id;
    },
    isPreview() {
      return false;
    }
  },
});
