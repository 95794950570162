//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, ref, defineComponent, watch, useContext } from '@nuxtjs/composition-api';
import { SfLoader, SfLink, SfButton, SfNotification, SfProperty } from '@storefront-ui/vue';
import WishlistItem from '~/components/Wishlist/WishlistItem.vue';
import { useProduct, wishlistGetters } from '@vue-storefront/magento';
import { useUser } from '~/composables';
import Sidebar from '~/components/Sidebar/Sidebar.vue';
import EmptyWishlistAlternativeProducts from '~/components/Cart/EmptyCartAlternativeProducts.vue';
import { useExponeaStore, wishlistStore, useUiState } from '~/stores';
import useExponeaConstants from '~/constants/exponea';
import _debounce from '~/helpers/debounce';

import TimesIcon from '~/assets/icons/times-light.svg';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'WishlistSidebar',
  components: {
    SfButton,
    SfLink,
    SfLoader,
    SfNotification,
    SfProperty,
    Sidebar,
    EmptyWishlistAlternativeProducts,
    TimesIcon,
    WishlistItem,
  },
  setup() {
    const uiState = useUiState();
    const { toggleWishlistSidebar } = uiState;
    const { isWishlistSidebarOpen } = storeToRefs(uiState);
    const { loadWishlist } = wishlistStore();
    const { wishlist, wishlistLoaded } = storeToRefs(wishlistStore());
    const { isAuthenticated } = useUser();
    const { products: alternativeProductsResult } = useProduct('wishlistAlternativeProducts');
    const { app } = useContext();

    const itemAddedToCart = ref(null);
    const products = computed(() => wishlist.value?.items_v2?.items?.filter(Boolean) || null);
    const totalItems = computed(() => wishlistGetters.getTotalItems(wishlist.value));

    // Exponea recommended products
    const exponeaStore = useExponeaStore();
    const { EMPTY_CART_RECOMMENDATIONS_ID } = useExponeaConstants();
    const exponeaRecommendations = computed(() =>
      exponeaStore.getExponeaRecommendationById(EMPTY_CART_RECOMMENDATIONS_ID),
    );

    const loadWishlistOrAlternativeProducts = async () => {
      await loadWishlist();
      if (!wishlist.value?.items_count && !exponeaRecommendations.value?.length) {
        await exponeaStore.getRecommendation({ id: EMPTY_CART_RECOMMENDATIONS_ID, fillWithRandom: true, size: 6 });
      }
      wishlistLoaded.value = true;
    };

    watch(isWishlistSidebarOpen, () => {
      if (isWishlistSidebarOpen.value && !wishlistLoaded.value) {
        loadWishlistOrAlternativeProducts();
      }
    });

    watch(isAuthenticated, async () => {
      await loadWishlistOrAlternativeProducts();
    });

    const notification = computed(() => ({
      title: itemAddedToCart.value?.success ? app.i18n.t('Added to cart') : '',
      message: itemAddedToCart.value?.success
        ? app.i18n.t('You added {product} to your shopping cart.', { product: itemAddedToCart.value.name })
        : app.i18n.t("Unfortunately, this item is sold out and can't be added to your shopping cart."),
      type: itemAddedToCart.value?.success ? 'success' : 'danger',
    }));

    const hideNotification = _debounce(() => (itemAddedToCart.value = null), 3000);

    watch(itemAddedToCart, (next) => {
      if (next) hideNotification();
    });

    return {
      isAuthenticated,
      isWishlistSidebarOpen,
      products,
      toggleWishlistSidebar,
      totalItems,
      alternativeProductsResult,
      itemAddedToCart,
      exponeaRecommendations,
      wishlistLoaded,
      notification,
    };
  },
});
