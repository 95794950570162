//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent, computed, onMounted,
} from '@nuxtjs/composition-api';
import {
  SfList,
  SfBottomModal,
  SfCharacteristic,
} from '@storefront-ui/vue';
import {
  useCurrency,
} from '@vue-storefront/magento';
import { useHandleChanges } from '~/helpers/magentoConfig/handleChanges';

export default defineComponent({
  name: 'CurrenciesModal',
  components: {
    SfList,
    SfBottomModal,
    SfCharacteristic,
  },
  props: {
    isModalOpen: Boolean,
    selectedCurrency: String,
  },
  emits: ['closeModal'],
  setup() {
    const {
      currencies,
      change: changeCurrency,
      load: loadCurrencies,
    } = useCurrency('header-currency');

    const { handleChanges } = useHandleChanges();

    const availableCurrencies = computed(() => currencies.value?.available_currency_codes || []);

    onMounted(() => {
      if (currencies.value && currencies.value?.available_currency_codes) return;
      loadCurrencies();
    });

    return {
      changeCurrency,
      handleChanges,
      availableCurrencies,
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
});
