

































import { defineComponent, ref, computed, useContext, useRoute, onMounted, watch } from '@nuxtjs/composition-api';
import { useBloomreach, useBloomreachComponents } from '~/composables';
import cacheControl from '~/helpers/cacheControl';
import { usePageStore, useBloomreachDiscoveryStore, wishlistStore } from '~/stores';

import AppFooter from '~/components/AppFooter.vue';
import Breadcrumbs from '~/components/MyJewellery/Breadcrumbs.vue';
import TopHeader from '~/components/MyJewellery/TopHeader.vue';
import ProductList from '~/components/ProductList.vue';
import { storeToRefs } from 'pinia';
import isEqual from 'lodash.isequal';
import { onSSR } from '@vue-storefront/core';

export default defineComponent({
  name: 'SearchPage',
  components: {
    Breadcrumbs,
    TopHeader,
    AppFooter,
    ProductList,
  },
  middleware: cacheControl({
    'max-age': 60,
    'stale-when-revalidate': 5,
  }),
  async asyncData(context) {
    const { getPage } = useBloomreach(context);
    const { page, pageData, hrefLangs, configuration } = await getPage('');

    return {
      page,
      pageData,
      hrefLangs,
      configuration,
    };
  },
  head() {
    return {
      title: this.$t('Search results for: “{keyword}”', { keyword: this.q }),
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('Search results for: “{keyword}”', { keyword: this.q }),
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: process.env.NUXT_APP_NAME || 'My Jewellery',
        },
        {
          hid: 'description',
          name: 'description',
          content: this.pageData?.description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.pageData?.description,
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex,follow',
        },
      ],
    };
  },
  setup() {
    const { app } = useContext();
    const { mapping } = useBloomreachComponents();
    const route = useRoute();
    const bloomreachDiscoveryStore = useBloomreachDiscoveryStore();
    const { pagination, currentPage, prependedPage } = storeToRefs(bloomreachDiscoveryStore);
    const { doSearch } = bloomreachDiscoveryStore;
    const pageStore = usePageStore();
    const { routeData } = storeToRefs(pageStore);
    const { addToWishlistAfterLogin } = wishlistStore();

    const q = computed(() => route.value.query?.q?.toString().replace('__empty__', '') || '');

    routeData.value = {
      type: 'SEARCH',
      relative_url: 'catalogsearch/result',
      query: { ...route.value.query, q },
    };

    const autoCorrectQuery = ref(null);
    const breadcrumbs = computed(() => [
      {
        link: app.localePath(`/catalogsearch/result?q=${autoCorrectQuery.value ?? q.value}`),
        text: app.i18n.t('Search results for: “{keyword}”', { keyword: autoCorrectQuery.value ?? q.value }),
      },
    ]);
    const totalProducts = computed(() => pagination.value?.totalProducts || 0);

    onMounted(() => {
      addToWishlistAfterLogin();
      window.scrollTo({ top: 0, behavior: 'auto' });
    });

    onSSR(async () => {
      currentPage.value = prependedPage.value = parseInt(route.value.query?.p?.toString()) || 1;
      await doSearch({ setProducts: true });
    });

    watch(route, (from, to) => {
      const fromQuery = { ...from.query, p: undefined };
      const toQuery = { ...to.query, p: undefined };
      // Do not reset the current page or reload the search if navigating to next page
      if (!isEqual(fromQuery, toQuery)) {
        currentPage.value = 1;
        doSearch({ page: 1, setProducts: true });
        // scroll to top on New search
        window.scrollTo({ top: 0, behavior: 'auto' });
      }
    });

    return {
      mapping,
      breadcrumbs,
      q,
      autoCorrectQuery,
      totalProducts,
    };
  },
});
