







import { defineComponent, useFetch, useRoute } from '@nuxtjs/composition-api';
import { useUpsellProducts } from '@vue-storefront/magento';
import ProductsCarousel from '~/components/ProductsCarousel.vue';
import { usePageStore } from '~/stores';

export default defineComponent({
  name: 'UpsellProducts',
  components: {
    ProductsCarousel,
  },
  setup() {
    const { routeData } = usePageStore();
    const route = useRoute();

    const id = routeData?.sku || route.value?.params?.id;
    const { search, products, loading } = useUpsellProducts(id);

    useFetch(async () => {
      const baseSearchQuery = {
        filter: {
          sku: {
            eq: id,
          },
        },
      };

      await search(baseSearchQuery);
    });

    return {
      products,
      loading,
    };
  },
});
