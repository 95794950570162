var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.soldOutForm.type === 'soldOut')?_c('h4',[_vm._v("\n    "+_vm._s(_vm.$t('Sold out form title'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('p',[_vm._v("\n    "+_vm._s(_vm.$t(_vm.soldOutForm.type === 'soldOut' ? 'Sold out form description' : 'Coming soon form description'))+"\n  ")]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{attrs:{"id":"shipping-details-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitRegisterForComingBackReminderForm)}}},[_c('ValidationProvider',{staticClass:"exponea-form__element",attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"email","label":_vm.$t('Enter your email address'),"required":true,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"accept_terms","rules":{ required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfCheckbox',{attrs:{"name":"terms","label":_vm.$t('Subscribe to newsletter'),"required":true,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"selected":_vm.form.newsletter_signup,"hintMessage":""},model:{value:(_vm.form.newsletter_signup),callback:function ($$v) {_vm.$set(_vm.form, "newsletter_signup", $$v)},expression:"form.newsletter_signup"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"actions modal__button"},[_c('SfButton',{attrs:{"type":"submit","disabled":invalid || _vm.loading || _vm.done}},[(_vm.loading)?_c('SfLoader',{staticClass:"loader",attrs:{"loading":_vm.loading}}):(_vm.done)?_c('CheckmarkIcon'):_c('span',[_vm._v(_vm._s(_vm.$t(_vm.soldOutForm.type === 'soldOut' ? 'Keep me updated' : 'Notify me on release')))])],1),_vm._v(" "),_vm._t("default")],2)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }