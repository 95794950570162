








import { defineComponent } from '@nuxtjs/composition-api';
import FeedbackCompany from './FeedbackCompany.vue';

export default defineComponent({
  name: 'CtaReviews',
  components: { FeedbackCompany },
  computed: {
    document() {
      const { document } = this.component?.getModels();
      return this.page?.getContent(document);
    },
    content() {
      const { document } = this.component?.getModels();
      const content = this.page.getContent(document);
      return content?.model?.data || {};
    },
    isPreview() {
      return this.page?.isPreview();
    },
  },
  props: {
    component: null,
    page: null,
  },
});
