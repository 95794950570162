import { render, staticRenderFns } from "./ProductComingSoonForm.vue?vue&type=template&id=544e7e63&scoped=true&"
import script from "./ProductComingSoonForm.vue?vue&type=script&lang=ts&"
export * from "./ProductComingSoonForm.vue?vue&type=script&lang=ts&"
import style0 from "./ProductComingSoonForm.vue?vue&type=style&index=0&id=544e7e63&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "544e7e63",
  null
  
)

export default component.exports