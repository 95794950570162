
















import { defineComponent, ref, watch, onMounted } from '@nuxtjs/composition-api';
import TopBar from '~/components/TopBar.vue';
import AppHeader from '~/components/AppHeader.vue';
import TopNav from '~/components/TopNav/TopNav.vue';
import { useUiState } from '~/stores';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'TopHeader',
  components: {
    TopBar,
    TopNav,
    AppHeader,
  },
  setup() {
    const uiState = useUiState();
    const { isTopHeaderVisible } = storeToRefs(uiState);
    const { toggleTopHeader } = uiState;
    const lastScrollY = ref(0);
    const lastOnScrollChange = ref(0);
    const lastVisibleScrollY = ref(0);
    const invisibleScrollOffset = ref(0);
    const ticking = ref(false);
    const scrollMargin = 90;
    const paddingTop = ref(0);
    const topHeaderContent = ref();
    const isMobile = ref(true);
    const isSticky = ref(false);

    const determineVisibility = () => {
      let isVisible = true;
      const scrollY = window.scrollY;

      // Always show on top of page
      if (scrollY < 50) {
        return toggleTopHeader(true);
      }

      const scrollDirection = lastScrollY.value < scrollY ? 'down' : 'up';

      // Hide after amount scrolled
      if (scrollY > scrollMargin) {
        isVisible = false;
      }

      if (scrollDirection === 'up') {
        // Show after amount scrolled up
        if (lastOnScrollChange.value - scrollY > scrollMargin) {
          isVisible = true;
        }
      } else {
        lastOnScrollChange.value = scrollY;
      }

      lastScrollY.value = scrollY;
      if (!isMobile.value) invisibleScrollOffset.value = scrollY - lastVisibleScrollY.value;
      else invisibleScrollOffset.value = 0;

      if (isVisible !== isTopHeaderVisible.value) {
        return toggleTopHeader(isVisible);
      }
    };

    const update = () => {
      ticking.value = false;
      determineVisibility();
    };

    const onScroll = () => {
      requestTick();
    };

    const requestTick = () => {
      if (!ticking.value) {
        requestAnimationFrame(update);
      }
      ticking.value = true;
    };

    // Ensure proper height on resize screen or adding/removing elements (like a notification)
    const resizeObserver = () => {
      const observer = new ResizeObserver(() => {
        if (isSticky.value) paddingTop.value = topHeaderContent.value.offsetHeight;
        isMobile.value = window.outerWidth < 1024;
      });
      observer.observe(topHeaderContent.value);
    };

    onMounted(() => {
      isSticky.value = true;
      window.addEventListener('scroll', onScroll, false);
      window.addEventListener('touchmove', onScroll, false);
      if (isSticky.value) paddingTop.value = topHeaderContent.value.offsetHeight;
      isMobile.value = window.outerWidth < 1024;

      resizeObserver();
    });

    watch(isTopHeaderVisible, () => {
      if (!isMobile.value && isTopHeaderVisible.value === false) lastVisibleScrollY.value = window.scrollY;
    });

    return {
      isTopHeaderVisible,
      topHeaderContent,
      paddingTop,
      invisibleScrollOffset,
      lastVisibleScrollY,
      isMobile,
      isSticky,
    };
  },
});
