






import { defineComponent, computed } from '@nuxtjs/composition-api';
import ProductLabels from './ProductLabels.vue';
import { useProductStore } from '~/stores';

export default defineComponent({
  name: 'ProductLabels',
  components: {
    ProductLabels,
  },
  setup() {
    const { getAttribute } = useProductStore();

    const labels = computed(() => getAttribute('product_label') || []);

    return {
      labels,
    };
  },
});
