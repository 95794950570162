
































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfBadge, SfColor, SfLink, SfProperty } from '@storefront-ui/vue';
import CheckmarkIcon from '~/assets/icons/checkmark-alt.svg';
import { getContrast } from '~/helpers/colorHelpers';
import { useProductStore } from '~/stores';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ProductRelatedColors',
  components: {
    SfBadge,
    SfColor,
    SfLink,
    SfProperty,
    CheckmarkIcon,
  },
  props: {
    sku: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { products, attributes } = storeToRefs(useProductStore());

    const colors = computed(
      () =>
        (products.value[props.sku] as any)?.colour_wheels
          ?.map((color) => ({
            ...color,
            url: color.url?.split('/').pop(), // Obtain the product-slug.html off the absolute url
          }))
          .filter((color) => color.url) || [],
    );

    const selectedColorName = computed(() => {
      const colors = attributes.value.find((attribute) => attribute.attribute_code === 'colors')?.attribute_options;
      const color = products.value[props.sku].colors
        ?.split(',')
        ?.map((colorCode) => colors?.find((color) => color.value === colorCode).label);

      return color?.join('/') || '';
    });

    const productUrl = computed(() => products.value[props.sku]?.canonical_url || '');

    return {
      colors,
      selectedColorName,
      productUrl,
      getContrast,
    };
  },
});
