//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@vue/composition-api';
import { SfBadge } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ProductCardLabels',
  components: {
    SfBadge,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    isComingSoon: {
      type: Boolean,
      default: false,
    },
    isSoldOut: {
      type: Boolean,
      default: false,
    },
    // component used in search-suggest module. Cant use useContext() or stores
    translateHandler: {
      type: Function,
      default: (key) => key,
    },
    hidePlpTags: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const showSaleLabel = computed(() => {
      return !props.hidePlpTags
        ?.split(',')
        .map((label) => label?.toLowerCase())
        .includes('sale' || 'promotions');
    });

    const labels = computed(() => {
      const labels = [];
      if (props.isComingSoon) {
        labels.push({
          text: props.translateHandler('COMING SOON'),
          class: 'sf-badge--coming-soon',
        });
      } else if (props.isSoldOut) {
        labels.push({
          text: props.translateHandler('BACK SOON'),
          class: 'sf-badge--back-soon',
        });
      } else if (props.product.specialPrice && showSaleLabel.value) {
        labels.push({
          text: props.translateHandler('SALE'),
          class: 'sf-badge--sale',
        });
      }

      if (props.product.labels?.length) {
        labels.push(
          ...props.product.labels?.map((label) => {
            return {
              text: label,
              class: 'sf-badge--regular',
            };
          }),
        );
      }

      return labels?.slice(0, 2);
    });

    return {
      labels,
    };
  },
});
