






















import { Document, Pagination as BrPagination, Page, Component } from '@bloomreach/spa-sdk';
import { defineComponent, useContext, computed } from '@nuxtjs/composition-api';
import { SfButton, SfCard, SfHeading } from '@storefront-ui/vue';

import CardItem from '~/components/MyJewellery/Banner/CardItem.vue';
import HTMLContent from '~/components/HTMLContent.vue';
import getBynderImageUrl from '~/helpers/bynderImage';

interface BrProps {
  page: Page;
  component: Component;
}

interface ArticleDocument {
  id: string;
  categoryManually: string;
  hideCategory: boolean;
  category: {
    selectionValues: {
      key: string;
      label: string;
    }[];
  };
  contentType: string;
  date: number;
  description: string;
  displayName: string;
  excludeFromFeed: boolean;
  image: string;
  localeString: string;
  name: string;
  title: string;
}

export interface Article {
  title: string;
  category: string;
  categorySlug: string;
  date: number;
  description: string;
  image: string;
}

export default defineComponent({
  name: 'ArticleFeed',
  props: {
    component: null,
    page: null,
  },
  components: {
    CardItem,
    HTMLContent,
    SfButton,
    SfCard,
    SfHeading,
  },
  setup(props) {
    const { page, component } = <BrProps>props;
    const isPreview = page.isPreview();
    const { pagination: paginationRef } = component.getModels();
    const document = page.getDocument<Document>();
    // @ts-ignore
    const { articleLimit } = component.model.meta.paramsInfo;
    const title = computed(() => {
      // @ts-ignore
      return component.model.meta.paramsInfo.title;
    });
    const { content } = document.getData();
    const pagination = paginationRef && page.getContent<BrPagination>(paginationRef);
    const { localePath } = useContext();

    const documents = pagination
      ?.getItems()
      .map((ref) => page.getContent<Document>(ref))
      .filter((doc: Document) => !doc.getData<ArticleDocument>().excludeFromFeed)
      .slice(0, articleLimit ?? 100);

    const articles: Article[] = documents?.map((doc) => {
      const {
        title,
        category,
        date,
        description,
        image,
        titleArticleFeed,
        descriptionArticleFeed,
        categoryManually,
        hideCategory,
      } = doc.getData<ArticleDocument>();

      return <Article>{
        title: titleArticleFeed ? titleArticleFeed : title,
        category: hideCategory ? '' : categoryManually ? categoryManually : category.selectionValues[0]?.label,
        categorySlug: category.selectionValues[0].key,
        date,
        description: descriptionArticleFeed ? descriptionArticleFeed : description,
        image: getBynderImageUrl(image),
        link: localePath(doc.getUrl()),
        asset: image,
      };
    });

    return {
      pagination,
      title,
      content,
      articles,
      articleLimit,
      isPreview,
    };
  },
});
