//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, useContext, useRoute, watch } from '@nuxtjs/composition-api';
import { SfLink, SfList, SfCharacteristic } from '@storefront-ui/vue';
import { storeConfigGetters } from '@vue-storefront/magento';
import { useConfig } from '~/composables';
import { usePageStore, useProductStore, useUiState } from '~/stores';
import Sidebar from '~/components/Sidebar/Sidebar.vue';
import storesJson from '~/composables/useStore/availableStores.json';
import { storeToRefs } from 'pinia';

import deFlagIcon from '~/assets/icons/flags/de.svg';
import enFlagIcon from '~/assets/icons/flags/en.svg';
import frFlagIcon from '~/assets/icons/flags/fr.svg';
import nlFlagIcon from '~/assets/icons/flags/nl.svg';

import CheckIcon from '~/assets/icons/checkmark.svg';
import TimesIcon from '~/assets/icons/times.svg';

export default defineComponent({
  name: 'LocaleSidebar',
  components: {
    SfLink,
    SfList,
    SfCharacteristic,
    Sidebar,
    CheckIcon,
    TimesIcon,
    nlFlagIcon,
    deFlagIcon,
    frFlagIcon,
    enFlagIcon,
  },
  setup() {
    const { i18n, app } = useContext();
    const { config: storeConfig } = useConfig();
    const uiState = useUiState();
    const { toggleLocaleSidebar } = uiState;
    const { isLocaleSidebarOpen } = storeToRefs(uiState);
    const route = useRoute();
    const pageStore = usePageStore();
    const { products } = storeToRefs(useProductStore());
    const apiState = app.$vsf.$magento.config.state;

    const stores = ref(storesJson);

    const availableStores = ref();

    const getStoreLocale = (store) => {
      const storeLocale = store?.locale.split('_')[0];
      const foundLocale = i18n.locales.find((locale) => locale.iso === storeLocale)?.code;
      return foundLocale ?? i18n.defaultLocale;
    };

    const getStoreLocaleIso = (store) => {
      return getStoreLocale(store).substring(0, 2).toUpperCase();
    };

    const getHrefByStore = (store) => {
      const iso = getStoreLocaleIso(store)?.toLowerCase();
      const storeLocale = getStoreLocale(store)?.toLowerCase();
      const hrefLang = pageStore?.hrefLangs?.find((h) => h.hreflang === storeLocale);

      const params = new URL(route.value.fullPath, window.location.origin)?.searchParams;

      // If on product page, return URL's provided by product API
      if (pageStore.routeData?.type === 'PRODUCT') {
        const sku = pageStore.routeData?.sku;

        const url = products.value[sku]?.product_urls?.[0]?.[iso];
        if (!url) return;
        const newUrl = new URL(products.value[sku]?.product_urls?.[0]?.[iso]);
        return `${newUrl.pathname}${params?.size > 0 ? '?' + params.toString() : ''}`;
      }

      if (pageStore.routeData?.type === 'SEARCH') {
        return new URL(decodeURIComponent(app.switchLocalePath(getStoreLocale(store))), window.location.origin);
      }

      const newUrl = new URL(
        decodeURIComponent(hrefLang?.href || app.switchLocalePath(getStoreLocale(store))),
        window.location.origin,
      );

      params.forEach((value, key) => {
        if (newUrl.searchParams.has(key)) return;
        newUrl.searchParams.append(key, value);
      });

      return newUrl;
    };

    const getCurrentLocale = () =>
      apiState.getLocale()?.substring(0, 2).toUpperCase() || storeConfig.value?.locale?.substring(0, 2).toUpperCase();

    watch(isLocaleSidebarOpen, (isOpen) => {
      if (!isOpen) return;
      availableStores.value =
        stores.value.map((store) => {
          return {
            ...store,
            href: getHrefByStore(store),
          };
        }) ?? [];
    });

    return {
      storeConfigGetters,
      availableStores,
      isLocaleSidebarOpen,
      toggleLocaleSidebar,
      getStoreLocaleIso,
      getCurrentLocale,
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
});
