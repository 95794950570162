














import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { getBynderImageUrl, getBynderImageName } from '@/helpers/bynderImage';
import BynderAsset from '~/components/MyJewellery/BynderAsset.vue';

export default defineComponent({
  name: 'FeedbackCompany',
  components: {
    BynderAsset,
  },
  props: {
    id: {
      type: String,
      default: '0cfe14ca-6faa-4a90-bd54-428628f903db',
    },
    image: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'footer',
      validator: (value: string) => {
        return ['inline', 'footer'].includes(value);
      },
    },
  },
  setup(props) {
    const widget = ref();
    const score = ref();
    const url = ref();
    const html = ref();

    const setScript = () => {
      const feedbackScript = <HTMLElement>document.createElement('script');
      feedbackScript.setAttribute('defer', 'defer');
      feedbackScript.setAttribute('charset', 'utf-8');
      feedbackScript.setAttribute('id', `__fbcw__${props.id}`);

      feedbackScript.innerHTML = `
        "use strict";
        !(function () {
          window.FeedbackCompanyWidgets = window.FeedbackCompanyWidgets || {
            queue: [],
            loaders: [],
          };
          var options = {
            uuid: "${props.id}",
            version: "1.2.1",
            prefix: "",
          };
          if (void 0 === window.FeedbackCompanyWidget) {
            if (
              (window.FeedbackCompanyWidgets.queue.push(options),
              !document.getElementById("__fbcw_FeedbackCompanyWidget"))
            ) {
              var scriptTag = document.createElement("script");
              (scriptTag.onload = function () {
                if (window.FeedbackCompanyWidget)
                for (; 0 < window.FeedbackCompanyWidgets.queue.length; )
              (options = window.FeedbackCompanyWidgets.queue.pop()),
            window.FeedbackCompanyWidgets.loaders.push(
              new window.FeedbackCompanyWidgetLoader(options)
            );
          }),
          (scriptTag.defer = true),
          (scriptTag.id = "__fbcw_FeedbackCompanyWidget"),
          (scriptTag.src =
          "https://www.feedbackcompany.com/includes/widgets/feedback-company-widget.min.js"),
          document.body.appendChild(scriptTag);
        }
      } else
      window.FeedbackCompanyWidgets.loaders.push(
        new window.FeedbackCompanyWidgetLoader(options)
      );
    })();
    `;

      widget.value.appendChild(feedbackScript);

      window.checkElement('.__fbcw__widget').then((element) => {
        url.value = element.href;
        score.value = element.querySelector('.__fbcw__widget__label')?.textContent?.split('/')[0]?.trim();
      });
    };

    onMounted(() => {
      setScript();
    });

    return {
      widget,
      getBynderImageUrl,
      getBynderImageName,
      url,
      score,
      html,
    };
  },
});
