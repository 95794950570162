






















import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfAccordion, SfProperty, SfTabs, SfSkeleton } from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';
import HTMLContent from '~/components/HTMLContent.vue';
import ProductDeliveryText from '~/components/Product/ProductDeliveryText.vue';
import { useProductStore } from '~/stores';

export default defineComponent({
  name: 'ProductTabs',
  components: {
    HTMLContent,
    LazyHydrate,
    SfAccordion,
    SfProperty,
    SfTabs,
    SfSkeleton,
    ProductDeliveryText,
  },
  props: {
    product: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup({ product }) {
    const productStore = useProductStore();
    const { app } = useContext();
    const productColors = computed(() => product?.colors || '');
    const productDescription = computed(() => product?.description?.html || '');
    const productHighlights = computed(() => product?.highlights || '');
    const productFit = computed(() => product?.fit || '');
    const productSku = computed(() => product?.sku || '');

    const convertMetadataNumbersToString = (attributeValues: string, attributeCode: string) => {
      const allMetadata = productStore.attributes.find((a) => a.attribute_code === attributeCode);
      const numbers = attributeValues?.toString().split(',') || [];
      return numbers
        ?.map(
          (number): string =>
            allMetadata?.attribute_options?.find((option) => option.value === number.toString())?.label,
        )
        .join(', ');
    };

    const productColorsValues = computed(() => convertMetadataNumbersToString(productColors.value, 'colors'));
    const productFitValue = computed(() => convertMetadataNumbersToString(productFit.value, 'fit'));

    const propertiesMap = {
      sku: {
        key: 'sku',
        name: 'SKU',
        metadata: productSku.value,
      },
      fit: {
        key: 'fit',
        name: app.i18n.t('Fit'),
        metadata: productFitValue,
      },
      colors: {
        key: 'colors',
        name: app.i18n.t('Colors'),
        metadata: productColorsValues,
      },
    };

    const properties = computed(() =>
      Object.entries(propertiesMap)
        .map(([k, v], i) => ({
          name: v.name,
          value: v.metadata?.value || product?.[v.key],
        }))
        .filter((f) => f.value),
    );

    return {
      productDescription,
      productHighlights,
      properties,
      productSku,
    };
  },
});
