//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, computed } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import ChevronDownIcon from '~/assets/icons/chevron-down-24.svg';

export default {
  components: {
    SfLink,
    ChevronDownIcon,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    active: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const open = ref(false);
    const itemList = computed(() => {
      return props.items.filter((item) => item.title !== props.active);
    });

    return { open, itemList };
  },
};
