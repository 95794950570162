//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfModal, SfHeading, SfInput, SfButton, SfScrollable, SfBar, SfLink } from '@storefront-ui/vue';
import { ref } from '@nuxtjs/composition-api';
import { useUiState } from '~/stores';
import { storeToRefs } from 'pinia';

export default {
  name: 'NewsletterModal',
  components: {
    SfModal,
    SfHeading,
    SfInput,
    SfButton,
    SfScrollable,
    SfBar,
    SfLink,
  },
  setup() {
    const uiState = useUiState();
    const { toggleNewsletterModal } = uiState;
    const { isNewsletterModalOpen } = storeToRefs(uiState);

    const isHidden = ref(true);
    const emailAddress = ref('');

    return {
      isNewsletterModalOpen,
      toggleNewsletterModal,
      isHidden,
      emailAddress,
    };
  },
};
