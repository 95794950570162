










import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfLoader, SfPrice, SfSkeleton } from '@storefront-ui/vue';
import type { Product } from '@/types/interfaces/product';

export default defineComponent({
  name: 'ProductPrice',
  components: {
    SfLoader,
    SfPrice,
    SfSkeleton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object as () => Product,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const mjGiftCard = props.product.sku === 'mjgiftcard';

    const getProductPrice = () => {
      const regularPrice = mjGiftCard ? 'minimum_price' : 'maximum_price';
      return props.product?.price_range?.[regularPrice]?.regular_price?.value || '';
    };

    const productPrice = computed(() => getProductPrice());

    const getProductSpecialPrice = () => {
      const finalPrice = props.product?.price_range?.maximum_price?.final_price?.value;
      if (mjGiftCard || productPrice.value === finalPrice) {
        return null;
      }
      return finalPrice;
    };

    const productSpecialPrice = computed(() => getProductSpecialPrice());

    return {
      productPrice,
      productSpecialPrice,
    };
  },
});
