










































































import { defineComponent, onMounted, ref, computed } from '@nuxtjs/composition-api';
import { SfButton, SfLoader, SfProperty } from '@storefront-ui/vue';
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);
import 'swiper/swiper-bundle.min.css';
import { useProduct } from '~/composables';
import { encodeBase64 } from '~/plugins/base64';
import ProductCard from '~/components/MyJewellery/ProductCard.vue';
import ProductCardLoading from '~/components/MyJewellery/ProductCardLoading.vue';
import ProductsCarousel from '../ProductsCarousel.vue';
import ChevronSliderIcon from '~/assets/icons/chevron-slider.svg';
import { useUiState } from '~/stores';
import { useProductStore } from '~/stores';
import getIsDisabled from '~/helpers/getIsDisabledProductVariantOption';
import { getSelectedProductVariantByUid } from '~/helpers/product/productGetters';

export default defineComponent({
  name: 'ProductVariantGiftcardPrice',
  components: {
    ChevronSliderIcon,
    ProductCard,
    ProductCardLoading,
    ProductsCarousel,
    SfButton,
    SfLoader,
    SfProperty,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    productConfiguration: {
      type: Object,
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
    pid: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { setSoldOutFormData } = useUiState();
    const productStore = useProductStore();

    const selectedVariant = computed(
      () =>
        props.option.values.find((value) => value.uid === props.productConfiguration[props.option.attribute_uid]) || {},
    );

    const cacheId = 'giftcardholder';
    const holderCatUid = encodeBase64('496');
    const products = ref([]);
    const selectedGiftcardHolderSku = ref(null);
    const swiper = ref(null);
    const swiperRef = ref(null);
    const { getProductList, loading: productsLoading } = useProduct(cacheId);

    const fetchProducts = async () => {
      const searchQuery = {
        pageSize: 20,
        customQuery: {
          products: 'products',
          metadata: {},
        },
        filter: {
          category_uid: {
            eq: holderCatUid,
          },
        },
        sort: {
          //
        },
      };
      const result = await getProductList(searchQuery);
      products.value = result?.items;
    };

    const selectGiftcardHolder = (product) => {
      selectedGiftcardHolderSku.value = product?.sku;
      productStore.giftcardHolder = product || null;
    };

    const initializeSwiper = () => {
      swiper.value = new Swiper(swiperRef.value, {
        // @ts-ignore
        modules: [Navigation],
        speed: 400,
        threshold: 2,
        slidesPerView: 3.5,
        spaceBetween: 8,
        roundLengths: true,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 16,
        breakpoints: {
          768: {
            slidesPerView: 3.5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            spaceBetween: 8,
          },
          1024: {
            slidesPerView: 4.5,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            spaceBetween: 8,
          },
        },
        navigation: {
          prevEl: '.product__giftcard-holder__button--prev',
          nextEl: '.product__giftcard-holder__button--next',
        },
      });
    };

    const selectVariant = (value) => {
      if (value.out_of_stock === 'OUT_OF_STOCK') {
        return setSoldOutFormData({
          productId: props.pid,
          isModalVisible: true,
          optionLabel: value.label,
        });
      }
      emit('updateProductConfiguration', { key: props.option.attribute_uid, value: value.uid });
    };

    const disabled = computed(() => getIsDisabled(props));

    const selectedProductSku = (uid) =>
      getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku;
    const selectedProductId = (uid) =>
      getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id;

    onMounted(async () => {
      await fetchProducts();
      if (products.value.length) {
        selectGiftcardHolder(products.value[0]);
      }
      initializeSwiper();
    });

    return {
      products,
      productsLoading,
      selectedGiftcardHolderSku,
      selectGiftcardHolder,
      swiperRef,
      selectedVariant,
      selectVariant,
      disabled,
      selectedProductSku,
      selectedProductId,
    };
  },
});
