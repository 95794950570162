import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=3bbeee6c&"
import script from "./AppHeader.vue?vue&type=script&lang=ts&"
export * from "./AppHeader.vue?vue&type=script&lang=ts&"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=3bbeee6c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderLogo: require('/builds/my-jewellery/code/frontend/components/HeaderLogo.vue').default,StoreSwitcher: require('/builds/my-jewellery/code/frontend/components/StoreSwitcher.vue').default})
