

























import { defineComponent, computed, ref, onMounted } from '@nuxtjs/composition-api';
import { SfLoader, SfButton } from '@storefront-ui/vue';

import TimesIcon from '~/assets/icons/times.svg';
import { useCartStore, useProductStore } from '~/stores';
import { storeToRefs } from 'pinia';
import ProductVariantMatrix from '~/components/Product/ProductVariantMatrix.vue';
import ProductActions from '../Product/ProductActions.vue';

export default defineComponent({
  name: 'CartVariant',
  components: {
    ProductVariantMatrix,
    SfLoader,
    SfButton,
    TimesIcon,
    ProductActions,
  },
  setup() {
    const { products: storeProducts, activeProductSku } = storeToRefs(useProductStore());
    const { showAtcModal } = storeToRefs(useCartStore());

    const product = computed(() => {
      return storeProducts.value[activeProductSku.value];
    });
    const sku = computed(() => product.value?.sku);

    const productVariantMatrix = ref();
    const productConfiguration = computed(() => productVariantMatrix.value?.productConfiguration || {});

    const productActions = ref();
    const addingProductToCart = computed(() => productActions.value?.isLoadingAddToCart);

    const addToCartImediately = computed(
      () =>
        showAtcModal.value &&
        ((product.value?.configurable_options?.length === 1 &&
          product.value?.configurable_options?.[0]?.values?.length === 1) ||
          product.value?.__typename === 'SimpleProduct'),
    );

    onMounted(() => {
      if (addToCartImediately.value) productActions.value?.addItemToCart();
    });

    const addedToCartHandler = () => {
      showAtcModal.value = false;
    };

    return {
      showAtcModal,
      product,
      sku,
      productVariantMatrix,
      productConfiguration,
      productActions,
      addingProductToCart,
      addedToCartHandler,
    };
  },
});
