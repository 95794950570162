//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { extend } from 'vee-validate';
import { required, min, confirmed } from 'vee-validate/dist/rules';
import { SfTabs } from '@storefront-ui/vue';
import { defineComponent, onMounted, ref, useContext, useRoute } from '@nuxtjs/composition-api';
import { useUser } from '~/composables';
import ProfileUpdateForm from '~/components/MyAccount/ProfileUpdateForm.vue';
import PasswordResetForm from '~/components/MyAccount/PasswordResetForm.vue';
import { customerPasswordRegExp, invalidPasswordMsg } from '../../helpers/customer/regex';
import TabMenu from '~/components/MyAccount/TabMenu.vue';

export default defineComponent({
  name: 'MyProfile',

  components: {
    SfTabs,
    TabMenu,
    ProfileUpdateForm,
    PasswordResetForm,
  },

  setup() {
    const { app } = useContext();
    const route = useRoute();
    const openTab = ref(route.value.hash?.substring(1) || 1);
    const { changePassword, errors, load, loading, updateUser, error } = useUser();

    const formHandler = async (fn, onComplete, onError) => {
      await fn();
      const actionErr = error.value.changePassword || error.value.updateUser;
      if (actionErr) {
        onError(actionErr);
      } else {
        onComplete();
      }
    };

    const updatePersonalData = ({ form, onComplete, onError }) =>
      formHandler(async () => updateUser({ user: form.value }), onComplete, onError);

    const updatePassword = ({ form, onComplete, onError }) =>
      formHandler(
        async () =>
          changePassword({
            current: form.value.currentPassword,
            new: form.value.newPassword,
          }),
        onComplete,
        onError,
      );

    onMounted(async () => {
      // await load();
    });

    extend('required', {
      ...required,
      message: 'This field is required',
    });

    extend('min', {
      ...min,
      message: 'The field should have at least {length} characters',
    });

    extend('password', {
      message: app.i18n.t('Invalid Password Message'),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      validate: (value) => customerPasswordRegExp.test(value),
    });

    extend('confirmed', {
      ...confirmed,
      message: app.i18n.t("Passwords don't match"),
    });

    return {
      loading,
      errors,
      updatePersonalData,
      updatePassword,
      openTab,
    };
  },
});
