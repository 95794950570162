























import { onSSR } from '@vue-storefront/core';
import { defineComponent, onMounted, useRoute } from '@nuxtjs/composition-api';
import { useCache } from '@vue-storefront/cache';
import { useBloomreachComponents, useBloomreach } from '~/composables';

import { wishlistStore } from '~/stores';
import { getBynderImageObject } from '~/helpers/bynderImage';

import AppFooter from '~/components/AppFooter.vue';
import BlogPost from '~/components/MyJewellery/PageLayout/BlogPost.vue';
import CustomerService from '~/components/MyJewellery/PageLayout/CustomerService.vue';
import OneColumn from '~/components/MyJewellery/PageLayout/OneColumn.vue';
import Shop from '~/components/MyJewellery/PageLayout/Shop.vue';
import TwoColumn from '~/components/MyJewellery/PageLayout/TwoColumn.vue';
import TopHeader from '~/components/MyJewellery/TopHeader.vue';
import TopNavMenu from '~/components/TopNav/TopNavMenu.vue';

export default defineComponent({
  components: {
    AppFooter,
    TopHeader,
    TopNavMenu,
    BlogPost,
    CustomerService,
    OneColumn,
    Shop,
    TwoColumn,
  },
  async asyncData(context) {
    const { getPage } = useBloomreach(context);
    const { page, pageLayout, pageData, hrefLangs, configuration } = await getPage();
    const currentLocale = context.app.i18n.locale;

    return {
      page,
      pageLayout,
      pageData,
      currentLocale,
      hrefLangs,
      configuration,
    };
  },
  head() {
    const image = getBynderImageObject(this.pageData?.image);
    const ogImage =
      (image && [
        {
          hid: 'og:image',
          name: 'og:image',
          content: image.url || null,
        },
        {
          hid: 'og:image:width',
          name: 'og:image:width',
          content: image.width || 600,
        },
        {
          hid: 'og:image:height',
          name: 'og:image:height',
          content: image.height || 600,
        },
      ]) ||
      [];

    return {
      title: this.pageData?.title,
      link: [
        ...(this.hrefLangs || []),
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.pageData?.canonical || `${this.$config.storeUrl}${this.$route.fullPath}` || null,
        },
      ],
      htmlAttrs: {
        lang: this.currentLocale,
      },
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.pageData?.title,
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: process.env.NUXT_APP_NAME || 'My Jewellery',
        },
        {
          hid: 'description',
          name: 'description',
          content: this.pageData?.description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.pageData?.description,
        },
        {
          hid: 'robots',
          name: 'robots',
          content: `${this.pageData?.robotsIndex?.selectionValues?.[0]?.label || 'index'}, ${
            this.pageData?.robotsFollow?.selectionValues?.[0]?.label || 'follow'
          }`.trim(),
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.pageData?.metaKeywords,
        },
        ...ogImage,
      ],
    };
  },
  setup() {
    const { addTags } = useCache();
    const route = useRoute();
    const { addToWishlistAfterLogin } = wishlistStore();
    const { mapping } = useBloomreachComponents();

    onSSR(async () => {
      addTags([{ prefix: 'CMS', value: route.value?.path.replace(/\/+$/, '') }]);
    });

    onMounted(addToWishlistAfterLogin);

    return {
      mapping,
    };
  },
});
