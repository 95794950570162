




















import { Document, Page } from '@bloomreach/spa-sdk';
import { defineComponent } from '@nuxtjs/composition-api';
import Breadcrumbs from '../Breadcrumbs.vue';
import CategoryMenu from '../CategoryMenu.vue';

export default defineComponent({
  name: 'BlogPost',
  props: {
    page: null,
    component: null,
  },
  components: {
    Breadcrumbs,
    CategoryMenu,
  },
  setup(props) {
    const { page }: { page: Page } = props;
    const document = page?.getDocument<Document>();
    const { contentType } = document.getData();

    return {
      contentType,
      isBlogArticle: contentType === 'brxsaas:mjArticlePageB',
    };
  },
});
