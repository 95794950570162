//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfRadio, SfLoader } from '@storefront-ui/vue';
import { useContext, ref, onMounted, defineComponent, computed, useRouter } from '@nuxtjs/composition-api';
import { usePaymentProvider, useCart, useUiNotification } from '~/composables';
import { useCartStore } from '~/stores';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { storeToRefs } from 'pinia';

//Payment provider icons
import ApplePayIcon from '~/assets/icons/paymentProviders/applepay.svg';
import FashionChequeGiftCardIcon from '~/assets/icons/paymentProviders/giftcardfashioncheque.svg';
import GenericGiftcardIcon from '~/assets/icons/paymentProviders/giftcardgenericgiftcard.svg';
import VVVGiftcardIcon from '~/assets/icons/paymentProviders/giftcardvvvgiftcard.svg';
import IdealIcon from '~/assets/icons/paymentProviders/ideal.svg';
import KlarnaIcon from '~/assets/icons/paymentProviders/klarna.svg';
import PaypalExpressIcon from '~/assets/icons/paymentProviders/paypal_express.svg';
import PayWithGoogleIcon from '~/assets/icons/paymentProviders/paywithgoogle.svg';
import SchemeIcon from '~/assets/icons/paymentProviders/scheme.svg';
import logger from '~/utilities/logger';

export default defineComponent({
  name: 'AdyenPaymentProvider',
  components: {
    SfRadio,
    SfLoader,
  },
  props: {
    selectedMethod: {
      type: String,
    },
  },
  model: {
    prop: 'selectedMethod',
    event: 'changeSelectedMethod',
  },
  setup(props, { emit }) {
    const { app } = useContext();
    const router = useRouter();
    const { save: savePayment, error: paymentError } = usePaymentProvider();
    const { load: loadCart } = useCart();
    const cartStore = useCartStore();
    const { paymentMethodsAdyen } = storeToRefs(cartStore);
    const adyenComponents = computed(
      () => paymentMethodsAdyen.value?.paymentMethodsResponse?.paymentMethods?.map((x) => x.type) || [],
    );
    const componentRefs = ref([]);
    const checkout = ref();
    const adyenState = ref();
    const selectedPaymentMethod = computed({
      get() {
        return props.selectedMethod;
      },
      set(value) {
        emit('changeSelectedMethod', value);
      },
    });
    const { send: sendNotification } = useUiNotification();

    onMounted(async () => {
      setWebComponents();
    });

    const adyenPaymentMethods = computed(() => {
      const methods = paymentMethodsAdyen.value?.paymentMethodsResponse?.paymentMethods || [];
      return methods.map((method) => {
        return {
          ...method,
          details: paymentMethodsAdyen.value?.paymentMethodsExtraDetails.find(
            (details) => details.type === method.type,
          ),
        };
      });
    });

    // Actions
    const selectPaymentMethod = (method) => {
      selectedPaymentMethod.value = method.type + method.brand;
    };

    const saveAdyenPaymentMethod = async (method) => {
      let paymentCode = null;
      switch (method.type) {
        case 'ideal':
        case 'applepay':
        case 'paywithgoogle':
        case 'giftcard':
          paymentCode = 'adyen_hpp';
          break;
        case 'scheme':
          paymentCode = 'adyen_cc';
          break;
        default:
          paymentCode = 'adyen_hpp';
          logger.warn('Payment method not found');
      }

      try {
        const order = await savePayment({
          provider: 'adyen',
          code: paymentCode,
          paymentMethod: {
            type: method.type,
          },
          state: adyenState.value?.data,
        });
        await loadCart();
        return order;
      } catch (e) {
        logger.error(e);
      }
    };

    const setWebComponents = async () => {
      const handleOnChange = (state, component) => {
        state.isValid;
        state.data;
        component;
        adyenState.value = state;
      };

      const handleOnAdditionalDetails = (state, component) => {
        state.data;
        component;
        adyenState.value = state;
      };

      const handleOnSubmit = async (state, component) => {
        adyenState.value = state;
        try {
          const response = await saveAdyenPaymentMethod(state.data.paymentMethod);

          logger.log({ response });
          component.setStatus('ready');

          // Succes
          if (['Authorised', 'Received', 'PresentToShopper'].includes(response?.adyen_payment_status.resultCode)) {
            return router.push(app.localePath(`/checkout/thank-you?order=${response.order_number}`));
          }

          // Action needed (handled by component)
          if (response?.adyen_payment_status.action) {
            return component.handleAction(JSON.parse(response.adyen_payment_status.action));
          }

          throw response;
        } catch (error) {
          sendNotification({
            id: Symbol('save_adyen_payment_method_error'),
            message: paymentError.value?.save,
            type: 'error',
            icon: 'error',
            persist: false,
            title: 'Error',
          });
        }
      };

      const configuration = {
        locale: 'nl_NL',
        environment: 'test',
        clientKey: process.env.NUXT_ENV_ADYEN_CLIENT_KEY,
        analytics: {
          enabled: true,
        },
        showPayButton: true,
        paymentMethodsResponse: paymentMethodsAdyen.value?.paymentMethodsResponse,
        onChange: handleOnChange,
        onAdditionalDetails: handleOnAdditionalDetails,
        onSubmit: handleOnSubmit,
      };

      checkout.value = await AdyenCheckout(configuration);
      adyenComponents.value.forEach((method, index) => {
        if (method === 'giftcard') {
          const giftcardConfiguration = {
            brand: adyenPaymentMethods.value[index].brand,
            onOrderCreated: function (orderStatus) {
              logger.log({ orderStatus, remainingAmount: orderStatus.remainingAmount });
            },
          };
          return checkout.value.create(method, giftcardConfiguration).mount(componentRefs.value[index]);
        }
        checkout.value.create(method).mount(componentRefs.value[index]);
      });
    };

    const icon = (type) => {
      switch (type) {
        case 'applepay':
          return ApplePayIcon;
        case 'fashioncheque':
          return FashionChequeGiftCardIcon;
        case 'genericgiftcard':
          return GenericGiftcardIcon;
        case 'vvvgiftcard':
          return VVVGiftcardIcon;
        case 'ideal':
          return IdealIcon;
        case 'klarna':
          return KlarnaIcon;
        case 'paypal_express':
          return PaypalExpressIcon;
        case 'paywithgoogle':
          return PayWithGoogleIcon;
        case 'scheme':
          return SchemeIcon;
        default:
          return SchemeIcon;
      }
    };

    const mostPickedPaymentMethod = 'ideal';

    return {
      selectedPaymentMethod,
      adyenPaymentMethods,
      componentRefs,
      selectPaymentMethod,
      icon,
      mostPickedPaymentMethod,
    };
  },
});
