



































import { useCartStore } from '~/stores';
import { SfSteps } from '@storefront-ui/vue';
import { defineComponent, computed, useRoute, useRouter, useContext, onMounted } from '@nuxtjs/composition-api';
import { useBloomreachComponents, useUser, useCart, useBloomreach } from '~/composables';

import AppFooter from '~/components/AppFooter.vue';
import TopHeader from '~/components/MyJewellery/TopHeader.vue';

export default defineComponent({
  name: 'CheckoutPage',
  components: {
    TopHeader,
    AppFooter,
    SfSteps,
  },
  async asyncData(context) {
    const { getPage } = useBloomreach(context);
    const { page, pageData, hrefLangs, configuration } = await getPage('');

    return {
      page,
      pageData,
      hrefLangs,
      configuration,
    };
  },
  head() {
    return {
      title: this.pageData?.title,
      link: [
        ...(this.hrefLangs || []),
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.pageData?.canonical || `${this.$config.storeUrl}${this.$route.fullPath}` || null,
        },
      ],
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.pageData?.title,
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: process.env.NUXT_APP_NAME || 'My Jewellery',
        },
        {
          hid: 'description',
          name: 'description',
          content: this.pageData?.description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.pageData?.description,
        },
        {
          hid: 'robots',
          name: 'robots',
          content: `${this.pageData?.robotsIndex?.selectionValues[0].label || 'index'}, ${
            this.pageData?.robotsFollow?.selectionValues[0].label || 'follow'
          }`.trim(),
        },
      ],
    };
  },
  setup() {
    const cartStore = useCartStore();
    const route = useRoute();
    const { app } = useContext();
    const { mapping } = useBloomreachComponents();
    const router = useRouter();
    const { load } = useCart();
    const currentStep = computed(() => route.value.path.split('/').pop());
    const { isAuthenticated } = useUser();

    const STEPS = computed(() => {
      const steps = [
        {
          title: 'Shipping',
          url: 'shipping',
        },
        {
          title: 'Overview',
          url: 'overview',
        },
        {
          title: 'Payment',
          url: 'payment',
        },
      ];

      if (isAuthenticated.value) {
        return steps;
      }
      return [
        {
          title: 'User Account',
          url: 'user-account',
        },
        ...steps,
      ];
    });

    const currentStepIndex = computed(() => {
      const val = STEPS.value.findIndex((step) => step.url === currentStep.value);
      return val < 0 ? 0 : val;
    });
    const isThankYou = computed(() => currentStep.value === 'thank-you' || false);

    const handleStepClick = async (stepIndex: number) => {
      const { url } = STEPS.value[stepIndex];
      await router.push(`${app.localePath(`/checkout/${url}`)}`);
    };

    onMounted(async () => {
      if (route.value.path.split('/').pop() === 'checkout') {
        if (isAuthenticated.value) {
          await router.push(app.localePath(`/checkout/shipping`));
        } else {
          await router.push(app.localePath(`/checkout/user-account`));
        }
      }

      await load({ completeCart: true });

      if (cartStore.cart.items.length === 0 && currentStep.value !== 'thank-you') {
        await router.push(app.localePath('/'));
      }
    });

    return {
      handleStepClick,
      STEPS,
      currentStepIndex,
      isThankYou,
      currentStep,
      mapping,
    };
  },
});
