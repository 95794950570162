

























import { defineComponent, ref, computed, useContext, useRoute, useRouter, watch } from '@nuxtjs/composition-api';
import { SfInput, SfButton, SfCheckbox, SfLoader, SfBar, SfBreadcrumbs } from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { customerPasswordRegExp } from '~/helpers/customer/regex';
import { useCustomerStore } from '~/stores';

import LazyHydrate from 'vue-lazy-hydration';
import { useBloomreach } from '~/composables';

import { getBynderImageUrl, getBynderImageName } from '~/helpers/bynderImage';
import useBloomreachComponents from '~/composables/useBloomreachComponents';

import AppFooter from '~/components/AppFooter.vue';
import Login from './MyAccount/Login.vue';
import LogoutSuccess from './MyAccount/LogoutSuccess.vue';
import Register from './MyAccount/Register.vue';
import ForgotPassword from './MyAccount/ForgotPassword.vue';
import Breadcrumbs from '@/components/MyJewellery/Breadcrumbs.vue';
import TopHeader from '~/components/MyJewellery/TopHeader.vue';

export default defineComponent({
  components: {
    AppFooter,
    SfBreadcrumbs,
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    ValidationProvider,
    ValidationObserver,
    SfBar,
    LazyHydrate,
    Login,
    LogoutSuccess,
    Register,
    ForgotPassword,
    Breadcrumbs,
    TopHeader,
  },
  methods: {
    getBynderImageUrl,
    getBynderImageName,
  },
  async asyncData(context) {
    const { getPage } = useBloomreach(context);
    const { page, pageData, hrefLangs, configuration } = await getPage('');

    return {
      page,
      pageData,
      hrefLangs,
      configuration,
    };
  },
  head() {
    return {
      title: 'Login',
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { app } = useContext();
    const { mapping } = useBloomreachComponents();

    const activePage = computed(() => {
      const lastSlug = router.currentRoute.fullPath
        ?.split('/')
        .filter((path) => path !== '')
        ?.pop();
      return lastSlug || 'login';
    });

    const activePageComponent = computed(() => {
      const components = {
        login: 'Login',
        create: 'Register',
        logoutSuccess: 'LogoutSuccess',
        forgotpassword: 'ForgotPassword',
      };
      return components?.[activePage.value] || 'Login';
    });

    const breadcrumbs = ref([
      {
        text: app.i18n.t('Home - WORK IN PROGRESS'),
        route: { link: '/' },
      },
      {
        text: app.i18n.t('My Account'),
        route: { link: '/customer/account' },
      },
      {
        text: app.i18n.t(activePage.value),
        route: { link: `/customer/account/${activePage.value}` },
      },
    ]);

    const customerStore = useCustomerStore();
    const isLoggedIn = computed(() => customerStore.isLoggedIn);

    const AuthenticatedHandler = async () => {
      const redirectToReferer = route.value.query?.['redirectToReferer']?.toString();

      if (!redirectToReferer) {
        return router.replace(`${app.localePath('/customer/account')}`);
      }

      if (redirectToReferer === '1') {
        return router.go(-1);
      }
      router.push(app.localePath(redirectToReferer));
    };

    watch(isLoggedIn, (isLoggedIn) => {
      if (isLoggedIn) AuthenticatedHandler();
    });

    extend('email', {
      ...email,
      message: 'Invalid email',
    });

    extend('required', {
      ...required,
      message: 'This field is required',
    });

    extend('password', {
      message: 'Invalid Password',
      validate: (value) => customerPasswordRegExp.test(value),
    });

    return {
      mapping,
      activePage,
      activePageComponent,
      breadcrumbs,
    };
  },
});
