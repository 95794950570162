


























































import { defineComponent, onMounted, ref, useContext, watch, PropType, toRefs } from '@nuxtjs/composition-api';
import { SfSkeleton } from '@storefront-ui/vue';
import HomeIcon from '@/assets/icons/home.svg';
import ChevronLeftIcon from '@/assets/icons/chevron-left.svg';
import ChevronRightIcon from '@/assets/icons/chevron-right.svg';
import { useBreadcrumbsStore } from '~/stores';
import { Breadcrumb } from '~/types/interfaces/breadcrumbs';
import { storeToRefs } from 'pinia';

export default defineComponent({
  components: {
    SfSkeleton,
    HomeIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    items: {
      type: Array as PropType<Breadcrumb[]> | null,
      default: null,
    },
  },
  setup(props) {
    const { app } = useContext();
    const expanded = ref(false);
    const breadcrumbContainer = ref(null);
    const breadcrumbList = ref(null);
    const isMobile = ref(!app.$device.isDesktop);
    const breadcrumbsStore = useBreadcrumbsStore();
    const { breadcrumbs } = storeToRefs(breadcrumbsStore);
    const { autoGenerateBreadcrumbs } = breadcrumbsStore;
    const loading = ref(true);

    const propBreadcrumbs = toRefs(props).items;

    const generate = async () => {
      breadcrumbs.value = [];

      if (!isMobile.value) {
        breadcrumbs.value.push({
          link: app.localePath('/') + '/',
          text: 'Home',
          isHome: true,
        });
      }
      /*
       pdps and pcps receive the Breadcrumbs as props
       Auto generate only if there are no prop breadcrumbs
       */
      if (propBreadcrumbs.value) {
        const previousBreadcrumb = propBreadcrumbs.value.slice(-2, -1);
        breadcrumbs.value.push(...(isMobile.value ? previousBreadcrumb : propBreadcrumbs.value));
      } else {
        await autoGenerateBreadcrumbs(isMobile.value);
      }
    };

    watch(propBreadcrumbs, generate);
    watch(isMobile, generate);

    onMounted(async () => {
      await generate();
      loading.value = false;
    });

    return {
      breadcrumbs,
      isMobile,
      breadcrumbContainer,
      breadcrumbList,
      expanded,
      loading,
    };
  },
});
