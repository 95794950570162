//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfTabs, SfCheckbox, SfButton, SfLink, SfLoader } from '@storefront-ui/vue';
import { onSSR } from '@vue-storefront/core';
import { defineComponent, ref, onMounted, watch } from '@nuxtjs/composition-api';
import { useUser } from '~/composables';
import CheckIcon from '~/assets/icons/checkmark-alt.svg';

export default defineComponent({
  name: 'MyNewsletter',
  components: {
    CheckIcon,
    SfButton,
    SfCheckbox,
    SfLink,
    SfLoader,
    SfTabs,
  },
  setup() {
    const { user, load, loading, updateUser, isAuthenticated } = useUser();

    const isSubscribed = ref(!!user.value?.is_subscribed);
    const isSuccess = ref(false);

    onMounted(async () => {
      // await load();
    });

    const saveForm = async () => {
      if (isAuthenticated.value && !!user.value.email) {
        await updateUser({
          user: {
            is_subscribed: isSubscribed.value,
          },
        });
        isSuccess.value = true;
        setTimeout(() => (isSuccess.value = false), 1200);
      }
    };

    return {
      isSubscribed,
      isSuccess,
      saveForm,
      loading,
    };
  },
});
