








































import { SfProperty, SfButton, SfNotification } from '@storefront-ui/vue';
import { defineComponent, computed, ref, PropType } from '@nuxtjs/composition-api';
import SizeChartSidebar from '~/components/Product/SizeChartSidebar.vue';
import BellIcon from '~/assets/icons/bell.svg';
import { useUiState } from '~/stores';
import getIsDisabled from '~/helpers/getIsDisabledProductVariantOption';
import { getSelectedProductVariantByUid } from '~/helpers/product/productGetters';

export default defineComponent({
  name: 'ProductVariantSize',
  components: {
    SfProperty,
    SfButton,
    SfNotification,
    BellIcon,
    SizeChartSidebar,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    optionsLength: {
      type: Number,
      required: true,
    },
    productConfiguration: {
      type: Object,
      required: true,
    },
    type: {
      type: String as PropType<'wishlist' | 'cart'>,
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
    pid: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { setSoldOutFormData } = useUiState();

    const errorNoVariantSelected = ref(false);

    const selectedVariant = computed(
      () =>
        props.option.values.find((value) => value.uid === props.productConfiguration[props.option.attribute_uid]) || {},
    );

    const isSizeChartSidebarOpen = ref(false);
    const toggleSizeChartSidebar = () => {
      isSizeChartSidebarOpen.value = !isSizeChartSidebarOpen.value;
    };

    const disabled = computed(() => getIsDisabled(props));

    const selectVariant = (value) => {
      if (disabled.value) return showErrorNotification();

      if (value.out_of_stock === 'OUT_OF_STOCK' && props.type === 'cart') {
        return setSoldOutFormData({
          productId: props.pid,
          isModalVisible: true,
          optionLabel: value.label,
        });
      }
      emit('updateProductConfiguration', { key: props.option.attribute_uid, value: value.uid });
    };

    const getDisabledForOption = (out_of_stock_status) =>
      out_of_stock_status === 'OUT_OF_STOCK' && props.type !== 'wishlist';

    const selectedProductSku = (uid) =>
      getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku;
    const selectedProductId = (uid) =>
      getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id;

    const showErrorNotification = () => {
      errorNoVariantSelected.value = true;
      return setTimeout(() => (errorNoVariantSelected.value = false), 3000);
    };

    const errorNotificationMessage = ref(
      props.optionsLength > 2 ? 'Please select the option above first' : 'Please select a color first',
    );

    return {
      errorNoVariantSelected,
      selectedVariant,
      isSizeChartSidebarOpen,
      toggleSizeChartSidebar,
      selectVariant,
      getDisabledForOption,
      disabled,
      selectedProductSku,
      selectedProductId,
      errorNotificationMessage,
    };
  },
});
