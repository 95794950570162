










import { defineComponent, computed, onMounted } from '@nuxtjs/composition-api';
import ProductsCarousel from '~/components/ProductsCarousel.vue';
import { useExponeaStore } from '~/stores';
import useExponeaConstants from '~/constants/exponea';

export default defineComponent({
  name: 'BestsellerProducts',
  components: {
    ProductsCarousel,
  },
  setup() {
    const exponeaStore = useExponeaStore();
    const { PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID, PRODUCT_PAGE_BESTSELLERS_ID } = useExponeaConstants();
    const bestSellers = computed(() => exponeaStore.getRecommendationById(PRODUCT_PAGE_BESTSELLERS_ID));
    const loading = computed(
      () =>
        exponeaStore.getRecommendationLoadingStateById(PRODUCT_PAGE_BESTSELLERS_ID) ||
        exponeaStore.getRecommendationLoadingStateById(PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID),
    );

    onMounted(async () => {
      // This recommendation updates on every page visit, so force load
      exponeaStore.getRecommendation({
        id: PRODUCT_PAGE_LAST_VIEWED_RECOMMENDATIONS_ID,
        fillWithRandom: true,
        size: 6,
        force: true,
      });
      exponeaStore.getRecommendation({ id: PRODUCT_PAGE_BESTSELLERS_ID, fillWithRandom: true, size: 6 });
    });

    return {
      bestSellers,
      loading,
      PRODUCT_PAGE_BESTSELLERS_ID,
    };
  },
});
