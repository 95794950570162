//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfBanner, SfLink } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import BynderAsset from '../BynderAsset.vue';

export default defineComponent({
  name: 'MainBanner',
  components: {
    SfBanner,
    SfLink,
    BynderAsset,
  },
  props: {
    banners: null,
  },
  computed: {
    firstBanner() {
      return this.banners[0] || false;
    },
    asset() {
      return {
        data: this.firstBanner.content.model.data.bannerImage,
        dataMobile: this.firstBanner.content.model.data.mobileBannerImage,
        size: '100vw',
        ratio: 0.2976,
        ratioMobile: 1.0256,
      };
    },
  },
});
