







import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    url: String,
    imageUrl: String,
    title: String,
  },
  setup() {},
});
