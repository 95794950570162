





















import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SfImage } from '@storefront-ui/vue';

export default defineComponent({
  props: {
    campaign: {
      type: [Object, null],
      default: null,
    },
  },
  components: {
    SfButton,
    SfImage,
  },
  setup(props) {
    function getProperty(content, startTag, endTag) {
      if (!content) return undefined;
      if (content.includes(startTag) && content.includes(endTag)) {
        return content.substring(content.indexOf(startTag) + startTag.length, content.indexOf(endTag));
      } else {
        return undefined;
      }
    }
    const htmlText = props?.campaign?.htmlText;
    const gifPath = getProperty(htmlText, '<gif>', '</gif>');
    const videoPath = getProperty(htmlText, '<mp4>', '</mp4>');
    const url = getProperty(htmlText, '<url>', '</url>');
    const tag = getProperty(htmlText, '<tag>', '</tag>');
    return {
      htmlText,
      gifPath,
      videoPath,
      url,
      tag,
    };
  },
});
