import { render, staticRenderFns } from "./ProductVariantMatrix.vue?vue&type=template&id=b5763f9e&"
import script from "./ProductVariantMatrix.vue?vue&type=script&lang=ts&"
export * from "./ProductVariantMatrix.vue?vue&type=script&lang=ts&"
import style0 from "./ProductVariantMatrix.vue?vue&type=style&index=0&id=b5763f9e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductRelatedColors: require('/builds/my-jewellery/code/frontend/components/Product/ProductRelatedColors.vue').default,ProductVariantEngraving: require('/builds/my-jewellery/code/frontend/components/Product/ProductVariantEngraving.vue').default})
