import { render, staticRenderFns } from "./HeaderNotification.vue?vue&type=template&id=728fb70d&"
import script from "./HeaderNotification.vue?vue&type=script&lang=js&"
export * from "./HeaderNotification.vue?vue&type=script&lang=js&"
import style0 from "./HeaderNotification.vue?vue&type=style&index=0&id=728fb70d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Countdown: require('/builds/my-jewellery/code/frontend/components/Countdown.vue').default})
