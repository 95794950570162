//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfTabs, SfLoader, SfReview, SfRating } from '@storefront-ui/vue';
import { reviewGetters, useReview } from '@vue-storefront/magento';
import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import TabMenu from '~/components/MyAccount/TabMenu.vue';
import { useI18n } from '~/helpers/hooks/usei18n';

export default defineComponent({
  name: 'MyReviews',
  components: {
    SfLoader,
    SfReview,
    SfTabs,
    SfRating,
    TabMenu,
  },
  setup() {
    const { reviews, loading, loadCustomerReviews } = useReview('productReviews-my-reviews');

    const userReviews = computed(() => reviewGetters.getItems(reviews.value));

    const { locale } = useI18n();
    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString(locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    };

    onMounted(async () => {
      await loadCustomerReviews();
    });

    const openTab = ref(1);

    return {
      reviewGetters,
      userReviews,
      loading,
      loadCustomerReviews,
      openTab,
      formatDate,
    };
  },
});
