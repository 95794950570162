







import { defineComponent } from '@nuxtjs/composition-api';
import CartPreview from '~/components/Checkout/CartPreview.vue';

export default defineComponent({
  components: {
    CartPreview,
  },
});
