//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';

export default defineComponent({
  name: 'Video',
  components: {
    LazyHydrate,
  },
  props: {
    component: null,
    videoContent: null,
    page: null,
  },
  computed: {
    isPreview() {
      return this.page?.isPreview();
    },
    title() {
      return this.component?.getProperties()?.title;
    },
    color() {
      return this.video?.color?.selectionValues[0]?.key;
    },
    video() {
      const data = this.document?.getData();

      if (!data) {
        return null;
      }

      return {
        ...data,
      };
    },
    videoSource() {
      const { videoUrls, fullScreenPossible, autoplay } = this.video;
      if (videoUrls?.length) {
        const videoIDs = videoUrls.map((url) => this.parseVideoUrl(url));
        return (
          'https://www.youtube.com/embed/' +
          videoIDs[0] +
          '?playlist=' +
          videoIDs.join(',') +
          '&autoplay=' +
          (autoplay ? 1 : 0) +
          '&fs=' +
          (fullScreenPossible ? 1 : 0) +
          '&color=' +
          this.color +
          '&mute=' +
          (autoplay ? 1 : 0) +
          '&rel=0&loop=0&modestbranding=1'
        );
      }
      return undefined;
    },
    document() {
      if (this.videoContent) {
        return this.videoContent;
      }

      const { document } = this.component.getModels();
      return this.page.getContent(document);
    },
  },
  methods: {
    parseVideoUrl(url) {
      var match = url.match(/(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i);
      return match ? match[1] : undefined;
    },
  },
});
