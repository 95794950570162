//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfMenuItem, SfModal } from '@storefront-ui/vue';
import { useUiState } from '~/stores';
import { storeToRefs } from 'pinia';

export default {
  name: 'HeaderNavigation',
  components: {
    SfMenuItem,
    SfModal,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uiState = useUiState();
    const { toggleMobileMenu } = uiState;
    const { isMobileMenuOpen } = storeToRefs(uiState);
    const categories = ['women', 'men'];

    return {
      categories,
      isMobileMenuOpen,
      toggleMobileMenu,
    };
  },
};
