//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfFooter, SfList, SfImage, SfMenuItem } from '@storefront-ui/vue';
import FooterTop from './MyJewellery/Footer/FooterTop.vue';
import FooterMenu from './MyJewellery/Footer/FooterMenu.vue';
import FooterPaymentProviders from './MyJewellery/Footer/FooterPaymentProviders.vue';

import Logo from '~/assets/images/logo.svg';

export default {
  components: {
    SfFooter,
    SfList,
    SfImage,
    SfMenuItem,
    Logo,
    FooterTop,
    FooterMenu,
    FooterPaymentProviders,
  },
  methods: {
    toggleCollapsed() {
      if (event.target && ['h2', 'h3', 'h4'].includes(event.target.tagName.toLowerCase())) {
        event.target.closest('.footer__collapsed')?.classList.toggle('open');
      }
    },
  },
  mounted() {
    this.$el.addEventListener('click', this.toggleCollapsed);
  },
  destroyed() {
    this.$el.removeEventListener('click', this.toggleCollapsed);
  },
};
