//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';

import CardItem from '~/components/MyJewellery/Banner/CardItem.vue';

export default defineComponent({
  name: 'ImageTextCards',
  components: {
    CardItem,
  },
  props: {
    banners: null,
  },
  methods: {
    getCategory(banner) {
      return banner?.hideCategory ? '' : banner.categoryManually ? banner.categoryManually : banner.category || '';
    },
  },
  computed: {
    columns() {
      return this.banners.length % 2 === 0 ? 2 : 3; // When carrot of two equals zero, show two columns. Otherwise show three.
    },
  },
});
