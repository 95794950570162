























import { computed, defineComponent, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import { SfButton, SfRating, SfSkeleton } from '@storefront-ui/vue';
import { useExponeaStore, useUiState } from '~/stores';
import ProductReviewsSidebar from '~/components/Product/ProductReviewsSidebar.vue';
import type { Product } from '@/types/interfaces/product';
import FeedbackCompany from '~/components/MyJewellery/Footer/FeedbackCompany.vue';
import useExponeaConstants from '~/constants/exponea';

export default defineComponent({
  name: 'ProductRating',
  components: {
    SfButton,
    SfRating,
    SfSkeleton,
    ProductReviewsSidebar,
    FeedbackCompany,
  },
  props: {
    product: {
      type: Object as () => Product,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup({ product }) {
    const context = useContext();
    const locale = context.app?.i18n?.locale;
    const { toggleProductReviewsSidebar } = useUiState();
    const { getSegmentation, getSegmentationById } = useExponeaStore();
    const { REVIEW_OR_COMPANY_FEEDBACK_ID } = useExponeaConstants();

    const totalReviews = computed(() => product?.review_count || 0);
    const averageRating = computed(() => (product?.rating_summary / 100) * 5 || 0);
    const starIconPath =
      'M11.963,0l2.732,8.407h8.84l-7.152,5.2,2.732,8.407-7.152-5.2-7.152,5.2L7.543,13.6.392,8.407h8.84Z';

    const openSidebarAndFocusOnInputForm = () => {
      toggleProductReviewsSidebar();
      document.querySelector('#name')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    };

    const showCompanyFeedback = computed(
      () => locale === 'nl-nl' && getSegmentationById(REVIEW_OR_COMPANY_FEEDBACK_ID) !== 'Review',
    );

    const segmentationLoading = ref(true);

    onMounted(async () => {
      if (locale === 'nl-nl') await getSegmentation({ id: REVIEW_OR_COMPANY_FEEDBACK_ID });
      segmentationLoading.value = false;
    });

    return {
      totalReviews,
      averageRating,
      starIconPath,
      toggleProductReviewsSidebar,
      openSidebarAndFocusOnInputForm,
      segmentationLoading,
      showCompanyFeedback,
    };
  },
});
