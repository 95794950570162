//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfHeading } from '@storefront-ui/vue';
import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import { useCart } from '~/composables';
import CartPreview from '~/components/Checkout/CartPreview.vue';

export default defineComponent({
  name: 'ReviewOrderAndPayment',
  components: {
    SfHeading,
    CartPreview,
    VsfPaymentProvider: () => import('~/components/Checkout/VsfPaymentProvider.vue'),
  },
  setup() {
    const { load } = useCart();

    onMounted(async () => {
      // const validStep = await isPreviousStepValid('overview');
      // if (!validStep) {
      //   await router.push(app.localePath('/checkout'));
      // }

      await load();
    });
  },
});
