










import { defineComponent } from '@nuxtjs/composition-api';
import TimesIcon from '~/assets/icons/times.svg';

export default defineComponent({
  name: 'ProductGalleryLightbox',
  components: {
    TimesIcon,
  },
});
