









































import { SfComponentSelect, SfProperty, SfNotification } from '@storefront-ui/vue';
import { defineComponent, ref, computed, useContext, PropType } from '@nuxtjs/composition-api';
import ChevronDownIcon from '~/assets/icons/chevron-down.svg';
import BellIcon from '~/assets/icons/bell.svg';
import { useUiState } from '~/stores';
import getIsDisabled from '~/helpers/getIsDisabledProductVariantOption';
import { getSelectedProductVariantByUid, getProductOptionsNames } from '~/helpers/product/productGetters';

export default defineComponent({
  name: 'ProductVariantSelect',
  components: {
    SfComponentSelect,
    SfProperty,
    SfNotification,
    ChevronDownIcon,
    BellIcon,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    optionsLength: {
      type: Number,
      required: true,
    },
    productConfiguration: {
      type: Object,
      required: true,
    },
    type: {
      type: String as PropType<'wishlist' | 'cart'>,
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
    pid: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { setSoldOutFormData } = useUiState();
    const { i18n } = useContext();
    const errorNoVariantSelected = ref(false);

    const translatedLabels = {
      size: i18n.t('Size'),
      base_color: i18n.t('Color'),
      birth_year: i18n.t('Birth year'),
      birth_month: i18n.t('Birth month'),
      zodiac_sign: i18n.t('Zodiac sign'),
      initial: i18n.t('Initial'),
      phone_type: i18n.t('Phone model'),
    };

    const translatedLabel = computed(() => translatedLabels[props.option.attribute_code]);

    const selectedVariant = computed(
      () =>
        props.option.values.find((value) => value.uid === props.productConfiguration[props.option.attribute_uid]) || {},
    );

    const selectVariant = (uid) => {
      const value = props.option.values.find((value) => value.uid === uid);
      if (value.out_of_stock === 'OUT_OF_STOCK' && props.type === 'cart') {
        return setSoldOutFormData({
          productId: props.pid,
          isModalVisible: true,
          optionLabel: value.label,
        });
      }
      emit('updateProductConfiguration', { key: props.option.attribute_uid, value: value.uid });
    };

    const getDisabledForOption = (out_of_stock_status) =>
      out_of_stock_status === 'OUT_OF_STOCK' && props.type !== 'wishlist';

    const disabled = computed(() => getIsDisabled(props));

    const selectedProductSku = (uid) =>
      getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku;
    const selectedProductId = (uid) =>
      getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id;

    const showErrorNotification = () => {
      if (disabled.value) {
        errorNoVariantSelected.value = true;
        return setTimeout(() => (errorNoVariantSelected.value = false), 3000);
      }
    };

    const errorNotificationMessage = ref(
      props.optionsLength > 2 ? 'Please select the option above first' : 'Please select a color first',
    );

    return {
      selectedVariant,
      selectVariant,
      getDisabledForOption,
      translatedLabel,
      disabled,
      selectedProductSku,
      selectedProductId,
      showErrorNotification,
      errorNoVariantSelected,
      errorNotificationMessage,
    };
  },
});
