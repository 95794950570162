//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useCustomerStore } from '~/stores';
import { SfHeading, SfInput, SfButton, SfCheckbox } from '@storefront-ui/vue';
import { onSSR } from '@vue-storefront/core';
import { ref, computed, defineComponent, useRouter, useContext, onMounted } from '@nuxtjs/composition-api';
import { required, min, email, confirmed } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { useUiNotification, useUser, useGuestUser, useCart } from '~/composables';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';
import { customerPasswordRegExp, invalidPasswordMsg } from '../../helpers/customer/regex';
import CartPreview from '~/components/Checkout/CartPreview.vue';

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('password', {
  message: invalidPasswordMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  validate: (value) => customerPasswordRegExp.test(value),
});

extend('confirmed', {
  ...confirmed,
  message: "Password doesn't match",
});

export default defineComponent({
  name: 'UserAccount',
  components: {
    SfHeading,
    SfInput,
    SfButton,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    CartPreview,
  },
  setup() {
    const router = useRouter();
    const { app, $recaptcha, $config } = useContext();
    const { cart, load: loadCart } = useCart();

    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);

    const { load, loading: loadingUser, register, login, user, error: errorUser } = useUser();

    const { send: sendNotification } = useUiNotification();

    const isFormSubmitted = ref(false);
    const createUserAccount = ref(false);
    const loading = computed(() => loadingUser.value || loadingGuestUser.value);

    const customerStore = useCustomerStore();
    const isAuthenticated = computed(() => customerStore.isLoggedIn);

    const canMoveForward = computed(() => {
      if (loading.value) return false;
      if (createUserAccount.value) {
        return form.value.password !== '' && form.value.password === form.value.passwordControl;
      }
      return true;
    });
    const hasError = computed(() => errorUser.value.register || errorGuestUser.value.attachToCart);

    const form = ref({
      firstname: '',
      lastname: '',
      email: '',
      telephone: '',
      password: '',
      passwordControl: '',
      is_subscribed_newsletter: false,
      is_subscribed_sms: false,
    });

    const handleFormSubmit = (reset) => async () => {
      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (!isAuthenticated.value) {
        if (isRecaptchaEnabled.value && createUserAccount.value) {
          const recaptchaToken = await $recaptcha.getResponse();
          form.value.recaptchaToken = recaptchaToken;
          form.value.recaptchaInstance = $recaptcha;
        }

        await (!createUserAccount.value
          ? attachToCart({ email: form.value.email, cart })
          : register({ user: form.value, cart }));
      }

      if (!hasError.value) {
        await mergeItem('checkout', { 'user-account': form.value });
        await router.push(`${app.localePath('/checkout/shipping')}`);
        reset();
        isFormSubmitted.value = true;
      } else {
        sendNotification({
          id: Symbol('user_form_error'),
          message: 'Something went wrong during form submission. Please try again later',
          type: 'error',
          icon: 'error',
          persist: false,
          title: 'Error',
        });
      }

      if (isRecaptchaEnabled.value) {
        // reset recaptcha
        $recaptcha.reset();
      }
    };

    onSSR(async () => {
      await load();
    });

    onMounted(async () => {
      const checkout = await getItem('checkout');
      if (checkout && checkout['user-account']) {
        const data = checkout['user-account'];
        form.value.email = data.email;
        form.value.firstname = data.firstname;
        form.value.lastname = data.lastname;
        form.value.telephone = data.telephone;
      }
    });

    const { attachToCart, loading: loadingGuestUser, error: errorGuestUser } = useGuestUser();

    return {
      canMoveForward,
      createUserAccount,
      errorUser,
      form,
      handleFormSubmit,
      isAuthenticated,
      isFormSubmitted,
      loading,
      user,
      isRecaptchaEnabled,
    };
  },
});
