






import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import logger from '~/utilities/logger';
import { useUser } from '~/composables';

export default defineComponent({
  props: {
    component: Object,
  },
  setup(props: any) {
    const { user: customerData, load } = useUser();
    const bookingElement = ref(null);

    const bookingUrl = props.component?.model?.meta?.paramsInfo?.bookingUrl;

    const addCalendlyScript = new Promise((resolve, reject) => {
      const calendlyUrl = 'https://assets.calendly.com/assets/external/widget.js';
      let calendly = document.createElement('script');
      calendly.setAttribute('src', calendlyUrl);
      document.head.appendChild(calendly);

      setTimeout(() => {
        const { Calendly } = window as any;
        if (Calendly) {
          resolve(Calendly);
        } else {
          reject(new Error("Can't load Calendly"));
        }
      }, 300);
    });

    const initializeCalendy = (Calendly: any) => {
      if (!bookingUrl) return;
      try {
        Calendly.initInlineWidget({
          url: bookingUrl,
          parentElement: bookingElement.value,
          prefill: {
            name:
              [customerData.value?.firstname, customerData.value?.middlename, customerData.value?.lastname]
                .filter((v) => v)
                .join(' ') || '',
            email: customerData.value?.email || '',
          },
          utm: {},
        });
      } catch (e) {
        logger.warn(e);
      }
    };

    onMounted(async () => {
      await load();
      addCalendlyScript.then((Calendly) => initializeCalendy(Calendly)).catch((e) => logger.warn(e));
    });

    return { bookingUrl, bookingElement };
  },
});
