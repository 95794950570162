














































import LazyHydrate from 'vue-lazy-hydration';
import { CategoryInterface } from '@vue-storefront/magento-api';
import { computed, defineComponent, onUnmounted, ref, useContext, useRoute, onMounted } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { usePageStore, useProductStore, useUiState } from '~/stores';
import { onSSR } from '@vue-storefront/core';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import useBloomreachPage from '~/composables/useBloomreachPage';

export default defineComponent({
  name: 'Product',
  components: {
    LazyHydrate,
  },
  head() {
    return {
      title: this.product?.meta_title,
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.product?.meta_title,
        },
        {
          hid: 'description',
          name: 'description',
          content: this.product?.meta_description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.product?.meta_description,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.product?.meta_keyword,
        },
      ],
    };
  },
  setup() {
    const { getBloomreachPage } = useBloomreachPage(useContext() as any);
    const { app } = useContext();
    const { hrefLangs, routeData, previousPage } = storeToRefs(usePageStore());
    const { products: storeProducts, productLoading } = storeToRefs(useProductStore());
    const { setSoldOutFormData } = useUiState();

    const sku = computed(() => routeData.value?.sku || '');

    const product = computed(() => {
      return storeProducts.value[sku.value];
    });

    const productCategories = computed(() => product.value?.categories || []);

    const breadcrumbs = computed(() => {
      const breadcrumbCategory: CategoryInterface = [...productCategories.value]
        ?.sort((a, b) => (b.breadcrumbs?.length || 0) - (a.breadcrumbs?.length || 0))
        ?.shift() || { breadcrumbs: [], url_suffix: '', uid: '' };

      // If the previous page is a category, we want to show the category breadcrumbs from where the user came from
      if (previousPage.value.type === 'CATEGORY' && previousPage.value?.category_breadcrumbs?.length) {
        return previousPage.value.category_breadcrumbs;
      } else {
        let breadcrumbs = [];
        if (Array.isArray(breadcrumbCategory.breadcrumbs)) {
          breadcrumbs = breadcrumbCategory.breadcrumbs.map((breadcrumb) => ({
            text: breadcrumb.category_name,
            link: app.localePath(`/${breadcrumb.category_url_path}${breadcrumbCategory.url_suffix || ''}`),
            id: breadcrumb.category_id,
          }));
        }
        breadcrumbs.push({
          text: breadcrumbCategory.name,
          link: app.localePath(`/${breadcrumbCategory.url_path}${breadcrumbCategory.url_suffix || ''}`),
          id: breadcrumbCategory.id,
        });

        return breadcrumbs;
      }
    });

    const productVariantMatrix = ref();
    const productConfiguration = computed(() => productVariantMatrix.value?.productConfiguration);

    const productIsComingSoon = computed(
      () => product.value?.date_online && new Date(product.value?.date_online) > new Date(),
    );

    hrefLangs.value = [];

    const productPid = computed(() => product.value?.pid);
    const loading = computed(() => {
      return productLoading.value[sku.value] || !product.value;
    });

    const { addTags } = useCache();
    const route = useRoute();

    onSSR(() => {
      addTags([{ prefix: CacheTagPrefix.Product, value: route.value?.path.replace(/\/+$/, '') }]);
    });

    const populatePDPContent = () => {
      const breadcrumbsArray = breadcrumbs.value.map((breadcrumb) => breadcrumb.id).reverse();
      const items = [sku.value, ...breadcrumbsArray, 'global'];
      for (const item of items) {
        if (!item) continue;
        getBloomreachPage(item, 'q', 'pdp');
      }
    };

    onMounted(() => {
      if (productIsComingSoon.value) {
        setSoldOutFormData({
          productId: product.value.pid,
          type: 'comingSoon',
        });
      }
      populatePDPContent();
    });

    onUnmounted(() => {
      previousPage.value.category_breadcrumbs = [];
      if (productIsComingSoon.value)
        setSoldOutFormData({ productId: null, optionLabel: null, type: null, isModalVisible: false });
    });

    return {
      sku,
      breadcrumbs,
      product,
      loading,
      productConfiguration,
      productIsComingSoon,
      productPid,
      productVariantMatrix,
    };
  },
});
