//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfBanner, SfLink } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import BynderAsset from '../BynderAsset.vue';

export default defineComponent({
  name: 'HeroArticle',
  components: {
    SfBanner,
    SfLink,
    BynderAsset,
  },
  props: {
    banners: null,
  },
  computed: {
    banner() {
      return this.banners[0] || false;
    },
  },
});
