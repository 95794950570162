





























import { computed, defineComponent, onMounted, nextTick } from '@nuxtjs/composition-api';

import HTMLContent from '~/components/HTMLContent.vue';
import BynderAsset from './BynderAsset.vue';
import { useBloomreachDiscoveryStore } from '~/stores';
import { storeToRefs } from 'pinia';
import { parseBloomreachInternalLinks } from '~/helpers/urlHelpers';

export default defineComponent({
  name: 'CategoryHeader',
  computed: {
    asset() {
      return {
        data: this.model?.image,
        dataMobile: this.model?.mobileBannerImage,
        size: '100vw',
        ratio: 0.3472,
        ratioMobile: 0.48,
      };
    },
    document() {
      return this.page?.getDocument();
    },
    content() {
      const { document } = this.component?.getModels();
      return this.page.getContent(document);
    },
    model() {
      const data = this.content?.getData();

      return (
        (data && {
          ...data,
          image: data.image,
        }) ||
        {}
      );
    },
    isPreview() {
      return this.page?.isPreview();
    },
    headerTitle() {
      return this.document.getData()?.pageTitle || this.document.getData().displayName;
    },
    headerText() {
      return parseBloomreachInternalLinks(this.model?.richTextDescription?.value || '');
    },
  },
  components: {
    HTMLContent,
    BynderAsset,
  },
  props: {
    component: null,
    page: null,
  },
  setup() {
    const bloomreachDiscoveryStore = useBloomreachDiscoveryStore();
    const { pagination } = storeToRefs(bloomreachDiscoveryStore);
    const totalProducts = computed(() => pagination.value?.totalProducts || 0);

    onMounted(() => {
      nextTick(() => {
        document.querySelector('#category').classList.add('category-has-header');
      });
    });

    return {
      totalProducts,
    };
  },
});
