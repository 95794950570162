
























































import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import { SfPagination, SfButton } from '@storefront-ui/vue';
import { storeToRefs } from 'pinia';
import useBloomreachDiscoveryStore, { doSearchOperations } from '~/stores/useBloomreachDiscoveryStore';

export default defineComponent({
  name: 'Pagination',
  components: {
    SfPagination,
    SfButton,
  },
  setup() {
    const bloomreachDiscoveryStore = useBloomreachDiscoveryStore();
    const { currentPage, pagination, prependedPage, products } = storeToRefs(bloomreachDiscoveryStore);
    const { applyFilters, doSearch } = bloomreachDiscoveryStore;

    const loading = ref(false);

    const showTopPagination = computed(() => pagination.value?.currentProducts && pagination.value?.prependedPage > 1);

    const canGoNext = computed(
      () => pagination.value.currentProducts && pagination.value.totalPages > currentPage.value,
    );

    const showMore = async () => {
      loading.value = true;
      currentPage.value += 1;

      await doSearch({});
      applyFilters(doSearchOperations.APPEND);
      loading.value = false;
    };

    const showPrevious = async () => {
      loading.value = true;
      const firstProductSku = products.value[0].sku.replace(' ', '_');

      const distanceFromTopOfscreen = document
        .querySelector(`[data-sku=${firstProductSku}]`)
        .getBoundingClientRect().top;

      prependedPage.value -= 1;

      await doSearch({ page: prependedPage.value });
      await applyFilters(doSearchOperations.PREPEND);
      window.scrollTo({
        top:
          document.querySelector(`[data-sku=${firstProductSku}]`).getBoundingClientRect().top +
          window.scrollY -
          distanceFromTopOfscreen,
        //@ts-ignore
        behavior: 'instant',
      });
      loading.value = false;
    };

    return {
      pagination,
      loading,
      showTopPagination,
      canGoNext,
      showMore,
      showPrevious,
    };
  },
});
