










import { defineComponent, computed, onMounted, watch, ref } from '@nuxtjs/composition-api';
import { useExponeaStore } from '~/stores';
import useExponeaConstants from '~/constants/exponea';
import logger from '~/utilities/logger';
import { Product } from '~/types/interfaces/product';

export default defineComponent({
  name: 'RecommendedProducts',
  props: {
    sku: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { PRODUCT_PAGE_RECOMMENDATIONS_CATALOG, PRODUCT_PAGE_RECOMMENDATIONS_ID } = useExponeaConstants();
    const exponeaStore = useExponeaStore();
    const products = ref([]);
    const isLoadingRecommendations = ref(true);

    const getRecommendation = async () => {
      isLoadingRecommendations.value = true;
      const exponeaProduct = await exponeaStore.getProduct({
        catalogId: PRODUCT_PAGE_RECOMMENDATIONS_CATALOG,
        sku: props?.sku,
      });

      if (!exponeaProduct) {
        isLoadingRecommendations.value = false;
        return logger.error(`Product with SKU ${props.sku} not found in Exponea`);
      }

      await exponeaStore.getRecommendation({
        id: PRODUCT_PAGE_RECOMMENDATIONS_ID,
        fillWithRandom: true,
        size: 8,
        force: true,
        productId: exponeaProduct.product_id,
      });

      products.value = exponeaStore
        .getRecommendationById(PRODUCT_PAGE_RECOMMENDATIONS_ID)
        ?.filter((product: Product) => product.sku !== props.sku);

      isLoadingRecommendations.value = false;
    };

    onMounted(async () => {
      if (props?.sku) getRecommendation();
    });

    return {
      PRODUCT_PAGE_RECOMMENDATIONS_ID,
      products,
      isLoadingRecommendations,
    };
  },
});
