


























































































































import { ref, reactive, defineComponent, useContext, useRouter } from '@nuxtjs/composition-api';
import { SfModal, SfInput, SfButton, SfCheckbox, SfLoader, SfBar, SfNotification } from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/helpers/customer/regex';
import { useUser } from '~/composables';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

extend('confirmed', {
  ...confirmed,
  message: "Password doesn't match",
});

export default defineComponent({
  name: 'Register',
  components: {
    SfModal,
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    SfBar,
    SfNotification,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const form = ref({
      firstname: '',
      lastname: '',
      email: '',
      telephone: '',
      password: '',
      passwordControl: '',
      is_subscribed_newsletter: false,
      is_subscribed_sms: false,
      recaptchaInstance: null,
    });
    const isSubscribed = ref(false);

    const error = reactive({
      register: null,
    });

    const { app, $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);

    const router = useRouter();
    const { register, loading, error: userError } = useUser();

    const resetErrorValues = () => {
      error.register = null;
    };

    const handleRegister = () => async () => {
      resetErrorValues();

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.getResponse();
        form.value.recaptchaInstance = $recaptcha;

        await register({
          user: {
            ...form.value,
            is_subscribed: isSubscribed.value,
            recaptchaToken,
          },
        });
      } else {
        await register({
          user: {
            ...form.value,
            is_subscribed: isSubscribed.value,
          },
        });
      }

      const hasUserErrors = userError.value.register || userError.value.login;
      if (hasUserErrors) {
        error.register = userError.value.register?.message;
        return;
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.reset();
      }
    };

    const redirectToLogin = async () => {
      await router.push(app.localePath('/customer/account/login'));
    };

    return {
      error,
      form,
      loading,
      isSubscribed,
      isRecaptchaEnabled,
      redirectToLogin,
      handleRegister,
    };
  },
});
