//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfCheckbox, SfAddressPicker, SfButton, SfLoader } from '@storefront-ui/vue';
import { useUserShipping, userShippingGetters, useAddresses, userAddressesGetters } from '@vue-storefront/magento';
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import AddressForm from '~/components/MyAccount/AddressForm.vue';
import { useUser } from '~/composables';

export default defineComponent({
  name: 'UserShippingAddresses',
  components: {
    SfCheckbox,
    SfAddressPicker,
    SfButton,
    SfLoader,
    UserAddressDetails,
    AddressForm,
  },
  props: {
    currentAddressId: {
      type: String,
      default: '',
    },
  },
  emits: ['setCurrentAddress'],
  setup(_, { emit }) {
    const { shipping: userShipping } = useUserShipping();
    const { isAuthenticated } = useUser();
    const { addresses } = useAddresses();

    // Variables and computed
    const selectedAddress = ref();
    const isNewAddress = ref(true);
    const hasSavedShippingAddress = computed(() => {
      if (!isAuthenticated.value || !userShipping.value) {
        return false;
      }
      const addresses = userShippingGetters.getAddresses(userShipping.value);
      return Boolean(addresses?.length);
    });
    const shippingAddresses = computed(() => userAddressesGetters.getAddresses(addresses.value));

    // Used only in template
    const showAddNewAddress = ref(false);
    const showAddNewAddressButton = computed(() => hasSavedShippingAddress.value && !showAddNewAddress.value);

    // Methods
    const setAddress = (addressId) => {
      const selectedAddress = userShippingGetters.getAddresses(userShipping.value, {
        id: Number.parseInt(addressId, 10),
      });

      if (!selectedAddress) {
        return;
      }

      emit('setCurrentAddress', selectedAddress[0]);
    };

    return {
      setAddress,
      shippingAddresses,
      userShippingGetters,
      showAddNewAddressButton,
      isNewAddress,
      hasSavedShippingAddress,
      showAddNewAddress,
      selectedAddress,
    };
  },
});
