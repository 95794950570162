































































import { ref, reactive, defineComponent, useContext, useRouter } from '@nuxtjs/composition-api';
import { SfModal, SfInput, SfButton, SfCheckbox, SfLoader, SfBar, SfNotification } from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { useForgotPassword } from '@vue-storefront/magento';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/helpers/customer/regex';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'Login',
  components: {
    SfModal,
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    SfBar,
    SfNotification,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const form = ref({
      email: null,
      recaptchaInstance: null,
    });
    const isSubscribed = ref(false);
    const isLogin = ref(true);
    const isForgotten = ref(false);
    const isThankYouAfterForgotten = ref(false);
    const userEmail = ref('');
    const error = reactive({
      login: null,
    });

    const { app, $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);

    const router = useRouter();
    const { request, error: forgotPasswordError, loading } = useForgotPassword();

    const handleForgotten = async () => {
      userEmail.value = form.value.email;

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.getResponse();

        // @ts-ignore
        await request({ email: userEmail.value, recaptchaToken });
      } else {
        await request({ email: userEmail.value });
      }

      if (!forgotPasswordError.value.request) {
        isThankYouAfterForgotten.value = true;
        isForgotten.value = false;
      }

      if (isRecaptchaEnabled.value) {
        // reset recaptcha
        $recaptcha.reset();
      }
    };

    const redirectToLogin = async () => {
      await router.push(app.localePath('/customer/account/login'));
    };

    return {
      error,
      form,
      loading,
      handleForgotten,
      isLogin,
      isSubscribed,
      isThankYouAfterForgotten,
      isRecaptchaEnabled,
      redirectToLogin,
    };
  },
});
