//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SfNotification, SfIcon, SfLink } from '@storefront-ui/vue';
import { useUiNotification } from '~/composables';
import { useUiState } from '~/stores';
import SvgImage from '~/components/General/SvgImage.vue';
import TimesIcon from '~/assets/icons/times-light.svg';
import ShoppingBagIcon from '~/assets/icons/shopping-bag.svg';
import DangerInfo from '~/assets/icons/danger-info.svg';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'Notification',
  components: {
    SfButton,
    SfNotification,
    SfIcon,
    SfLink,
    ShoppingBagIcon,
    SvgImage,
    TimesIcon,
    DangerInfo,
  },
  setup() {
    const { isTopHeaderVisible, isHeaderNotificationVisible } = storeToRefs(useUiState());
    const { notifications } = useUiNotification();

    return {
      notifications,
      isTopHeaderVisible,
      isHeaderNotificationVisible,
    };
  },
});
