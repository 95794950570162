








import { defineComponent, computed } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { SfLoader } from '@storefront-ui/vue';
import { useContent } from '~/composables';
import { useBreadcrumbsStore, useContentStore, usePageStore } from '~/stores';
import HTMLContent from '~/components/HTMLContent.vue';
import { onSSR } from '@vue-storefront/core';
import { storeToRefs } from 'pinia';
import Vue from 'vue';
import useBloomreachPage, { CategoryId, Sku } from '~/composables/useBloomreachPage';
import { PageModel } from '@bloomreach/spa-sdk';

interface BrxPDPTabs {
  type: string;
  data: {
    name: string;
    displayName: string;
    overrideShipping: boolean;
    overrideShippingText: {
      value: string;
    };
    productIDs: string;
    categoryIDs: string;
    localeString: string;
    contentType: string;
    id: string;
  };
}

export default defineComponent({
  components: {
    HTMLContent,
    LazyHydrate,
    SfLoader,
  },
  props: {
    sku: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const { bloomreachPages } = storeToRefs(usePageStore());
    const { loadBlocks, loading } = useContent();
    const contentStore = useContentStore();
    const { content } = storeToRefs(contentStore);
    const breadcrumbsStore = useBreadcrumbsStore();
    const { breadcrumbs } = storeToRefs(breadcrumbsStore);

    function getPDPTabsDocuments(page: PageModel): BrxPDPTabs[] {
      if (!page?.page) return [];
      const pageObjects: BrxPDPTabs[] = Object.values(page.page);
      return pageObjects.filter((object) => object?.data?.contentType === 'brxsaas:mjPDPTabs');
    }

    function getBreadcrums() {
      const breadcrumbsArray = breadcrumbs.value.map((breadcrumb) => breadcrumb.id).reverse();
      return [props.sku, ...breadcrumbsArray, 'global'];
    }

    const deliveryTextOverride = computed(() => {
      for (const item of getBreadcrums()) {
        if (!item) continue;
        const storeKey = item + 'q';
        const page: PageModel = bloomreachPages.value[storeKey];
        const documents = getPDPTabsDocuments(page);
        //Reduce array to the first document overriding shipping text
        const document = documents.find(
          (document) => document.data?.overrideShipping && document.data?.overrideShippingText?.value,
        );
        if (document) {
          return document?.data?.overrideShippingText?.value;
        }
      }
      return undefined;
    });

    const deliveryText = computed(() => {
      return deliveryTextOverride.value || content.value['delivery_info'];
    });

    onSSR(async () => {
      if (deliveryText.value) return;
      const blocks = await loadBlocks({ identifiers: ['delivery_info'] });
      const deliveryInfoData = blocks?.find((block) => block.identifier === 'delivery_info')?.content ?? '';
      Vue.set(content.value, 'delivery_info', deliveryInfoData);
    });

    return {
      content,
      loading,
      deliveryText,
    };
  },
});
