







import { defineComponent } from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';

import Checkmark from '~/assets/icons/checkmark.svg';

export default defineComponent({
  name: 'TopNavUspItems',
  components: {
    Checkmark,
    SfLoader,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
});
