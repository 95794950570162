//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfHeading, SfButton, SfCallToAction } from '@storefront-ui/vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ExternalCheckoutThankYou',
  components: {
    SfHeading,
    SfButton,
    SfCallToAction,
  },
  setup(props, context) {
    context.emit('changeStep', 4);

    const companyDetails = ref({
      name: 'Divante Headquarter',
      street: 'St. Dmowskiego 17, 53-534',
      city: 'Wroclaw, Poland',
      email: 'demo@vuestorefront.io',
    });
    const orderNumber = ref('80932031-030-00');

    return {
      companyDetails,
      orderNumber,
    };
  },
});
