//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from '@nuxtjs/composition-api';
import ChevronLeftIcon from '~/static/icons/chevron-left-alt.svg';
import TopNavMenuItem from './TopNavMenuItem.vue';

export default defineComponent({
  name: 'TopNavNestedMenu',
  components: {
    ChevronLeftIcon,
    TopNavMenuItem: () => import('./TopNavMenuItem.vue'),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    menuId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const marginLeft = ref(null);
    const minHeight = ref('100%');
    const isDesktop = ref(false);
    const isExpanded = ref(false);
    const submenu = ref();

    const showBackLink = computed(() => props.level > (isDesktop.value ? 2 : 1));

    const menuClassName = computed(() => {
      return [`top-nav__submenu--level${props.level}`, props.isOpen && 'top-nav__submenu--expanded'];
    });

    const setMinHeight = (height) => {
      minHeight.value = height;
    };

    const onResize = () => {
      isDesktop.value = window.outerWidth >= 1024;
      marginLeft.value = null;

      if (isDesktop.value) {
        const parentEl = submenu.value.parentElement;
        const boundingRect = parentEl.getBoundingClientRect();
        marginLeft.value =
          boundingRect.left + boundingRect.width / 2 < 250
            ? 504 / 2 - boundingRect.left + boundingRect.width / 2 - boundingRect.width + 'px'
            : null;
      }
    };

    const formatLink = (name) => {
      // name + label e.g.: "rokjes {deal}"
      return {
        name: name.replace(/ *\{[^)]*\} */g, ''),
        label: name.match(/{(\w+)}/, '$1')?.[1],
      };
    };

    const collapse = (event) => {
      event.preventDefault();
      emit('collapse', true);
      emit('setMinHeight', '100%');
    };

    const onBlur = () => {
      setMinHeight('100%');
      emit('collapse', true);
      emit('setMinHeight', '100%');
    };

    const openened = () => {
      isExpanded.value = true;
      submenu.value.scrollTop = 0;
    };

    onMounted(() => {
      onResize();
      window.addEventListener('resize', onResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);
    });

    return {
      menuClassName,
      marginLeft,
      minHeight,
      isDesktop,
      isExpanded,
      submenu,
      showBackLink,
      setMinHeight,
      onResize,
      onBlur,
      formatLink,
      collapse,
      openened,
    };
  },
});
