






































import { defineComponent, ref, onMounted, watch, computed, onUnmounted, nextTick } from '@nuxtjs/composition-api';
import { SfArrow, SfButton, SfLink, SfSkeleton } from '@storefront-ui/vue';
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
Swiper.use([Navigation]);

import ArrowLeftIcon from '~/assets/icons/arrow-left.svg';
import ArrowRightIcon from '~/assets/icons/arrow-right.svg';
import { usePageStore, useBloomreachDiscoveryStore } from '~/stores';

export default defineComponent({
  name: 'CategoryListHorizontal',
  components: {
    SfArrow,
    SfButton,
    SfLink,
    SfSkeleton,
    ArrowLeftIcon,
    ArrowRightIcon,
  },
  props: {
    categories: {
      type: Array,
      default: [],
    },
    activeCategoryId: {
      type: [String, Number],
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const swiper = ref(null);
    const swiperContainer = ref(null);
    const swiperWrapper = ref(null);
    const pageStore = usePageStore();
    const bloomreachDiscoveryStore = useBloomreachDiscoveryStore();

    const showNavigation = ref(true);
    const observer = ref(null);

    const checkForNavigation = () => {
      let previousNodesLength = 0;
      observer.value = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const nodesLength = swiperWrapper.value?.childNodes.length;
            if (nodesLength === previousNodesLength) return;

            let categoriesWidth = 0;
            swiperWrapper.value?.childNodes.forEach(
              (node: Element) => (categoriesWidth += node.getBoundingClientRect().width),
            );

            showNavigation.value = categoriesWidth > swiper.value?.size;
            previousNodesLength = nodesLength;
          }
        }
      });

      observer.value.observe(swiperWrapper.value, { childList: true });
    };

    const initializeSwiper = () => {
      swiper.value = new Swiper(swiperContainer.value, {
        navigation: {
          prevEl: '.sf-button--left',
          nextEl: '.sf-button--right',
        },
        speed: 400,
        slidesPerGroup: 2,
        slidesPerView: 'auto',
        threshold: 2,
        spaceBetween: 0,
        roundLengths: true,
        slidesOffsetBefore: 16,
        slidesOffsetAfter: 16,
        breakpoints: {
          1024: {
            slidesOffsetBefore: 4,
            slidesOffsetAfter: 4,
          },
        },
      });
    };

    const quickFilters = computed(() => {
      let filters: any[] = props.categories;
      if (pageStore.quickFilters.length) {
        filters = filters.filter((filter) => pageStore.quickFilters?.some((pageFilter) => pageFilter.id == filter.id));
      }
      return filters;
    });

    const isLoaded = computed(() => !props.loading || quickFilters.value?.length > 0);

    onMounted(() => {
      initializeSwiper();
      checkForNavigation();
    });

    onUnmounted(() => {
      swiper.value.destroy();
      observer.value.disconnect();
    });

    return {
      showNavigation,
      swiperContainer,
      swiperWrapper,
      quickFilters,
      isLoaded,
    };
  },
});
