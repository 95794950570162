var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{staticClass:"notifications",class:{
    'notifications--visible': _vm.notifications.length,
    'notifications--top-header-visible': _vm.isTopHeaderVisible,
    'notifications--header-notification-visible': _vm.isHeaderNotificationVisible,
  },attrs:{"tag":"div","name":"slide-fade"}},_vm._l((_vm.notifications),function(notification){return _c('SfNotification',{key:notification.id,attrs:{"title":_vm.$tc(notification.title),"message":_vm.$tc(notification.message),"action":notification.action && notification.action.text,"type":notification.type,"visible":""},on:{"click:close":notification.dismiss,"click:action":function($event){notification.action && notification.action.onClick()}},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
  var icon = ref.icon;
return [(icon === 'added_to_cart')?_c('ShoppingBagIcon',{staticClass:"sf-notification__icon",attrs:{"width":"24","height":"24"}}):(icon === 'info_shield')?_c('DangerInfo',{staticClass:"sf-notification__icon",attrs:{"width":"24","height":"24"}}):_c('SfIcon',{staticClass:"sf-notification__icon",class:{ 'display-none': !icon },attrs:{"icon":icon,"size":"lg","color":"white"}})]}},{key:"title",fn:function(ref){
  var title = ref.title;
return [_c('div',{staticClass:"sf-notification__title",class:{ 'display-none': !title }},[_vm._v(_vm._s(title))])]}},{key:"message",fn:function(ref){
  var message = ref.message;
return [(!notification.link)?_c('span',{staticClass:"sf-notification__message",class:{ 'display-none': !message }},[_vm._v("\n        "+_vm._s(message)+"\n      ")]):_c('i18n',{staticClass:"sf-notification__message",class:{ 'display-none': !message },attrs:{"path":notification.message,"tag":"span"}},[_c('SfLink',{attrs:{"link":notification.link}},[_vm._v(_vm._s(notification.linkText))])],1)]}},{key:"close",fn:function(){return [_c('SfButton',{staticClass:"sf-button--pure sf-notification__close sf-button",attrs:{"aria-label":_vm.$t('Close notification')},on:{"click":notification.dismiss}},[_c('TimesIcon')],1)]},proxy:true}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }