
















import { computed, defineComponent, watch } from '@nuxtjs/composition-api';
import { SfModal, SfLoader } from '@storefront-ui/vue';
import { storeToRefs } from 'pinia';
import { useProductStore, wishlistStore, useCartStore } from '~/stores';
import WishlistVariant from '~/components/Wishlist/WishlistVariant.vue';
import CartVariant from '~/components/Cart/CartVariant.vue';

export default defineComponent({
  name: 'ProductVariantSelectionModal',
  components: { SfModal, SfLoader, WishlistVariant, CartVariant },
  setup() {
    const { showWishlistModal } = storeToRefs(wishlistStore());
    const { showAtcModal } = storeToRefs(useCartStore());
    const { products, activeProductSku } = storeToRefs(useProductStore());

    const product = computed(() => {
      return products.value[activeProductSku.value];
    });

    watch(
      () => showWishlistModal.value,
      () => {
        showWishlistModal.value
          ? document.body.classList.add('no-scroll')
          : document.body.classList.remove('no-scroll');
      },
    );

    const close = () => {
      showWishlistModal.value = false;
      showAtcModal.value = false;
      activeProductSku.value = null;
    };

    return {
      product,
      showWishlistModal,
      showAtcModal,
      close,
    };
  },
});
