











































import { productGetters } from '@vue-storefront/magento';
import { SfProperty, SfColor, SfBadge } from '@storefront-ui/vue';
import CheckmarkIcon from '~/assets/icons/checkmark-alt.svg';
import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import { useUiState } from '~/stores';
import BellIcon from '~/assets/icons/bell.svg';
import { getContrast } from '~/helpers/colorHelpers';
import getIsDisabled from '~/helpers/getIsDisabledProductVariantOption';
import { storeToRefs } from 'pinia';
import { getSelectedProductVariantByUid } from '~/helpers/product/productGetters';

export default defineComponent({
  name: 'ProductVariantColor',
  components: {
    SfProperty,
    SfColor,
    SfBadge,
    CheckmarkIcon,
    BellIcon,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    productConfiguration: {
      type: Object,
      required: true,
    },
    type: {
      type: String as PropType<'wishlist' | 'cart'>,
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
    pid: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const uiState = useUiState();
    const { setSoldOutFormData } = uiState;
    const { currentSlideIndex } = storeToRefs(uiState);

    const selectedVariant = computed(
      () =>
        props.option.values.find((value) => value.uid === props.productConfiguration[props.option.attribute_uid]) || {},
    );

    const selectVariant = (value) => {
      if (value.out_of_stock === 'OUT_OF_STOCK' && props.type === 'cart') {
        return setSoldOutFormData({
          productId: props.pid,
          isModalVisible: true,
          optionLabel: value.label,
        });
      }
      emit('updateProductConfiguration', { key: props.option.attribute_uid, value: value.uid });
      currentSlideIndex.value = 0;
    };

    const getDisabledForOption = (out_of_stock_status) =>
      out_of_stock_status === 'OUT_OF_STOCK' && props.type !== 'wishlist';

    const disabled = computed(() => getIsDisabled(props));

    const selectedProductSku = (uid) =>
      getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku;
    const selectedProductId = (uid) =>
      getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id;

    return {
      selectedVariant,
      productGetters,
      selectVariant,
      getContrast,
      getDisabledForOption,
      disabled,
      selectedProductSku,
      selectedProductId,
    };
  },
});
