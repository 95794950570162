//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfBanner, SfBannerGrid, SfLink, SfHeading } from '@storefront-ui/vue';
import { defineComponent, useContext } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';

import { stripTags } from '@/helpers/stringHelpers';
import getBynderImageUrl from '@/helpers/bynderImage';
import getSpacingClassName from '@/helpers/spacing';

import GridBanner from './Banner/GridBanner.vue';
import HeroArticle from './Banner/HeroArticle.vue';
import HighlightedArticle from './Banner/HighlightedArticle.vue';
import ImageTextBanner from './Banner/ImageTextBanner.vue';
import ImageTextCards from './Banner/ImageTextCards.vue';
import LatestArticles from './Banner/LatestArticles.vue';
import MainBanner from './Banner/MainBanner.vue';
import PromotionBanner from './Banner/PromotionBanner.vue';
import RecommendedArticles from './Banner/RecommendedArticles.vue';

export default defineComponent({
  name: 'Banner',
  components: {
    LazyHydrate,
    SfBanner,
    SfBannerGrid,
    GridBanner,
    HeroArticle,
    HighlightedArticle,
    ImageTextBanner,
    MainBanner,
    PromotionBanner,
    RecommendedArticles,
    SfHeading,
    SfLink,
  },
  props: {
    component: null,
    page: null,
  },
  computed: {
    isPreview() {
      return this.page?.isPreview();
    },
    params() {
      return this.component.model.meta.paramsInfo;
    },
    showHeader() {
      return ['image-and-text'].includes(this.bannerType);
    },
    marginBottom() {
      return getSpacingClassName(this.params.marginBottom || '');
    },
    styleAttr() {
      if (this.marginBottom) {
        return {
          '--mj-component-margin-bottom': `var(--margin-bottom-${this.marginBottom})`,
        };
      }
      return null;
    },
    banners() {
      // Get the banners, remove empties, convert to readable object
      const banners = Object.values(
        Object.entries(this.component.getModels())
          .filter(([k, v]) => v !== null)
          .sort(([k1], [k2]) => k1.localeCompare(k2))
          .map((v) => this.getBannerObject(v[1])),
      );
      return banners;
    },
    bannerType() {
      const { bannerType } = this.params;

      // convert so we can also use it for classnames
      return bannerType.toLowerCase().replaceAll(' ', '-');
    },
    currentComponent() {
      switch (this.bannerType) {
        case 'grid-banner':
          return GridBanner;
        case 'hero-article':
          return HeroArticle;
        case 'highlighted-article':
          return HighlightedArticle;
        case 'image-and-text-left':
        case 'image-and-text-right':
          return ImageTextBanner;
        case 'image-and-text':
          return ImageTextCards;
        case 'latest-articles':
          return LatestArticles;
        case 'promotion-banner':
          return PromotionBanner;
        case 'recommended-no-background':
        case 'recommended-pink':
        case 'recommended-sand':
          return RecommendedArticles;
        default:
          return MainBanner;
      }
    },
  },
  methods: {
    getBannerObject(ref) {
      let articleData = {};
      const content = this.page.getContent(ref);
      const data = content?.getData();

      const getCategory = (data) => {
        const values = data?.category?.selectionValues;
        return values ? values[0]?.label : false;
      };

      const getDescription = (data) => {
        return stripTags(data?.bannerDescription?.value || '');
      };

      const getHtmlDescription = (data) => {
        return data?.bannerDescription?.value || '';
      };

      if (!data) {
        return null;
      }

      if (data.article) {
        const article = this.page.getContent(data.article)?.getData();

        articleData = {
          ...article,
          content,
          category: getCategory(article),
          bannerTitle: article.title,
          bannerDescription: article.description || '',
          bannerDescriptionHtml: article.description || '',
          bannerImage: getBynderImageUrl(article.image),
          asset: article.image,
          ctaUrl: this.localePath(
            this.page
              .getContent(data.article)
              ?.getUrl()
              ?.replace(this.$nuxt.context.$brxmEndpointResolver.resolvePagesEndPoint(), ''),
          ),
        };
      }
      const { localePath } = useContext();
      const ctaLink = data.ctaLink && this.page.getContent(data.ctaLink)?.getUrl();
      const localeCtaLink = ctaLink && localePath(ctaLink);
      const newData = Object.fromEntries(
        Object.entries({
          ...data,
          content,
          category: getCategory(data),
          bannerDescription: getDescription(data),
          bannerDescriptionHtml: getHtmlDescription(data),
          bannerImage: getBynderImageUrl(data.bannerImage),
          asset: data.bannerImage,
          ctaLink: localeCtaLink,
        }).filter(([_, v]) => v !== null && v !== ''),
      );

      return { ...articleData, ...newData };
    },
  },
});
