import { render, staticRenderFns } from "./ProductVariantSize.vue?vue&type=template&id=42412c08&scoped=true&"
import script from "./ProductVariantSize.vue?vue&type=script&lang=ts&"
export * from "./ProductVariantSize.vue?vue&type=script&lang=ts&"
import style0 from "./ProductVariantSize.vue?vue&type=style&index=0&id=42412c08&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42412c08",
  null
  
)

export default component.exports