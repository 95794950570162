//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfButton, SfNotification } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';

import TimesIcon from '~/assets/icons/times-light.svg';

export default defineComponent({
  name: 'InlineNotification',
  components: {
    SfButton,
    SfNotification,
    TimesIcon,
  },
  props: {
    component: null,
    page: null,
  },
  computed: {
    document() {
      return this.page?.getDocument();
    },
    content() {
      const { document } = this.component?.getModels();
      return this.page.getContent(document);
    },
    model() {
      return this.content?.getData();
    },
    isPreview() {
      return this.page?.isPreview();
    },
    title() {
      return this.model?.title;
    },
    message() {
      return this.model?.message;
    },
    type() {
      return this.model?.type?.selectionValues[0]?.key;
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    checkIfVisible() {
      const itemFromStorage = sessionStorage.getItem('closedInlineNotifications');
      const currentClosedInlineNotifications = itemFromStorage ? JSON.parse(itemFromStorage) : [];
      const isClosed = currentClosedInlineNotifications.includes(this.model?.id);
      this.isVisible = !isClosed;
    },
    close() {
      const itemFromStorage = sessionStorage.getItem('closedInlineNotifications');
      const currentClosedInlineNotifications = itemFromStorage ? JSON.parse(itemFromStorage) : [];
      const closedInlineNotifications = [...currentClosedInlineNotifications, this.model?.id];
      sessionStorage.setItem('closedInlineNotifications', JSON.stringify(closedInlineNotifications));
      this.isVisible = false;
    },
  },
  mounted() {
    this.checkIfVisible();
  },
});
