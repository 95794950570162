//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfTabs, SfButton } from '@storefront-ui/vue';
import { userAddressesGetters, useAddresses } from '@vue-storefront/magento';
import { computed, defineComponent, useRouter, useRoute, useContext, ref } from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import AddressForm from '~/components/MyAccount/AddressForm.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import TabMenu from '~/components/MyAccount/TabMenu.vue';

export default defineComponent({
  name: 'AddressesDetails',
  components: {
    SfTabs,
    SfButton,
    AddressForm,
    SvgImage,
    UserAddressDetails,
    TabMenu,
  },
  setup() {
    const { addresses, load, remove, update, save } = useAddresses();

    const userAddresses = computed(() => userAddressesGetters.getAddresses(addresses.value));
    const router = useRouter();
    const route = useRoute();
    const { app } = useContext();
    const activeAddress = computed(() =>
      userAddresses.value.filter((address) => String(address?.id) === route.value.query.id).pop(),
    );

    const getTranslatedUrlAddress = (title) => app.i18n.t(`${title}`).toLowerCase().replace(' ', '-');
    const isNewAddress = computed(() => !activeAddress.value);
    const editingAddress = computed(() => !!route.value.query.id);
    const changeAddress = async (address) => {
      const addressId = address?.id || 'new';
      await router.push(
        `${app.localePath({
          path: `/customer/account/${getTranslatedUrlAddress('Addresses details')}`,
          query: { id: addressId },
        })}`,
      );
    };

    const removeAddress = async (address) => {
      const isDefault = userAddressesGetters.isDefault(address);
      if (!isDefault) {
        await remove({ address });
        await load({});
      }
    };

    const saveAddress = async ({ form, onComplete, onError }) => {
      try {
        const actionMethod = isNewAddress.value ? save : update;
        const data = await actionMethod({ address: form });
        await onComplete(data);
        await router.push(app.localePath(`/customer/account/${getTranslatedUrlAddress('Addresses details')}`));
      } catch (error) {
        onError(error);
      }
    };

    const openTab = ref(1);
    const title = computed(() => {
      if (editingAddress) {
        return isNewAddress ? app.i18n.t('Add the address') : app.i18n.t('Update the address');
      }
      return app.i18n.t('Addresses details');
    });

    onSSR(async () => {
      await load({});
    });

    return {
      changeAddress,
      update,
      removeAddress,
      saveAddress,
      userAddressesGetters,
      userAddresses,
      editingAddress,
      activeAddress,
      isNewAddress,
      openTab,
      title,
    };
  },
});
