






import { defineComponent } from '@nuxtjs/composition-api';
import TimesIcon from '~/assets/icons/times.svg';

export default defineComponent({
  name: 'SidebarCloseButton',
  components: {
    TimesIcon,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
