












































import { Document, Pagination as BrPagination, Page, Component } from '@bloomreach/spa-sdk';
import { computed, defineComponent, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import ChevronRightIcon from '~/static/icons/chevron-right-alt.svg';

import CardItem from '~/components/MyJewellery/Banner/CardItem.vue';
import HTMLContent from '~/components/HTMLContent.vue';

interface BrProps {
  page: Page;
  component: Component;
}

export interface ShopInShopDocument {
  storeName: string;
  streetName: string;
  houseNumber: string;
  addition: string;
  postalCode: string;
  city: string;
  country: string;
  storeUrl: string;
}

export default defineComponent({
  name: 'ShopInShop',
  props: {
    component: null,
    page: null,
  },
  components: {
    CardItem,
    HTMLContent,
    ChevronRightIcon,
  },
  setup(props) {
    const { app } = useContext();
    let limitedShopsNumber = ref(6);
    onMounted(() => {
      if (app.$device.isDesktop) {
        limitedShopsNumber.value = 12;
      }
    });
    const showButton = computed(() => {
      return limitedShopsNumber.value < shops.length;
    });
    const limitedShops = computed(() => (limitStores.value ? shops.slice(0, limitedShopsNumber.value) : shops));
    const buttonLabel = computed(() => (limitStores.value ? 'Show more shop-in-shops' : 'Show less shop-in-shops'));
    const title = computed(() => component?.getProperties()?.title);
    const content = computed(() => component?.getProperties()?.description);
    const { page, component } = <BrProps>props;
    const isPreview = page.isPreview();
    const { pagination: paginationRef } = component.getModels();
    const pagination = paginationRef && page.getContent<BrPagination>(paginationRef);

    const documents = pagination
      .getItems()
      .map((ref) => page.getContent<Document>(ref))
      .filter((doc: Document) => !doc.getData<ShopInShopDocument>().excludeFromFeed);

    let shops: ShopInShopDocument[] = documents.map((doc: Document) => {
      return doc.getData<ShopInShopDocument>();
    });

    shops = shops.sort((shop1, shop2) =>
      shop1.country > shop2.country || (shop1.country == shop2.country && shop1.city > shop2.city) ? 1 : -1,
    );

    const limitStores = ref(true);

    return {
      showButton,
      buttonLabel,
      limitStores,
      limitedShops,
      pagination,
      title,
      content,
      shops,
      isPreview,
    };
  },
  methods: {
    toggleButton() {
      this.limitStores = !this.limitStores;
    },
  },
});
