//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfInput, SfTextarea, SfButton, SfSelect, SfCheckbox, SfHeading } from '@storefront-ui/vue';
import { required, min } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { ref, computed, defineComponent, onMounted, useContext } from '@nuxtjs/composition-api';
import useExponeaApi from '~/composables/useExponeaApi';
import { parseBloomreachInternalLinks } from '~/helpers/urlHelpers';
import { useExponeaStore } from '~/stores';
import LazyHydrate from 'vue-lazy-hydration';
import { useMagentoConfiguration } from '~/composables';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('phone', {
  validate: (value) =>
    value &&
    value.startsWith('+') &&
    // This regex matches serveral phone formats but they also want to start by +
    /^(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})$/.test(value),
  message: 'Phone format is not valid',
});

export default defineComponent({
  name: 'ExponeaForm',
  props: {
    component: null,
    document: null,
    page: null,
  },
  components: {
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    SfHeading,
    LazyHydrate,
    SfTextarea,
  },

  setup(props) {
    const { selectedLocale } = useMagentoConfiguration();
    const { app } = useContext();
    let form = ref({});

    const getItemContent = (ref) => {
      return props.page.getContent(ref)?.getData() || {};
    };

    onMounted(() => {
      const exponeaStore = useExponeaStore();
      const { firstname, lastname, email } = exponeaStore.user;
      form.value = { firstname, lastname, email };
      // Needed to make sure that checkboxes are handled as booleans
      items.value?.forEach((item) => {
        if (item.type.selectionValues[0].key === 'checkbox') {
          form.value[item.fieldName] = false;
        }
      });
    });

    const errors = ref([]);
    const success = ref(false);

    const birthDays = [...Array(31).keys()];
    const birthMonths = [...Array(12).keys()].map((x) =>
      new Date(2022, x, 1).toLocaleString(selectedLocale.value, { month: 'long' }),
    );
    const birthYears = computed(() => {
      const years = [];
      const min = new Date().getFullYear();
      const max = min - 80;

      for (var i = max; i <= min; i++) {
        years.push(i);
      }
      return years.reverse();
    });

    const computedDocument = computed(() => {
      if (props.document) return props.document;

      const { document } = props.component?.getModels();
      return props.page.getContent(document);
    });
    const isPreview = computed(() => {
      return props.page?.isPreview();
    });
    const data = computed(() => {
      return computedDocument.value?.getData() || {};
    });
    const title = computed(() => {
      return data.value.title;
    });
    const submitButton = computed(() => {
      return data.value.submitButton;
    });
    const htmlContent = computed(() => {
      return data.value.content?.value;
    });
    const privacyPolicy = computed(() => {
      return data.value.privacyPolicy?.value;
    });
    const submitText = computed(() => {
      return data.value.submitText;
    });
    const items = computed(() => {
      const { formItems } = data.value;
      const items = formItems?.map((item) => getItemContent(item));
      return items;
    });

    const { post } = useExponeaApi();

    const calcDate = (year, month, day) => {
      if (!(year && month && day)) return null;
      let birthdayDate = new Date(year, month, day, 12, 0, 0);
      return birthdayDate;
    };

    const getTimeStamp = (date) => {
      return Math.round(date.getTime() / 1000);
    };

    const getLabelWithoutPTags = (item) => {
      return item?.richTextlabel?.value?.replace('<p>', '').replace('</p>', '').trim();
    };

    const isValidDate = (year, month, day) => {
      var parsedDate = new Date(year, month, day);
      return parsedDate.getFullYear() == year && parsedDate.getMonth() == month && parsedDate.getDate() == day;
    };

    const submitForm = async () => {
      let formValid = true;
      const theProperties = {
        action: 'new',
        signup_source: data.value.source,
        consent_list: [
          {
            action: 'accept',
            category: 'newsletter',
            valid_until: 'unlimited',
          },
        ],
      };
      const theMergedProperties = { ...theProperties, ...form.value };
      const body = {
        customer_ids: { registered: form.value.email },
        properties: theMergedProperties,
        event_type: 'double_opt_in',
      };

      items.value?.forEach((item) => {
        if (
          item.type.selectionValues[0].key === 'birthday' &&
          form.value[item.fieldName + 'year'] &&
          form.value[item.fieldName + 'month'] &&
          form.value[item.fieldName + 'day']
        ) {
          const year = form.value[item.fieldName + 'year'];
          const month = form.value[item.fieldName + 'month'];
          const day = form.value[item.fieldName + 'day'];
          const birthday = calcDate(year, month, day);
          if (birthday != null && (!isValidDate(year, month, day) || birthday >= new Date())) {
            success.value = false;
            const birthdayErrorField = document.getElementById(item.fieldName + 'error');
            birthdayErrorField.innerText = app.i18n.t('Birthday incorrect');
            birthdayErrorField.scrollIntoView();
            formValid = false;
          } else {
            document.getElementById(item.fieldName + 'error').innerText = '';
            body.properties[item.fieldName] = getTimeStamp(birthday);
          }
        }
      });

      const smsMessagesBody = {
        customer_ids: { registered: form.value.email },
        properties: {
          action: 'accept',
          category: 'sms_messages',
          identification: form.value.email,
          identification_type: 'registered',
          valid_until: 'unlimited',
        },
        event_type: 'consent',
      };
      try {
        if (formValid) {
          await post('/customers/events', body, 'track');
          if (form.value?.phone_marketing) {
            await post('/customers/events', smsMessagesBody, 'track');
          }
          pushEventToGa();
          success.value = true;
          errors.value = [];
        }
      } catch (error) {
        success.value = false;
        errors.value = error;
      }
    };

    const pushEventToGa = () => {
      dataLayer.push({
        event: 'GAEvent',
        eventCategory: 'Marketing Automation',
        eventAction: 'Contact Signup',
        eventLabel: 'website.blackfriday',
        eventValue: undefined,
      });
    };

    return {
      form,
      submitForm,
      birthDays,
      birthMonths,
      birthYears,
      errors,
      success,
      title,
      items,
      isPreview,
      submitText,
      computedDocument,
      htmlContent,
      privacyPolicy,
      submitButton,
      getLabelWithoutPTags,
      parseBloomreachInternalLinks,
    };
  },
});
