


















































import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Photoslurp',
  computed: {
    document() {
      return this.page?.getDocument();
    },
    params() {
      return this.component?.model?.meta?.paramsInfo;
    },
    isPreview() {
      return this.page?.isPreview();
    },
    model() {
      const { document } = this.component.getModels();
      return document;
    },
    content() {
      return this.page.getContent(this.model);
    },
    data() {
      return this.content?.getData();
    },
    title() {
      return this.getParam('title', false);
    },
    subtitle() {
      return this.getParam('subtitle', false);
    },
    scriptSrc() {
      return this.getParam('src', 'https://static.photoslurp.com/widget/v3/loader.js');
    },
    photoslurpId() {
      return this.getParam('id', false);
    },
    widgetHtml() {
      return `<ps-widget data-config="${this.photoslurpId}"></ps-widget>`;
    },
    isMasonry() {
      const { type } = this.params;
      return type === 'Masonry';
    },
    albumId() {
      return this.getParam('albumId');
    },
  },
  methods: {
    getParam(key: string | number, fallback = false) {
      const value = this.component.model.meta.paramsInfo[key];

      if (value) {
        return value;
      }

      return fallback;
    },
  },
  props: {
    component: null,
    page: null,
  },
  setup() {},
});
