





















import { SfBanner } from '@storefront-ui/vue';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import BynderAsset from '../BynderAsset.vue';

export default defineComponent({
  name: 'GridBanner',
  components: {
    SfBanner,
    BynderAsset,
  },
  props: {
    banners: null,
  },
  setup(props) {
    const getAttributes = (banner) => {
      if (banner.ctaUrl) {
        return {
          href: banner.ctaUrl,
        };
      } else {
        return {
          to: banner.ctaLink ? banner.ctaLink : '',
        };
      }
    };

    const columns = computed(() => ((props.banners?.length || 0) % 4 === 0 ? 4 : 3));

    const formatTitle = (title) => title.replace(/\{(.*)\}/, '<span class="sf-badge sf-badge--tiny">$1</span>');

    return {
      getAttributes,
      formatTitle,
      columns,
    };
  },
});
