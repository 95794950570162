//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfHeading, SfButton } from '@storefront-ui/vue';
import { defineComponent, computed, onMounted } from '@nuxtjs/composition-api';
import { useCart } from '~/composables';
import CartPreview from '~/components/Checkout/CartPreview.vue';
import { isPreviousStepValid } from '~/helpers/checkout/steps';

export default defineComponent({
  name: 'OverviewStep',
  components: {
    SfHeading,
    SfButton,
    CartPreview,
  },
  setup() {
    const { cart } = useCart();
    const shippingAddress = computed(() => {
      return cart.value.shipping_addresses?.[0];
    });
    const billingAddress = computed(() => {
      return cart.value.billing_address;
    });

    onMounted(async () => {
      const validStep = await isPreviousStepValid('shipping');
      if (!validStep) {
        await router.push(app.localePath('/checkout/shipping'));
      }
    });

    return {
      cart,
      shippingAddress,
      billingAddress,
    };
  },
});
