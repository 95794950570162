var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items && _vm.items.length)?_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('div',{staticClass:"form exponea-form",class:_vm.isPreview ? 'has-edit-button' : ''},[_c('br-manage-content-button',{attrs:{"content":_vm.computedDocument}}),_vm._v(" "),(_vm.title)?_c('SfHeading',{staticClass:"exponea-form__heading",attrs:{"title":_vm.title,"level":2}}):_vm._e(),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.htmlContent)}}),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{attrs:{"id":"shipping-details-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[(item.type.selectionValues[0].key === 'text')?_c('ValidationProvider',{staticClass:"exponea-form__element",attrs:{"rules":{ required: item.required },"name":item.fieldName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":item.fieldName,"label":_vm.getLabelWithoutPTags(item),"required":item.required,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"type":'text'},model:{value:(_vm.form[item.fieldName]),callback:function ($$v) {_vm.$set(_vm.form, item.fieldName, $$v)},expression:"form[item.fieldName]"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(item.type.selectionValues[0].key === 'phone')?_c('ValidationProvider',{staticClass:"exponea-form__element",attrs:{"rules":{
              required: item.required,
              phone: true,
            },"name":item.fieldName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":item.fieldName,"label":_vm.getLabelWithoutPTags(item),"required":item.required,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"type":'tel'},model:{value:(_vm.form[item.fieldName]),callback:function ($$v) {_vm.$set(_vm.form, item.fieldName, $$v)},expression:"form[item.fieldName]"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(item.type.selectionValues[0].key === 'textarea')?_c('ValidationProvider',{staticClass:"exponea-form__element",attrs:{"rules":{ required: item.required },"name":item.fieldName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"exponea-form__birthday-label",domProps:{"innerHTML":_vm._s(
                item.richTextlabel
                  ? _vm.parseBloomreachInternalLinks(item.richTextlabel.value) + (item.required ? '<p>&nbsp;*</p>' : '')
                  : ''
              )}}),_vm._v(" "),_c('div',{staticStyle:{"clear":"both"}}),_vm._v(" "),_c('SfTextarea',{attrs:{"name":item.fieldName,"cols":60,"rows":10,"wrap":"soft","required":item.required,"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form[item.fieldName]),callback:function ($$v) {_vm.$set(_vm.form, item.fieldName, $$v)},expression:"form[item.fieldName]"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(item.type.selectionValues[0].key === 'email')?_c('ValidationProvider',{staticClass:"exponea-form__element",attrs:{"rules":{ required: item.required },"name":item.fieldName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":item.fieldName,"label":_vm.getLabelWithoutPTags(item),"required":item.required,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"type":'email'},model:{value:(_vm.form[item.fieldName]),callback:function ($$v) {_vm.$set(_vm.form, item.fieldName, $$v)},expression:"form[item.fieldName]"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(item.type.selectionValues[0].key === 'birthday')?_c('div',[_c('div',{staticClass:"exponea-form__birthday-label",domProps:{"innerHTML":_vm._s(
                item.richTextlabel
                  ? _vm.parseBloomreachInternalLinks(item.richTextlabel.value) + (item.required ? '<p>&nbsp;*</p>' : '')
                  : ''
              )}}),_vm._v(" "),_c('div',{staticStyle:{"clear":"both"}}),_vm._v(" "),_c('span',{staticClass:"sf-input__error-message sf-input.invalid sf-input",staticStyle:{"padding":"var(--spacer-2xs)"},attrs:{"id":item.fieldName + 'error'}}),_vm._v(" "),_c('div',{staticClass:"exponea-form__horizontal"},[_c('ValidationProvider',{staticClass:"exponea-form__element",attrs:{"name":item.fieldName + 'day',"rules":{ required: item.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfSelect',{staticClass:"exponea-form__element form__element--half form__element--half-even form__select sf-select--underlined",attrs:{"label":_vm.$t('Day'),"name":item.fieldName + 'day',"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form[item.fieldName + 'day']),callback:function ($$v) {_vm.$set(_vm.form, item.fieldName + 'day', $$v)},expression:"form[item.fieldName + 'day']"}},_vm._l((_vm.birthDays),function(option){return _c('SfSelectOption',{key:option,attrs:{"value":option + 1}},[_vm._v("\n                    "+_vm._s(option + 1)+"\n                  ")])}),1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"exponea-form__element",attrs:{"name":item.fieldName + 'month',"rules":{ required: item.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfSelect',{staticClass:"exponea-form__element form__element--half form__element--half-even form__select sf-select--underlined",attrs:{"label":_vm.$t('Month'),"name":item.fieldName + 'month',"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form[item.fieldName + 'month']),callback:function ($$v) {_vm.$set(_vm.form, item.fieldName + 'month', $$v)},expression:"form[item.fieldName + 'month']"}},_vm._l((_vm.birthMonths),function(option,index){return _c('SfSelectOption',{key:option,attrs:{"value":index}},[_vm._v("\n                    "+_vm._s(option)+"\n                  ")])}),1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"exponea-form__element",attrs:{"name":item.fieldName + 'year',"rules":{ required: item.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfSelect',{staticClass:"exponea-form__element form__element--half form__element--half-even form__select sf-select--underlined",attrs:{"label":_vm.$t('Year'),"name":item.fieldName + 'year',"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form[item.fieldName + 'year']),callback:function ($$v) {_vm.$set(_vm.form, item.fieldName + 'year', $$v)},expression:"form[item.fieldName + 'year']"}},_vm._l((_vm.birthYears),function(option){return _c('SfSelectOption',{key:option,attrs:{"value":option}},[_vm._v("\n                    "+_vm._s(option)+"\n                  ")])}),1)]}}],null,true)})],1)]):_vm._e(),_vm._v(" "),(item.type.selectionValues[0].key === 'checkbox')?_c('ValidationProvider',{staticClass:"exponea-form__element",attrs:{"name":item.fieldName,"rules":{ required: item.required ? { allowFalse: false } : false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfCheckbox',{staticClass:"exponea-form__checkbox",attrs:{"name":item.fieldName,"label":_vm.getLabelWithoutPTags(item),"hintMessage":"","selected":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"sf-checkbox__label exponea-form__checkbox-label",domProps:{"innerHTML":_vm._s(
                    item.richTextlabel
                      ? _vm.parseBloomreachInternalLinks(item.richTextlabel.value) +
                        (item.required ? '<p>&nbsp;*</p>' : '')
                      : item.required
                      ? '*'
                      : ''
                  )}})]},proxy:true}],null,true),model:{value:(_vm.form[item.fieldName]),callback:function ($$v) {_vm.$set(_vm.form, item.fieldName, $$v)},expression:"form[item.fieldName]"}}),_vm._v(" "),(errors[0])?_c('span',{staticClass:"sf-input__error-message sf-input.invalid sf-input",staticStyle:{"padding":"var(--spacer-2xs)"}},[_vm._v(_vm._s(_vm.$t(errors[0])))]):_vm._e()]}}],null,true)}):_vm._e()],1)}),_vm._v(" "),(!_vm.success)?_c('SfButton',{staticClass:"exponea-form__button",attrs:{"disabled":invalid}},[_vm._v(" "+_vm._s(_vm.submitButton)+" ")]):_vm._e(),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.privacyPolicy)}}),_vm._v(" "),(_vm.errors.length)?_c('div',{staticClass:"errors errors bg-danger--variant text-danger"},_vm._l((_vm.errors),function(error){return _c('p',{key:error},[_vm._v(_vm._s(_vm.$t(error)))])}),0):_vm._e(),_vm._v(" "),(_vm.success)?_c('div',{staticClass:"exponea-form__success"},[_vm._v(_vm._s(_vm.submitText))]):_vm._e()],2)]}}],null,false,389999642)})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }