




import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import useProduct from '~/composables/useProduct';
import ProductCard from '~/components/MyJewellery/ProductCard.vue';
import ProductCardLoading from '~/components/MyJewellery/ProductCardLoading.vue';

export default defineComponent({
  name: 'IndividualProductCard',
  components: { ProductCardLoading, ProductCard },
  props: {
    component: null,
    page: null,
  },
  setup(props) {
    const { getProductList } = useProduct();

    const { sku } = props.component.getParameters();
    const loading = ref(true);

    let product = ref({});

    onMounted(async () => {
      const result = await getProductList({
        filter: {
          sku: {
            in: [sku],
          },
        },
      });

      product.value = result.items?.[0] || {};
      loading.value = false;
    });

    const showAtcButton = ref(true);

    return {
      loading,
      product,
      showAtcButton,
    };
  },
});
