













import { defineComponent, ref, onMounted, onUnmounted, computed, watch, nextTick } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { pcpUspsStore } from '~/stores';

Swiper.use([Autoplay]);

export default defineComponent({
  setup() {
    const swiperInstance = ref();
    const swiperRef = ref(null);
    const store = pcpUspsStore();
    const { currentUsps } = storeToRefs(store);

    const initializeSwiper = () => {
      swiperInstance.value = new Swiper(swiperRef.value, {
        slidesPerView: 'auto',
        centeredSlides: false,
        spaceBetween: 0,
        loop: true,
        threshold: 1,
        speed: 500,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        breakpoints: {
          1024: {
            autoplay: false,
            allowTouchMove: false,
          },
        },
      });
    };

    onMounted(async () => {
      if (currentUsps.value.length) {
        initializeSwiper();
      }
    });

    onUnmounted(() => swiperInstance.value?.destroy());

    watch(currentUsps, () => {
      if (currentUsps.value.length) {
        nextTick(() => {
          initializeSwiper();
        });
      }
    });

    /**
     * Computed property for the style of the component.
     * Scrollbar width is set this way because of 100vw doesn't take into account the scrollbar width.
     * @returns {Object} The computed style object.
     */
    const style = computed(() => {
      const scrollbarWidth = window.innerWidth - document.body.clientWidth;
      return {
        '--swiper-scrollbar-width': `${scrollbarWidth}px`,
      };
    });

    return {
      currentUsps,
      swiperInstance,
      swiperRef,
      style,
    };
  },
});
