//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

import { useUiHelpers } from '~/composables';
import { useUiState } from '~/stores';
import TimesIcon from '~/assets/icons/times.svg';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'TopNav',
  components: {
    SfButton,
    TimesIcon,
  },
  setup() {
    const uiState = useUiState();
    const { toggleMobileMenu } = uiState;
    const { isMobileMenuOpen } = storeToRefs(uiState);
    const { toggleMobileMenuOpenClassOnBody } = useUiHelpers();

    const toggleNavMenu = () => {
      toggleMobileMenu();
      toggleMobileMenuOpenClassOnBody();
    };

    return {
      isMobileMenuOpen,
      toggleNavMenu,
    };
  },
});
