//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfBanner, SfLink } from '@storefront-ui/vue';
import { defineComponent, useContext, computed } from '@nuxtjs/composition-api';
import BynderAsset from '../BynderAsset.vue';

export default defineComponent({
  name: 'ImageTextBanner',
  components: {
    SfBanner,
    SfLink,
    BynderAsset,
  },
  props: {
    alignTextLeft: false,
    banners: null,
    page: null,
  },
  setup(props) {
    const { app } = useContext();
    const computedBanners = computed(
      () =>
        props.banners?.map((banner) => ({
          ...banner,
          link: banner.ctaUrl || banner.ctaLink,
        })) || [],
    );
    return {
      computedBanners,
    };
  },
});
