







































import { computed, defineComponent } from '@vue/composition-api';
import { SfPrice, SfButton } from '@storefront-ui/vue';
import SaleLabel from './SaleLabel.vue';

interface Color {
  name: string;
  image: string;
  url: string;
}

export default defineComponent({
  name: 'ProductCardInfo',
  components: {
    SfPrice,
    SfButton,
    SaleLabel,
  },
  props: {
    colors: {
      type: Array as () => Color[],
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    specialPrice: {
      type: String,
      default: '',
    },
    regularPrice: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const hasColors = computed(() => Boolean(props.colors?.length));
    const limitedColors = computed(() => props.colors?.slice(0, 4) ?? []);
    const showBadge = computed(() => props.colors?.length > 5);

    const getBackgroundColor = (color) => {
      if (color.image.indexOf('#') === 0) return 'none';
      if (color.image.indexOf('https') === 0) return `url(${color.image})`;
      return `url(https://www.my-jewellery.com/media/attribute/swatch/swatch_image/30x20/${color.image})`;
    };

    return {
      hasColors,
      limitedColors,
      showBadge,
      getBackgroundColor,
    };
  },
});
