





























import { defineComponent } from '@nuxtjs/composition-api';
import { SfHeading, SfSkeleton } from '@storefront-ui/vue';

import ProductPrice from './ProductPrice.vue';
import ProductRating from './ProductRating.vue';
import SaleLabel from '~/components/MyJewellery/SaleLabel.vue';
import type { Product } from '@/types/interfaces/product';

export default defineComponent({
  name: 'ProductInfo',
  components: {
    SfHeading,
    SfSkeleton,
    ProductPrice,
    ProductRating,
    SaleLabel,
  },
  props: {
    product: {
      type: Object as () => Product,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
