//
//
//
//
//
//
//
//
//
//
//

import { SfSkeleton } from '@storefront-ui/vue';
export default {
  name: 'ProductCardLoading',
  props: {
    type: {
      type: String,
      default: 'carousel',
    },
  },
  components: {
    SfSkeleton,
  },
};
