//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfButton, SfCharacteristic } from '@storefront-ui/vue';
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import { useUiState } from '~/stores';

import ChevronDownIcon from '~/assets/icons/chevron-down.svg';
import deFlagIcon from '~/assets/icons/flags/de.svg';
import enFlagIcon from '~/assets/icons/flags/en.svg';
import frFlagIcon from '~/assets/icons/flags/fr.svg';
import nlFlagIcon from '~/assets/icons/flags/nl.svg';

export default defineComponent({
  name: 'StoreSwitcher',
  components: {
    SfButton,
    SfCharacteristic,
    ChevronDownIcon,
    nlFlagIcon,
    deFlagIcon,
    frFlagIcon,
    enFlagIcon,
  },
  setup() {
    const { config } = useConfig();
    const { app } = useContext();
    const { toggleLocaleSidebar } = useUiState();
    const apiState = app.$vsf.$magento.config.state;

    const storeLocale = computed(
      () =>
        app.i18n.locale?.substring(0, 2).toUpperCase() ||
        apiState.getLocale()?.substring(0, 2).toUpperCase() ||
        config.value?.locale?.substring(0, 2).toUpperCase() ||
        '',
    );

    return {
      toggleLocaleSidebar,
      storeLocale,
    };
  },
});
