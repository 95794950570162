
























import { defineComponent } from '@nuxtjs/composition-api';
import BynderAsset from './BynderAsset.vue';
import { SfSkeleton, SfButton } from '@storefront-ui/vue';
import Countdown from '~/components/Countdown.vue';

export default defineComponent({
  components: {
    BynderAsset,
    SfSkeleton,
    SfButton,
    Countdown,
  },
  props: {
    component: null,
    page: null,
  },
  setup(props) {
    const { document } = props.component?.getModels();
    const { colorDateTimeUntil, ctaLink, ctaUrl, dateTimeUntil, image } =
      props.page?.getContent(document)?.getData() || {};

    const internalLink = props.page?.getContent(ctaLink)?.getUrl();

    return {
      colorDateTimeUntil,
      internalLink,
      ctaUrl,
      dateTimeUntil,
      image,
    };
  },
});
