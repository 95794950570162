




























import { TYPE_LINK_EXTERNAL } from '@bloomreach/spa-sdk';
import { clickOutside } from '~/utilities/directives/click-outside/click-outside-directive.js';
import ChevronDownNavIcon from '~/assets/icons/chevron-down-nav.svg';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { MenuItem } from '@bloomreach/spa-sdk';

export default defineComponent({
  name: 'NestedLeftMenu',
  components: {
    ChevronDownNavIcon,
  },
  props: {
    items: {
      type: Array as () => MenuItem[],
      required: true,
    },
  },
  directives: { clickOutside },
  setup(props) {
    const isExpanded = ref(false);

    const toggleExpanded = (event) => {
      event.preventDefault();
      isExpanded.value = !isExpanded.value;
    };

    const getClassName = (item) => {
      return {
        expanded: isExpanded.value,
        'has-children': item.getChildren()?.length,
        active: item.isSelected(),
      };
    };

    const getLinkClassName = (item) => {
      return {
        'nav-link--disabled': !item.getUrl(),
        'nav-link--external': item.getLink()?.type === TYPE_LINK_EXTERNAL,
        'nav-link--expandable': item.getChildren()?.length,
      };
    };

    const closeNav = () => {
      isExpanded.value = false;
    };

    const getUrl = (item) => item.getUrl();

    const getName = (item) => item.getName();

    const getChildren = (item) => item.getChildren();

    const getChildrenLength = (item) => item.getChildren()?.length;

    return {
      isExpanded,
      TYPE_LINK_EXTERNAL,
      closeNav,
      toggleExpanded,
      getClassName,
      getLinkClassName,
      getUrl,
      getName,
      getChildren,
      getChildrenLength,
    };
  },
});
