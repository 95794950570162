//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfList, SfPrice, SfQuantitySelector, SfRadio, SfButton } from '@storefront-ui/vue';
import { productGetters } from '@vue-storefront/magento';
import { computed, defineComponent, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { useCart } from '~/composables/useCart';
import { bundleProductInitialSelector } from '~/helpers/product/bundleProduct';
import { useProduct } from '~/composables';
import { storeToRefs } from 'pinia';
import { usePageStore, useProductStore } from '~/stores';

export default defineComponent({
  name: 'BundleProductSelector',
  components: {
    SfButton,
    SfList,
    SfPrice,
    SfQuantitySelector,
    SfRadio,
  },
  props: {
    canAddToCart: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['update-bundle', 'update-price'],
  setup(props, { emit }) {
    const { product } = storeToRefs(useProductStore());
    const { routeData } = usePageStore();
    const route = useRoute();
    const { loading, addItem } = useCart();

    const productId = routeData?.sku || route.value?.params?.id;
    const { productLoading } = useProduct(`product-${productId}`);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const bundleProduct = computed(() => productGetters.getBundleProducts(product.value));

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const selectedOptions = ref(() => []);
    selectedOptions.value = bundleProductInitialSelector(bundleProduct.value);

    const canChangeQuantity = (bundle) => {
      const selectedOption = bundle.options.find((o) => o.uid === selectedOptions.value[bundle.uid]?.uid);

      return !selectedOption.can_change_quantity;
    };

    const price = computed(() =>
      Object.keys(selectedOptions.value)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        .reduce((s, k) => s + Number.parseFloat(selectedOptions.value[k].price) * selectedOptions.value[k].quantity, 0),
    );

    const addToCart = async () => {
      const bundleProductData = computed(() =>
        Object.keys(selectedOptions.value).map((selectedOptionKey) => {
          const selectedOption = selectedOptions.value[selectedOptionKey];
          return {
            uid: selectedOption.uid,
            value: selectedOption.quantity,
          };
        }),
      );

      await addItem({
        product: {
          ...product.value,
          bundle_options: bundleProductData.value,
        },
        quantity: 1,
      });
    };

    watch(
      bundleProduct,
      (newVal) => {
        selectedOptions.value = newVal;
      },
      { deep: true },
    );

    watch(
      computed(() => selectedOptions.value),
      () => {
        emit('update-bundle', selectedOptions.value);
        emit('update-price', price.value);
      },
      { deep: true },
    );

    return {
      addToCart,
      bundleProduct,
      canChangeQuantity,
      loading,
      productLoading,
      price,
      product,
      productGetters,
      selectedOptions,
    };
  },
});
