import { render, staticRenderFns } from "./CartSidebarItem.vue?vue&type=template&id=72c56f84&"
import script from "./CartSidebarItem.vue?vue&type=script&lang=ts&"
export * from "./CartSidebarItem.vue?vue&type=script&lang=ts&"
import style0 from "./CartSidebarItem.vue?vue&type=style&index=0&id=72c56f84&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductSpecialNote: require('/builds/my-jewellery/code/frontend/components/Product/ProductSpecialNote.vue').default})
