var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"checkout-page checkout-page--loading"},[_vm._v("Loading...")]):_c('div',{staticClass:"checkout-page"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var validate = ref.validate;
return [_c('SfHeading',{staticClass:"sf-heading--left sf-heading--no-underline title",attrs:{"level":2,"title":_vm.$t('User Account')}}),_vm._v(" "),_c('p',[_vm._v("We gebruiken je naam en contactgegevens voor de verwerking van je bestelling.")]),_vm._v(" "),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();validate().then(handleSubmit(_vm.handleFormSubmit(reset)))}}},[_c('div',{staticClass:"form form--user-account"},[_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-firstName'),expression:"'user-account-firstName'"}],staticClass:"form__element form__element--half",attrs:{"label":"First name","name":"firstName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"lastname","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-lastName'),expression:"'user-account-lastName'"}],staticClass:"form__element form__element--half form__element--half-even",attrs:{"label":"Last name","name":"lastName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","rules":"email|required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-email'),expression:"'user-account-email'"}],staticClass:"form__element",attrs:{"label":"E-mail","name":"email","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:8","name":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"telephone","label":_vm.$t('Phone number'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})]}}],null,true)})],1),_vm._v(" "),_c('h3',{staticClass:"sf-heading__title h3"},[_vm._v("\n        "+_vm._s(_vm.$t('Email & SMS'))+" "),_c('small',[_vm._v(_vm._s(_vm.$t('recommended')))])]),_vm._v(" "),_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('sign-up-newsletter'),expression:"'sign-up-newsletter'"}],staticClass:"form__element",attrs:{"label":"Yes, sign me up for for the newsletter","name":"signupNewsletter"},model:{value:(_vm.form.is_subscribed_newsletter),callback:function ($$v) {_vm.$set(_vm.form, "is_subscribed_newsletter", $$v)},expression:"form.is_subscribed_newsletter"}}),_vm._v(" "),_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('sign-up-sms'),expression:"'sign-up-sms'"}],staticClass:"form__element",attrs:{"label":"Yes, keep me up to date via SMS","name":"signupSms"},model:{value:(_vm.form.is_subscribed_sms),callback:function ($$v) {_vm.$set(_vm.form, "is_subscribed_sms", $$v)},expression:"form.is_subscribed_sms"}}),_vm._v(" "),_c('h3',{staticClass:"sf-heading__title h3"},[_vm._v("\n        "+_vm._s(_vm.$t('Create account'))+" "),_c('small',[_vm._v(_vm._s(_vm.$t('optional')))])]),_vm._v(" "),_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('create-account'),expression:"'create-account'"}],staticClass:"form__element",attrs:{"label":"Create a password","name":"createUserAccount"},model:{value:(_vm.createUserAccount),callback:function ($$v) {_vm.createUserAccount=$$v},expression:"createUserAccount"}}),_vm._v(" "),(_vm.createUserAccount)?_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8|password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-password'),expression:"'user-account-password'"}],staticClass:"form__element",attrs:{"name":"password","label":"Password","type":"password","required":"","has-show-password":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.createUserAccount)?_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","data-vv-as":"password","name":"password_confirmation","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-password-control'),expression:"'user-account-password-control'"}],staticClass:"form__element",attrs:{"label":"Password confirmation","type":"password","name":"password_confirmation","required":"","has-show-password":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.passwordControl),callback:function ($$v) {_vm.$set(_vm.form, "passwordControl", $$v)},expression:"form.passwordControl"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form__action"},[_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('continue-to-shipping'),expression:"'continue-to-shipping'"}],staticClass:"form__action-button",attrs:{"type":"submit","disabled":!_vm.canMoveForward}},[_vm._v("\n            "+_vm._s(_vm.$t('Continue to shipping'))+"\n          ")])],1)])],1)]}}])}),_vm._v(" "),_c('CartPreview',{key:"order-summary",staticClass:"desktop-only"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }