







































































































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import {
  SfAccordion,
  SfBadge,
  SfButton,
  SfCheckbox,
  SfChevron,
  SfFilter,
  SfRadio,
  SfRange,
  SfSection,
} from '@storefront-ui/vue';
import PriceRangeSlider from '~/components/Category/PriceRangeSlider.vue';
import Sidebar from '~/components/Sidebar/Sidebar.vue';
import { usePageStore, useUiState } from '~/stores';

import { useBloomreachDiscoveryStore } from '~/stores';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'FiltersSortingSidebar',
  components: {
    Sidebar,
    SfAccordion,
    SfBadge,
    SfButton,
    SfCheckbox,
    SfChevron,
    SfFilter,
    SfRadio,
    SfRange,
    SfSection,
    PriceRangeSlider,
  },
  setup() {
    const bloomreachDiscoveryStore = useBloomreachDiscoveryStore();
    const { getFiltersFromRoutePath } = bloomreachDiscoveryStore;
    const pageStore = usePageStore();
    const context = useContext();
    const { app } = context;
    const uiState = useUiState();
    const { closeFilterSidebar } = uiState;
    const { isFilterSidebarOpen } = storeToRefs(uiState);
    const { facets, selectedFilters, sortOptions, selectedSortOption, searchResponse, currentPage, facetsKeysMap } =
      storeToRefs(bloomreachDiscoveryStore);
    const { applyFilters, doSearch } = bloomreachDiscoveryStore;

    const isFilterSelected = (facet, option) =>
      selectedFilters.value?.[facet?.id] === option.name || selectedFilters.value?.[facet?.id]?.includes(option.name);

    const getSelectedFiltersCount = (facetId) => {
      let realFacetId = facetsKeysMap.value[facetId] ?? facetId;
      if (typeof selectedFilters.value?.[realFacetId] === 'string') {
        return selectedFilters.value?.[realFacetId]
          .split(',')
          .map((p) => app.$fc(p).replace(',00', '').replace('.00', ''))
          .join(' - ');
      }

      return selectedFilters.value?.[facetId]?.length || 0;
    };

    const openAccordionItems = ref([]);

    const isAccordionItemOpen = (facetId) => openAccordionItems.value?.includes(facetId);

    const toggleAccordionItem = (uid) => {
      const toggledItems = [uid];
      openAccordionItems.value = openAccordionItems.value
        .filter((x) => !toggledItems.includes(x))
        .concat(toggledItems.filter((x) => !openAccordionItems.value.includes(x)));
    };

    const onApplyFilters = async () => {
      openAccordionItems.value = [];
      await applyFilters();

      scrollAfterPageLoaded();
    };

    const selectFilter = (facetId, option) => {
      let filter = selectedFilters.value?.[facetId] || [];

      // When user deselects filter
      if (filter.includes(option?.name)) {
        selectedFilters.value = {
          ...selectedFilters.value,
          [facetId]: filter.filter((filter) => filter !== option?.name),
        };
        return doSearch({});
      }

      if (facetId === 'price') {
        filter = option.join(',');
      } else if (filter.includes(option?.name)) {
        filter = filter.filter((x) => x !== option?.name);
      } else {
        filter.push(option?.name);
      }

      if (filter.length) {
        selectedFilters.value = {
          ...selectedFilters.value,
          [facetId]: Array.isArray(filter) ? filter.filter((x) => x !== '').map((x) => x) : filter,
        };
      } else {
        delete selectedFilters.value[facetId];
      }

      return doSearch({});
    };

    const selectSortOption = (option) => {
      selectedSortOption.value = option;
      const previousSortOption = new URLSearchParams(window.location.search).get('sort');

      if (selectedSortOption.value !== previousSortOption) {
        currentPage.value = 1;
      }

      return doSearch({});
    };

    const close = () => {
      closeFilterSidebar();
      getFiltersFromRoutePath(context);
      doSearch({});
    };

    const clearSelectedFilters = async () => {
      selectedFilters.value = {};
      await doSearch({});
    };

    const scrollAfterPageLoaded = () => {
      new Promise<void>((resolve) => {
        let timePassed = 0;
        const interval = setInterval(() => {
          timePassed += 100;
          if (pageStore.loaded || timePassed > 2500) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      }).then(() => {
        const pageHeader = document.querySelector('.category-page-header__container');
        // If we have a CategoryHeader scroll past it after changing filters
        if (pageHeader) {
          return pageHeader.scrollIntoView({
            behavior: 'smooth',
          });
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
    };

    return {
      isFilterSelected,
      selectFilter,
      getSelectedFiltersCount,
      selectedSortOption,
      selectedFilters,
      facets,
      openAccordionItems,
      isAccordionItemOpen,
      toggleAccordionItem,
      isFilterSidebarOpen,
      sortOptions,
      clearSelectedFilters,
      close,
      searchResponse,
      onApplyFilters,
      selectSortOption,
    };
  },
});
