//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useConfig, storeConfigGetters } from '@vue-storefront/magento';
import Logo from '~/assets/images/logo.svg';

export default defineComponent({
  name: 'HeaderLogo',
  components: { Logo },
});
