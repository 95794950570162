




















































import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { SfButton, SfProductCard } from '@storefront-ui/vue';
import { ExponeaProduct } from '~/types/interfaces/exponea';
import { BloomreachProduct } from '~/types/interfaces/bloomreach';
import ProductCard from '~/components/MyJewellery/ProductCard.vue';

interface Props {
  title: string;
  items: ExponeaProduct[];
}

export default defineComponent({
  name: 'EmptyCartAlternativeProducts',
  components: {
    SfButton,
    SfProductCard,
    ProductCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<Props['items']>,
      default: [],
    },
  },
  setup(props) {
    const mapExponeaToBloomreach = (exponeaProduct: ExponeaProduct): BloomreachProduct => ({
      brand: 'MyJewellery',
      colors: exponeaProduct.color_codes,
      date_online: exponeaProduct.date_added?.toString(),
      hover_image_url: exponeaProduct.hover_image_url,
      original_price: exponeaProduct.price,
      pid: exponeaProduct.product_id,
      price: exponeaProduct.price,
      sale_price: Number(exponeaProduct.sale_price),
      sizes: exponeaProduct.variant_code,
      sku: exponeaProduct.sku,
      thumb_image: exponeaProduct.image,
      title: exponeaProduct.title,
      url: exponeaProduct.url,
      variants: exponeaProduct.variant_code,
    });

    // Map Exponea products to Bloomreach products
    const exponeaMappedToBloomreachProducts = computed(() => {
      return props.items.map((item) => ({
        ...mapExponeaToBloomreach(item),
      })) as BloomreachProduct[];
    });

    return {
      exponeaMappedToBloomreachProducts,
    };
  },
});
