//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfRadio } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useCartStore } from '~/stores';
import { useShippingProvider } from '~/composables';
import PostNL from '~/assets/icons/postnl.svg';

export default defineComponent({
  name: 'PickupLocationButton',
  components: {
    SfRadio,
    PostNL,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { selectedShippingAddress, shipping } = useCartStore();
    const { save: saveShippingProvider } = useShippingProvider();
    const selectedShippingMethod = computed(() => selectedShippingAddress.company || null);

    const selectPickupLocation = () => {
      const shippingData = {
        token: shipping.token,
        method: {
          identifier: props.location.shippingOptions[0].identifier,
          method_code: 'paazlshipping',
          carrier_code: 'paazlshipping',
        },
        pickupLocation: {
          code: props.location.code,
        },
      };

      saveShippingProvider({
        shippingMethod: shippingData,
      });
    };

    const icon = computed(() => props.location.shippingOptions?.[0].carrier.description);

    return {
      selectedShippingMethod,
      selectPickupLocation,
      icon,
    };
  },
});
