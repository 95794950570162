
















import { defineComponent, computed, ref, PropType } from '@nuxtjs/composition-api';
import { SfButton, SfLoader } from '@storefront-ui/vue';
import HeartIcon from '~/assets/icons/heart.svg';
import HeartIconFilled from '~/assets/icons/heart-fill.svg';
import { useProductStore, wishlistStore } from '~/stores';
import { useProduct } from '~/composables';
import { storeToRefs } from 'pinia';
import logger from '~/utilities/logger';
import type { Product } from '@/types/interfaces/product';

export default defineComponent({
  name: 'AddToWishlist',
  components: {
    HeartIcon,
    HeartIconFilled,
    SfButton,
    SfLoader,
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      default: () => ({}),
    },
    selectedVariant: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { getProductDetails } = useProduct();
    const { setProduct } = useProductStore();
    const { activeProductSku } = storeToRefs(useProductStore());
    const { isInWishlist: fetchIsInWishlist, removeItem } = wishlistStore();
    const { showWishlistModal } = storeToRefs(wishlistStore());

    const isInWishlist = computed(() => fetchIsInWishlist({ product: props.product }));
    const actionText = computed(() => (isInWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'));

    const loading = ref(false);
    const toggleIsInWishlist = async () => {
      loading.value = true;
      const sku = props.product?.sku;
      activeProductSku.value = sku;

      if (isInWishlist.value) {
        await removeItem({
          product: props.product,
        });
      } else {
        showWishlistModal.value = true;
      }

      // Otherwise, fetch it
      const result = await getProductDetails({
        filter: {
          sku: {
            eq: sku,
          },
        },
      });

      if (!result) {
        return logger.error('Product not found');
      }
      await setProduct(result?.items[0] as Product);
      loading.value = false;
    };

    return {
      actionText,
      toggleIsInWishlist,
      isInWishlist,
      loading,
    };
  },
});
