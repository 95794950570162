


















































import { useCartStore } from '~/stores';
import { SfCheckbox, SfAddressPicker, SfButton, SfLoader, SfHeading, SfModal, SfLink } from '@storefront-ui/vue';
import { useAddresses, userAddressesGetters } from '@vue-storefront/magento';
import { CustomerAddress, CustomerAddressInput, CartAddressInput } from '@vue-storefront/magento-api';
import { computed, defineComponent, onMounted, ref, watch } from '@nuxtjs/composition-api';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import AddressForm from '~/components/MyAccount/AddressForm.vue';
import { useShipping, useBilling, useUser } from '~/composables';
import UserShippingAddresses from './UserShippingAddresses.vue';
import UserShippingForm from './UserShippingForm.vue';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'UserShippingExistingUser',
  components: {
    SfCheckbox,
    SfAddressPicker,
    SfButton,
    SfLoader,
    SfHeading,
    SfModal,
    SfLink,
    UserAddressDetails,
    AddressForm,
    UserShippingAddresses,
    UserShippingForm,
  },
  setup(_, { emit }) {
    const { loading: loadingShipping } = useShipping();
    const { loading: loadingBilling } = useBilling();
    const { addresses, save: saveAddress, load: loadAddresses } = useAddresses();
    const { load: loadUser } = useUser();
    const cartStore = useCartStore();
    const { selectedShippingAddress, selectedShippingAddressId, selectedBillingAddressId, hasShippingAddress } =
      storeToRefs(cartStore);

    const billingSameAsShipping = computed(() => selectedShippingAddressId.value === selectedBillingAddressId.value);
    const showAddressSelection = ref(false);
    const showBillingSelection = ref(false);
    const shippingAddresses = computed(() => userAddressesGetters.getAddresses(addresses.value));
    const loading = computed(() => loadingShipping.value || loadingBilling.value);

    const handleCheckBillingSameAsShipping = async () => {
      showAddressSelection.value = true;
      if (showBillingSelection.value) {
        showBillingSelection.value = false;
        emit('setAddressOnBilling', selectedShippingAddress.value);
      } else {
        showBillingSelection.value = true;
      }
    };

    const newAddressPopupVisible = ref(false);
    const newAddressType = ref('shipping');
    const showNewAddressPopup = (addressType: string) => {
      newAddressPopupVisible.value = true;
      newAddressType.value = addressType;
    };

    const setDefaultAddressOnCart = async () => {
      await loadAddresses();
      if (hasShippingAddress.value) {
        emit('setAddressOnShipping', selectedShippingAddress.value as CartAddressInput);
        if (!billingSameAsShipping.value) {
          showAddressSelection.value = true;
          showBillingSelection.value = true;
        }
        return;
      }

      const defaultShippingAddress: CustomerAddress = shippingAddresses.value.find(
        (address) => address.default_shipping,
      );

      const defaultBillingAddress: CustomerAddress = shippingAddresses.value.find((address) => address.default_billing);
      emit('setAddressOnShipping', defaultShippingAddress ? defaultShippingAddress : shippingAddresses.value[0]);

      if (defaultShippingAddress?.id !== defaultBillingAddress?.id) {
        //billingSameAsShipping.value = false;
        showAddressSelection.value = true;
      }
    };

    const setCurrentAddress = (type: string, address: CustomerAddressInput) => {
      // Set address on cart
      if (type === 'shipping') {
        emit('setAddressOnShipping', address);
      } else if (type === 'billing') {
        emit('setAddressOnBilling', address);
      }
    };

    const submitFromPopup = async (address: CustomerAddressInput) => {
      // Save address on shipping
      await saveAddress({ address });
      await loadUser();
      await loadAddresses();

      // Set address on cart
      emit(newAddressType.value === 'shipping' ? 'setAddressOnShipping' : 'setAddressOnBilling', address);
      newAddressPopupVisible.value = false;
    };

    // watch(billingSameAsShipping, () => {
    //   if (billingSameAsShipping.value) {
    //     return (showBillingSelection.value = false);
    //   }
    //   showBillingSelection.value = true;
    // });

    onMounted(async () => {
      setDefaultAddressOnCart();
    });

    return {
      billingSameAsShipping,
      showAddressSelection,
      showBillingSelection,
      handleCheckBillingSameAsShipping,
      showNewAddressPopup,
      selectedShippingAddress,
      loading,
      selectedShippingAddressId,
      selectedBillingAddressId,
      setCurrentAddress,
      newAddressPopupVisible,
      newAddressType,
      submitFromPopup,
    };
  },
});
