











import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import Checkmark from '~/assets/icons/checkmark.svg';
import { useProductUSPStore } from '~/stores';
import { Breadcrumb } from '~/types/interfaces/breadcrumbs';
import { storeToRefs } from 'pinia';
import { onSSR } from '@vue-storefront/core';

export default defineComponent({
  name: 'ProductUsps',
  components: {
    Checkmark,
  },
  props: {
    sku: {
      type: String,
      default: '',
    },
    breadcrumbs: {
      type: Array as PropType<Breadcrumb[]> | null,
      default: null,
    },
  },
  setup(props) {
    const productUSPStore = useProductUSPStore();
    const { getUspsBySku, getUspsByCategory } = productUSPStore;
    const { globalUsps, skuUsps, categoryUsps } = storeToRefs(productUSPStore);

    onSSR(async () => {
      await getUspsBySku(props.sku);
      await getUspsByCategory(props.breadcrumbs.map((breadcrumb) => breadcrumb.id).reverse());
    });

    const skus = computed(() => {
      if (skuUsps.value[props.sku]?.length) {
        return Object.values(skuUsps.value[props.sku])
          ?.flat()
          ?.filter((usp) => usp);
      }

      if (Object.values(categoryUsps.value)?.some((category) => category.length)) {
        return Object.values(categoryUsps.value)
          ?.flat()
          ?.filter((usp) => usp);
      }

      return globalUsps.value;
    });

    return {
      skus,
    };
  },
});
