var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",attrs:{"id":"my-account-create"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"column"},[_c('h1',[_vm._v(_vm._s(_vm.$t('Create an account')))])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"column"},[_c('ValidationObserver',{key:"sign-up",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.handleRegister())}}},[_c('div',{staticClass:"personal-information"},[_c('h2',{staticClass:"h3"},[_vm._v(_vm._s(_vm.$t('Personal information')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-firstName'),expression:"'login-modal-firstName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"first-name","label":_vm.$t('First Name')},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-lastName'),expression:"'login-modal-lastName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"last-name","label":_vm.$t('Last Name')},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)}),_vm._v(" "),_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('sign-up-newsletter'),expression:"'sign-up-newsletter'"}],staticClass:"form__element",attrs:{"label":_vm.$t('Sign Up for Newsletter'),"name":"signupNewsletter"},model:{value:(_vm.isSubscribed),callback:function ($$v) {_vm.isSubscribed=$$v},expression:"isSubscribed"}})],1),_vm._v(" "),_c('div',{staticClass:"sign-in-information"},[_c('h2',{staticClass:"h3"},[_vm._v(_vm._s(_vm.$t('Sign-in information')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Your email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8|password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-password'),expression:"'user-account-password'"}],staticClass:"form__element",attrs:{"name":"password","label":"Password","type":"password","required":"","has-show-password":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","data-vv-as":"password","name":"password_confirmation","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-password-control'),expression:"'user-account-password-control'"}],staticClass:"form__element",attrs:{"label":"Password confirmation","type":"password","name":"password_confirmation","required":"","has-show-password":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.passwordControl),callback:function ($$v) {_vm.$set(_vm.form, "passwordControl", $$v)},expression:"form.passwordControl"}})]}}],null,true)}),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),_c('SfNotification',{attrs:{"visible":!!_vm.error.register,"message":_vm.$t(_vm.error.register),"persistent":false,"type":"danger"}})],1),_vm._v(" "),_c('div',{staticClass:"button-group"},[_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-submit'),expression:"'login-modal-submit'"}],staticClass:"form__button",attrs:{"type":"submit","disabled":_vm.loading || invalid}},[_c('span',[_vm._v(_vm._s(_vm.$t('Create an account')))]),_vm._v(" "),_c('SfLoader',{staticClass:"form__button-loader",class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}})],1),_vm._v(" "),_c('div',{staticClass:"action"},[_c('SfButton',{staticClass:"sf-button--outline",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.redirectToLogin.apply(null, arguments)}}},[_vm._v("\n                "+_vm._s(_vm.$t('Go back'))+"\n              ")])],1)],1)])]}}])})],1),_vm._v(" "),_c('div',{staticClass:"column"})])])}
var staticRenderFns = []

export { render, staticRenderFns }