//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useRouter, useMeta } from '@nuxtjs/composition-api';
import { SfButton, SfImage, SfHeading } from '@storefront-ui/vue';

const STATUS_CODES_TITLES = {
  [404]: 'Page not found 404',
};

export default defineComponent({
  name: 'ErrorLayout',
  props: ['error'],
  components: { SfButton, SfImage, SfHeading },
  head: {},
  setup(props) {
    const router = useRouter();

    useMeta({
      title: STATUS_CODES_TITLES[props.error?.statusCode] ?? null,
    });

    return {
      router,
    };
  },
});
