











import { defineComponent } from '@nuxtjs/composition-api';
import { transformImageUrlToSize, ImageSize } from '~/helpers/magentoImage';

export default defineComponent({
  name: 'ProductSwiperSlidePicture',
  props: {
    item: {
      type: Object,
      required: true,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
  setup() {
    return { transformImageUrlToSize, ImageSize };
  },
});
