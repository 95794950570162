



















import { defineComponent, useAsync, useContext } from '@nuxtjs/composition-api';
import { SfAccordion, SfHeading } from '@storefront-ui/vue';
import HTMLContent from '~/components/HTMLContent.vue';

export default defineComponent({
  name: 'FAQ',
  components: { HTMLContent, SfAccordion, SfHeading },
  props: {
    faqRef: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { $axios, $brxmEndpointResolver } = useContext();
    const faqData = useAsync(async () => {
      const ref = props.faqRef.replace('/page/', '');

      const faqResponse = await $axios.$get(`${$brxmEndpointResolver.resolveDocumentsEndPoint()}/${ref}`);

      const faqItems = Object.values(faqResponse.content).map((faqItem: any) => faqItem.data);

      return {
        title: faqItems.find((item) => item.title)?.title,
        backgroundColor: faqItems.find((item) => item.background_color)?.background_color,
        questions: faqItems.filter((item) => item.question),
      };
    });

    return {
      faqData,
    };
  },
});
