










import { defineComponent } from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';

export default defineComponent({
  name: 'SidebarTitle',
  components: {
    SfHeading,
  },
  props: {
    title: String,
    description: String,
  },
});
