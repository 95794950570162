



















































































































































































import { watch, computed, defineComponent, ref, onMounted, nextTick } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Instagram',
  mounted() {
    embedInstagram(this.scriptSrc);
  },
  computed: {
    document() {
      return this.page?.getDocument();
    },
    params() {
      return this.component?.model?.meta?.paramsInfo;
    },
    isPreview() {
      return this.page?.isPreview();
    },
    model() {
      const { document } = this.component.getModels();
      return document;
    },
    content() {
      return this.page.getContent(this.model);
    },
    data() {
      return this.content?.getData();
    },
    title() {
      return this.getParam('title', false);
    },
    scriptSrc() {
      return this.getParam('src', 'https://www.instagram.com/embed.js');
    },
    instagramUrl() {
      return 'https://www.instagram.com/p/' + this.getParam('id', false) + '/?utm_source=ig_embed&utm_campaign=loading';
    },
  },
  methods: {
    getParam(key: string | number, fallback = false) {
      const value = this.component.model.meta.paramsInfo[key];

      if (value) {
        return value;
      }

      return fallback;
    },
  },
  props: {
    component: null,
    page: null,
  },
  setup(props) {
    const id = computed(() => {
      return props?.component?.model?.meta?.paramsInfo['id'];
    });
    const computedKey = ref(0);
    onMounted(() => {
      watch(id, () => {
        computedKey.value++;
        nextTick(() => {
          (window as any)?.instgrm?.Embeds.process();
        });
      });
    });
    return {
      computedKey,
    };
  },
});
function embedInstagram(scriptSrc) {
  const scriptIdentifier = 'instagramEmbedScript';
  document.getElementById(scriptIdentifier)?.remove();
  let recaptchaScript = document.createElement('script');
  recaptchaScript.setAttribute('id', scriptIdentifier);
  recaptchaScript.setAttribute('src', scriptSrc);
  document.head.appendChild(recaptchaScript);
}
