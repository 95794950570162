





















































































import { defineComponent, ref, useContext, useRoute, useRouter } from '@nuxtjs/composition-api';
import { SfBadge, SfColor, SfImage, SfLoader } from '@storefront-ui/vue';
import { productGetters } from '@vue-storefront/magento';
import ProductCardInfo from './ProductCardInfo.vue';
import ProductCardRating from './ProductCardRating.vue';
import ProductCardLabels from './ProductCardLabels.vue';
import AddToWishlist from '~/components/Product/AddToWishlist.vue';
import AddToCart from '~/components/Product/AddToCart.vue';
import HeartIcon from '~/assets/icons/heart.svg';
import HeartFillIcon from '~/assets/icons/heart-fill.svg';
import { useBloomreachDiscoveryStore, useExponeaStore, usePageStore, useProductStore, useUiState } from '~/stores';
import { useImage } from '~/composables';
import { getProductColourWheels } from '~/helpers/product/productGetters';
import { getLocalePathFromAbsoluteUrl } from '~/helpers/urlHelpers';
import { ImageSize, transformImageUrlToSize } from '~/helpers/magentoImage';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ProductCard',
  components: {
    AddToWishlist,
    AddToCart,
    HeartIcon,
    HeartFillIcon,
    ProductCardInfo,
    ProductCardRating,
    SfBadge,
    SfColor,
    SfImage,
    SfLoader,
    ProductCardLabels,
  },
  props: {
    productImageFirst: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    imageLoadingType: {
      type: String,
      default: 'lazy',
      validator: function (value: string) {
        return ['lazy', 'eager', 'none'].includes(value);
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    showAtcButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      app: { $fc, localePath, i18n, $config },
    } = useContext();
    const { pageData, routeData } = storeToRefs(usePageStore());
    const { getAttributeById } = useProductStore();
    const { getMagentoImage } = useImage();
    const { setSoldOutFormData } = useUiState();
    const { user } = storeToRefs(useExponeaStore());
    const { appliedFilters } = storeToRefs(useBloomreachDiscoveryStore());
    const router = useRouter();
    const route = useRoute();

    const getSpecialPrice = () => {
      if (props.product?.sale_price && props.product?.original_price !== props.product?.sale_price) {
        return $fc(props.product?.sale_price) || '';
      } else if (props.product?.price && props.product?.original_price !== props.product?.price) {
        return $fc(props.product?.price) || '';
      }
      return '';
    };

    const getBloomreachImage = () => {
      const currentColors = appliedFilters.value?.colors?.map((color) => color.toLowerCase());
      let variant = null;

      for (let colorId in currentColors) {
        variant = props.product?.variants?.find((variant) => variant.sku_color === currentColors?.[colorId]);
        if (variant?.filter_image?.[0]) return variant.filter_image[0];
      }

      return props.productImageFirst
        ? props.product?.thumbnail || props.product?.thumb_image
        : props.product?.thumb_image || '';
    };

    const checkIfLabelsAreAllowed = (labelOrlabels) => {
      if (!labelOrlabels) return;
      return Array.isArray(labelOrlabels)
        ? labelOrlabels?.filter(
            (label) =>
              !pageData.value?.hidePlpTags
                ?.split(',')
                .map((hideTag) => hideTag.toLowerCase())
                .includes(label?.toLowerCase()),
          )
        : !pageData.value?.hidePlpTags
            ?.split(',')
            .map((hideTag) => hideTag.toLowerCase())
            .includes(labelOrlabels?.toLowerCase())
        ? [labelOrlabels]
        : [];
    };

    // Check to see if the Exponea user has a preference for gold or silver
    // If they do, we will use this preference to display the correct image
    // Value in Exponea is Dutch, so we translate it
    const getMagentoImageWithUserPreference = (fallback) => {
      const preference = Array.isArray(user.value?.jewellery_preference)
        ? user.value?.jewellery_preference[0]
        : user.value?.jewellery_preference;
      const mapPreference = {
        Goud: ['Goud', 'Gold', 'Silver', 'Doré'],
        Zilver: ['Zilver', 'Silber', 'Silver', 'Argenté'],
      };
      const array = mapPreference[preference] ?? [];
      const variant = props.product?.variants?.find((variant) =>
        variant.attributes.find((attr) => array.includes(attr.label)),
      );
      return (
        transformImageUrlToSize(getMagentoImage(variant?.product?.thumbnail?.url || fallback), ImageSize.Large) || ''
      );
    };

    const mapProduct = () => {
      let regularPrice = '';
      // Magento
      if (['ConfigurableProduct', 'SimpleProduct'].includes(props.product?.__typename)) {
        regularPrice = getPriceOrHardcodedGiftCardRange(
          $fc,
          props.product?.sku,
          productGetters.getPrice(props.product).regular,
        );
        return {
          pid: props.product?.pid || '',
          title: props.product?.name || '',
          image: getMagentoImageWithUserPreference(props.product.thumbnail.url),
          hoverImage: getMagentoImageWithUserPreference(props.product.thumbnail_hover),
          regularPrice: regularPrice,
          specialPrice:
            (productGetters.getPrice(props.product).special &&
              $fc(productGetters.getPrice(props.product).special)?.toString()) ||
            '',
          link: localePath(`${productGetters.getSlug(props.product)}`),
          colors: getProductColourWheels(props.product),
          scoreRating: 0 && productGetters.getAverageRating(props.product),
          reviewsCount: productGetters.getTotalReviews(props.product),
          sku: props.product?.sku,
          date_online: props.product?.date_online,
          labels: checkIfLabelsAreAllowed(getAttributeById(props.product?.product_label, 'product_label')),
          variants: props.product?.variants?.map((variant) => {
            return {
              ...variant,
              out_of_stock: [[variant.stock_status === 'OUT_OF_STOCK'].toString()],
            };
          }),
          out_of_stock: props.product?.stock_status === 'OUT_OF_STOCK',
          source: 'Magento',
        };
      }

      // Exponea
      if (props.product?.__typename === 'ExponeaProduct') {
        regularPrice = getPriceOrHardcodedGiftCardRange($fc, props.product?.sku, props.product?.price?.toString());
        return {
          pid: props.product?.item_id || '',
          title: props.product?.title || '',
          image: transformImageUrlToSize(props.product?.image, ImageSize.Large) || '',
          hoverImage: transformImageUrlToSize(props.product?.hover_image_url, ImageSize.Large) || '',
          regularPrice: regularPrice,
          specialPrice: props.product?.sale_price ? $fc(props.product?.sale_price?.toString()) : null,
          link: props.product?.url ? getLocalePathFromAbsoluteUrl(props.product?.url) : '',
          colors: [],
          scoreRating: props.product?.reviews_rating_summary || 0,
          reviewsCount: props.product?.review_count || 0,
          sku: props.product?.sku,
          date_online: props.product?.date_online,
          variants: props.product?.variants,
          source: 'Exponea',
        };
      }

      // Bloomreach
      const bloomReachColors = () => {
        let colors = [];
        if (
          props.product?.variants &&
          props.product?.variants.length > 0 &&
          props.product?.variants[0]?.active_colors_hex?.length
        ) {
          colors = props.product?.variants[0]?.active_colors_hex;
        }
        if (props.product?.style_swatches_hex?.length) {
          colors = props.product?.style_swatches_hex;
        }
        return colors?.map((color) => {
          return { image: color };
        });
      };

      regularPrice = getPriceOrHardcodedGiftCardRange(
        $fc,
        props.product?.sku,
        props.product?.original_price?.toString(),
      );
      return {
        pid: props.product?.pid || '',
        title: props.product?.title || '',
        image: getBloomreachImage(),
        hoverImage: props.product?.hover_image_url || '',
        regularPrice: regularPrice,
        specialPrice: getSpecialPrice(),
        link: props.product?.url ? getLocalePathFromAbsoluteUrl(props.product?.url) : '',
        colors: bloomReachColors(),
        scoreRating: props.product?.reviews_rating_summary,
        reviewsCount: props.product?.review_count,
        sku: props.product?.sku,
        date_online: props.product?.date_online,
        labels: checkIfLabelsAreAllowed(props.product?.product_label),
        out_of_stock: props.product?.out_of_stock === 'true',
        variants: props.product?.variants,
        source: 'Bloomreach',
      };
    };

    const mappedProduct = mapProduct();

    const imageWidth = 300;
    const imageHeight = 450;

    const hasColors = () => Boolean(mappedProduct?.colors?.length);

    const hidePlpTags = ref(pageData.value?.hidePlpTags);

    const isSoldOut = (() => {
      if ($config.checkMainProductStock && mappedProduct?.out_of_stock) {
        return true;
      } else {
        return (
          mappedProduct?.variants?.length > 0 &&
          mappedProduct?.variants?.every((variant) => variant.out_of_stock?.[0] !== 'false')
        );
      }
    })();

    const comingSoonDateFormatted = mappedProduct?.date_online
      ? new Intl.DateTimeFormat(i18n.locale).format(new Date(mappedProduct?.date_online))
      : null;

    const isComingSoon = mappedProduct?.date_online
      ? !!(new Date(mappedProduct?.date_online) > new Date() && isSoldOut)
      : null;

    const productId = mappedProduct?.pid;
    const productSku = mappedProduct?.sku?.replace(' ', '_');
    const mapFetchPriority = {
      eager: 'high',
      lazy: 'low',
    };
    const imgPriority = ref({
      fetchPriority: mapFetchPriority[props.imageLoadingType],
      loading: props.imageLoadingType === 'none' ? undefined : props.imageLoadingType,
    });

    const setScrollPosition = (offsetTop: number) => {
      if (['CATEGORY', 'SEARCH'].includes(routeData.value.type)) {
        const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions'));
        sessionStorage.setItem(
          'scrollPositions',
          JSON.stringify({
            ...scrollPositions,
            [route.value.fullPath]: {
              sku: props.product.sku,
              offsetTop,
            },
          }),
        );
      }
    };

    const goToPDP = async () => {
      const offsetTop = document
        .querySelector(`[data-sku=${props.product.sku?.replace(' ', '_')}]`)
        .getBoundingClientRect().top;
      const currentPage = parseInt((route.value.query.p as string) || '1');

      // Save the right page to scroll to on browser back
      if (props.product?.page !== currentPage && props.product?.page > 1) {
        await router.push({ path: route.value.path, query: { ...route.value.query, p: props.product?.page } });
      } else if (props.product?.page !== currentPage && props.product?.page === 1) {
        let { p, ...query } = route.value.query;
        await router.push({ path: route.value.path, query });
      }

      setScrollPosition(offsetTop);

      router.push({ path: mappedProduct.link });
    };

    return {
      imageWidth,
      imageHeight,
      hasColors,
      mappedProduct,
      isSoldOut,
      isComingSoon,
      comingSoonDateFormatted,
      setSoldOutFormData,
      productId,
      productSku,
      hidePlpTags,
      imgPriority,
      goToPDP,
    };
  },
});

function getPriceOrHardcodedGiftCardRange($fc, sku, price) {
  let resultPrice = '';
  if (sku === 'mjgiftcard') {
    // hardcoded for mjgiftcard only
    resultPrice = $fc(10) + ' - ' + $fc(150);
  } else {
    resultPrice = $fc(price) || '';
  }
  return resultPrice;
}
