







































































































import { computed, ref, defineComponent, useContext, PropType, onMounted, onUnmounted } from '@nuxtjs/composition-api';
import { SfAddToCart, SfButton, SfProperty, SfNotification } from '@storefront-ui/vue';
import { useCart } from '~/composables';
import { useProductStore, useUiState } from '~/stores';

import AddToWishlist from '~/components/Product/AddToWishlist.vue';
import BundleProductSelector from '~/components/Product/BundleProductSelector.vue';
import GroupedProductSelector from '~/components/Product/GroupedProductSelector.vue';
import ProductBrands from './ProductBrands.vue';
import { storeToRefs } from 'pinia';
import { getProductOptionsNames, getSelectedProductVariant } from '~/helpers/product/productGetters';
import stockStatusEnum from '~/enums/stockStatusEnum';
import type { Product } from '@/types/interfaces/product';
import ProductSoldOutForm from '~/components/Product/ProductSoldOutForm.vue';

export default defineComponent({
  name: 'ProductActions',
  components: {
    ProductSoldOutForm,
    AddToWishlist,
    BundleProductSelector,
    GroupedProductSelector,
    SfAddToCart,
    SfButton,
    SfProperty,
    SfNotification,
    ProductBrands,
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      default: () => ({}),
    },
    productConfiguration: {
      type: Object,
      default: () => ({}),
    },
    isAtcModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { i18n } = useContext();
    const { addItem } = useCart();
    const productStore = useProductStore();
    const { setSoldOutFormData } = useUiState();
    const { productCustomOptionsConfiguration, giftcardHolder, productLoading } = storeToRefs(productStore);
    const { productCustomOptionsCanAddToCartHandler } = productStore;
    const errorNoVariantSelected = ref(false);

    const productConfigurationCanAddToCart = computed(() => {
      const selectedOptions = Object.values(props.productConfiguration || {}).sort();
      if (!selectedOptions.length) return false;
      return (
        props.product?.variants?.find((variant) =>
          variant.attributes.every((attribute) => selectedOptions.includes(attribute.uid)),
        )?.product.stock_status === 'IN_STOCK'
      );
    });

    const productCustomOptionsCanAddToCart = computed(() => productCustomOptionsCanAddToCartHandler(props.product.sku));

    const canAddProductToCart = computed(() => {
      if (productLoading.value[props.product.sku] || !productCustomOptionsCanAddToCart.value) return false;

      if (props.product?.__typename === 'ConfigurableProduct') {
        return productConfigurationCanAddToCart.value;
      }

      return props.product?.stock_status === stockStatusEnum.inStock;
    });

    const addToCartButtonText = computed(() => {
      if (hasJustAddedToCart.value) {
        return i18n.t('Added!');
      }
      if (isLoadingAddToCart.value) {
        return i18n.t('Adding');
      }
      return i18n.t('Add to cart');
    });

    const ADDED_TO_CART_MESSAGE_TIMEOUT_MS = 400;
    const isLoadingAddToCart = ref(false);
    const hasJustAddedToCart = ref(false);

    const addItemToCart = async () => {
      if (isLoadingAddToCart.value || hasJustAddedToCart.value) {
        return;
      }

      if (!canAddProductToCart.value) {
        errorNoVariantSelected.value = true;
        return setTimeout(() => (errorNoVariantSelected.value = false), 5000);
      }

      isLoadingAddToCart.value = true;
      try {
        if (giftcardHolder.value) {
          // Await or you'll have an out of sync cart
          await addItem({ product: giftcardHolder.value, quantity: 1 });
        }

        await addItem({
          product: props.product,
          quantity: 1,
          productConfiguration: props.productConfiguration,
          productCustomOptionsConfiguration: productCustomOptionsConfiguration.value,
        });

        hasJustAddedToCart.value = true;
        setTimeout(() => {
          hasJustAddedToCart.value = false;
        }, ADDED_TO_CART_MESSAGE_TIMEOUT_MS);
      } catch (err) {
        // if we don't catch the error, it bubbles up all the way to a 500 error
      } finally {
        isLoadingAddToCart.value = false;
      }

      emit('addedToCart');
    };

    const handleCustomAddToCart = async (callback) => {
      if (isLoadingAddToCart.value || hasJustAddedToCart.value) {
        return;
      }
      isLoadingAddToCart.value = true;
      try {
        await callback();
        hasJustAddedToCart.value = true;
        setTimeout(() => {
          hasJustAddedToCart.value = false;
        }, ADDED_TO_CART_MESSAGE_TIMEOUT_MS);
      } catch (err) {
        // if we don't catch the error, it bubbles up all the way to a 500 error
      } finally {
        isLoadingAddToCart.value = false;
      }

      emit('addedToCart');
    };

    const productNames = getProductOptionsNames(props.product);
    const selectedProduct = computed(() => getSelectedProductVariant(props.product, props.productConfiguration));

    onMounted(() => {
      if (props.product.stock_status === 'OUT_OF_STOCK') {
        setSoldOutFormData({
          productId: props.product.pid,
          type: 'soldOut',
        });
      }
    });

    onUnmounted(() => {
      if (props.product.stock_status === 'OUT_OF_STOCK')
        setSoldOutFormData({ productId: null, optionLabel: null, type: null, isModalVisible: false });
    });

    return {
      addItemToCart,
      errorNoVariantSelected,
      productNames,
      canAddProductToCart,
      isLoadingAddToCart,
      hasJustAddedToCart,
      addToCartButtonText,
      handleCustomAddToCart,
      selectedProduct,
    };
  },
});
