


















import { defineComponent, computed } from '@nuxtjs/composition-api';
import BynderAsset from './BynderAsset.vue';
import { getBynderImageName, getSvgOrThumbnailImageUrl, getCalculatedWidth } from '@/helpers/bynderImage';

export default defineComponent({
  name: 'LogoBanner',
  components: {
    BynderAsset,
  },
  props: {
    component: null,
    document: null,
    page: null,
  },
  setup(props) {
    const computedDocument = computed(() => {
      if (props.document) return props.document;
      const { document } = props.component?.getModels();
      return props.page.getContent(document);
    });
    const isPreview = computed(() => {
      return props.page?.isPreview();
    });
    const data = computed(() => {
      return computedDocument.value?.getData() || {};
    });

    const items = computed(() => {
      return data.value?.logoBannerItems?.map((item) => props.page.getContent(item)?.getData() || {});
    });

    const title = computed(() => {
      return data.value?.title;
    });

    return {
      computedDocument,
      isPreview,
      items,
      title,
      getCalculatedWidth,
      getSvgOrThumbnailImageUrl,
      getBynderImageName,
    };
  },
});
