//
//
//
//
//
//
//
//

export default { props: ['component'] };
