//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext, computed, onMounted, ref } from '@nuxtjs/composition-api';
import { useUiState } from '~/stores';

import Countdown from '~/components/Countdown.vue';
import TimesIcon from '~/assets/icons/times.svg';
import CopyIcon from '~/assets/icons/copy.svg';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'HeaderNotification',
  components: {
    Countdown,
    TimesIcon,
    CopyIcon,
  },
  props: {
    component: null,
    page: null,
  },
  setup(props) {
    const { app } = useContext();
    const uiState = useUiState();
    const { showHeaderNotification, hideHeaderNotification } = uiState;
    const { isHeaderNotificationVisible } = storeToRefs(uiState);
    const loaded = ref(false);

    const content = computed(() => {
      const { document } = props.component?.getModels();
      return props.page.getContent(document);
    });

    const model = computed(() => {
      const data = content?.value?.getData();

      return {
        ...data,
        [data?.ctaLink ? 'ctaLink' : null]: props.page.getContent(data?.ctaLink)?.getUrl(),
      };
    });

    const isPreview = computed(() => {
      return props.page?.isPreview();
    });

    const notification = computed(() => {
      if (!model || !model.value) return;
      const value = model.value.content?.value;
      const message = {
        value,
        copyValue: null,
      };
      if (!value) return message;
      const messageArr = value.split(/&#60;copy&#62;|&#60;\/copy&#62;/);
      if (messageArr.length > 1) {
        message.value = `${messageArr[0]}<u>${messageArr[1]}</u>${messageArr[2]}`;
        message.copyValue = messageArr[1];
      }
      return message;
    });

    const countDown = computed(() => {
      return model?.value?.dateTimeUntil;
    });

    const showCountDown = computed(() => {
      return countDown?.value && countDown?.value > Date.now();
    });

    const backgroundColor = computed(() => {
      return model?.value?.backgroundColor;
    });

    const hide = () => {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      app.$cookies.set('hideHeaderNotification', true, {
        expires: tomorrow,
      });

      hideHeaderNotification();
    };

    const copy = () => {
      navigator.clipboard.writeText(notification?.value?.copyValue);
    };

    onMounted(() => {
      if (app.$cookies.get('hideHeaderNotification')) {
        hideHeaderNotification();
      } else if (content.value) {
        showHeaderNotification();
      }
      loaded.value = true;
    });

    return {
      isHeaderNotificationVisible,
      showHeaderNotification,
      hideHeaderNotification,
      content,
      isPreview,
      countDown,
      showCountDown,
      notification,
      hide,
      copy,
      loaded,
      backgroundColor,
    };
  },
});
