import { render, staticRenderFns } from "./Product.vue?vue&type=template&id=753edc9f&"
import script from "./Product.vue?vue&type=script&lang=ts&"
export * from "./Product.vue?vue&type=script&lang=ts&"
import style0 from "./Product.vue?vue&type=style&index=0&id=753edc9f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MyJewelleryBreadcrumbs: require('/builds/my-jewellery/code/frontend/components/MyJewellery/Breadcrumbs.vue').default,ProductGalleryLoader: require('/builds/my-jewellery/code/frontend/components/Product/ProductGalleryLoader.vue').default,ProductGallerySlider: require('/builds/my-jewellery/code/frontend/components/Product/ProductGallerySlider.vue').default,ProductInfo: require('/builds/my-jewellery/code/frontend/components/Product/ProductInfo.vue').default,ProductComingSoonForm: require('/builds/my-jewellery/code/frontend/components/Product/ProductComingSoonForm.vue').default,ProductVariantMatrix: require('/builds/my-jewellery/code/frontend/components/Product/ProductVariantMatrix.vue').default,ProductSpecialNote: require('/builds/my-jewellery/code/frontend/components/Product/ProductSpecialNote.vue').default,ProductActions: require('/builds/my-jewellery/code/frontend/components/Product/ProductActions.vue').default,ProductUsps: require('/builds/my-jewellery/code/frontend/components/Product/ProductUsps.vue').default,ProductTabs: require('/builds/my-jewellery/code/frontend/components/Product/ProductTabs.vue').default,ProductRelatedArticle: require('/builds/my-jewellery/code/frontend/components/Product/ProductRelatedArticle.vue').default,ProductTrustedShopsWidget: require('/builds/my-jewellery/code/frontend/components/Product/ProductTrustedShopsWidget.vue').default,ProductsRelatedProducts: require('/builds/my-jewellery/code/frontend/components/Products/RelatedProducts.vue').default,LazyLoad: require('/builds/my-jewellery/code/frontend/components/LazyLoad.vue').default})
