
















import { defineComponent, computed, useContext } from '@nuxtjs/composition-api';

import Accordion from '~/components/MyJewellery/Accordion.vue';
import RichText from '~/components/MyJewellery/RichText.vue';

const mapping = {
  mjRichText: RichText,
  mjFaq: Accordion,
};

export default defineComponent({
  name: 'Row',
  components: {
    Accordion,
    RichText,
  },
  props: {
    component: null,
    page: null,
  },
  setup(props) {
    const { app } = useContext();
    const isPreview = props.page?.isPreview() || false;
    const isDesktop = computed(() => app.$device.isDesktop);

    const showRow = computed(() => {
      return (
        (isDesktop.value && !props.component?.getParameters()?.mobileOnly) ||
        (!isDesktop.value && !props.component?.getParameters()?.desktopOnly)
      );
    });

    const document = computed(() => {
      const { document: ref } = props.component?.getModels();
      return props.page.getContent(ref);
    });

    const getDocument = (ref) => {
      return props.page?.getContent(ref);
    };

    const getColumnClass = (index) => {
      return [
        `w-${props.component?.getParameters()?.[`width${index + 1}`]?.toLowerCase() || 'auto'}`,
        `mobile-order-${props.component?.getParameters()?.[`sortOrderMobile${index + 1}`]?.toLowerCase() || '1'}`,
      ];
    };

    const getContentType = (column) => {
      const { model } = props.page?.getContent(column);
      return mapping?.[model?.data?.contentType.replace('brxsaas:', '')] || undefined;
    };

    const columns = computed(() => {
      return Object.values(
        Object.entries(props.component?.getModels() || {})
          .filter(([k, v]) => v !== null)
          .sort(([k1], [k2]) => k1.localeCompare(k2))
          .map((v) => v[1]),
      );
    });

    const backgroundColor = props.component?.getParameters()?.backgroundColor;
    const verticalAlign = props.component?.getParameters()?.verticalAlign?.toLowerCase();

    return {
      backgroundColor,
      columns,
      document,
      isPreview,
      mapping,
      showRow,
      verticalAlign,
      getDocument,
      getColumnClass,
      getContentType,
    };
  },
});
