//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  SfCheckbox,
  SfAddressPicker,
} from '@storefront-ui/vue';
import {
  useUserBilling,
  userBillingGetters,
} from '@vue-storefront/magento';
import {
  defineComponent,
} from '@nuxtjs/composition-api';
import UserAddressDetails from '~/components/UserAddressDetails.vue';

export default defineComponent({
  name: 'UserBillingAddresses',
  components: {
    SfCheckbox,
    SfAddressPicker,
    UserAddressDetails,
  },
  props: {
    currentAddressId: {
      type: [String, Number],
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['setCurrentAddress'],
  setup(_, { emit }) {
    const { billing: userBilling } = useUserBilling();

    const setCurrentAddress = (addressId) => {
      const selectedAddress = userBillingGetters.getAddresses(userBilling.value, { id: Number.parseInt(addressId, 10) });
      if (!selectedAddress || selectedAddress.length === 0) {
        return;
      }
      emit('setCurrentAddress', selectedAddress[0]);
    };

    const billingAddresses = userBillingGetters.getAddresses(userBilling.value);

    return {
      billingAddresses,
      setCurrentAddress,
      userBillingGetters,
    };
  },
});
