//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MailIcon from '~/assets/icons/mail.svg';
import { computed, defineComponent, onMounted, ref, reactive } from '@nuxtjs/composition-api';
import { useCustomerStore, useExponeaStore } from '~/stores';
import useExponeaApi from '~/composables/useExponeaApi';
import useExponeaConstants from '~/constants/exponea';
import { SfInput, SfButton } from '@storefront-ui/vue';
import { required } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'CtaSubscription',
  components: {
    MailIcon,
    SfButton,
    SfInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    component: null,
    page: null,
  },
  setup(props) {
    const { page, component } = props;
    const content = computed(() => {
      return component?.model?.meta?.paramsInfo || {};
    });

    const isPreview = computed(() => {
      return page?.isPreview();
    });

    const customerStore = useCustomerStore();
    const exponeaStore = useExponeaStore();
    const { FOOTER_SUBSCRIPTION_SEGMENTATION_ID } = useExponeaConstants();
    const subscribed = computed(() => exponeaStore.getSegmentationById(FOOTER_SUBSCRIPTION_SEGMENTATION_ID));

    const form = reactive({
      email: '',
    });
    const errors = ref([]);
    const success = ref(false);

    const { post } = useExponeaApi();

    const submit = async () => {
      const body = {
        customer_ids: { registered: form.email },
        properties: {
          action: 'new',
          email: form.email,
          signup_source: 'inschrijving footer',
          consent_list: [
            {
              action: 'accept',
              category: 'newsletter',
              valid_until: 'unlimited',
            },
            {
              action: 'accept',
              category: 'personalised_email',
              valid_until: 'unlimited',
            },
          ],
        },
        event_type: 'double_opt_in',
      };

      try {
        await post('/customers/events', body, 'track');
        onSucces();
        success.value = true;
        errors.value = [];
      } catch (error) {
        success.value = false;
        errors.value = error;
      }
    };

    const onSucces = () => {
      exponeaStore.getSegmentation({ id: FOOTER_SUBSCRIPTION_SEGMENTATION_ID });
      if (dataLayer) {
        dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'Marketing Automation',
          eventAction: 'Contact Signup',
          eventLabel: 'inschrijving footer',
          eventValue: undefined,
        });
      }
    };

    onMounted(async () => {
      exponeaStore.getSegmentation({ id: FOOTER_SUBSCRIPTION_SEGMENTATION_ID });
    });

    customerStore.$subscribe(() => {
      if (customerStore.isLoggedIn) {
        exponeaStore.getSegmentation({ id: FOOTER_SUBSCRIPTION_SEGMENTATION_ID });
      }
    });

    return { isPreview, content, subscribed, submit, form, errors, success };
  },
});
