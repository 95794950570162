//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfCard, SfHeading, SfButton } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import HTMLContent from '../HTMLContent.vue';

export default defineComponent({
  name: 'Cards',
  components: {
    LazyHydrate,
    SfCard,
    SfHeading,
    SfButton,
    HTMLContent,
  },
  props: {
    component: null,
    page: null,
  },
  computed: {
    document() {
      return this.page?.getDocument();
    },
    isPreview() {
      return this.page?.isPreview();
    },
    title() {
      return '';
    },
    cards() {
      const { document } = this.component.getModels();
      const data = this.page.getContent(document)?.getData();

      if (!data) {
        return;
      }

      const { imageTextCardItems } = this.page.getContent(document)?.getData();
      return imageTextCardItems.map((item) => this.getCardItemObject(item));
    },
  },
  methods: {
    getCardItemObject(ref) {
      const content = this.page.getContent(ref);
      const data = content?.getData();

      if (!data) {
        return null;
      }

      return {
        ...data,
        description: data.content,
        content,
        ctaLink: this.page.getContent(data?.ctaLink)?.getUrl(),
      };
    }
  },
});
