//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfButton, SfInput, SfSearchBar } from '@storefront-ui/vue';
import { defineComponent, ref, computed, useContext, useRoute, useRouter, watch } from '@nuxtjs/composition-api';
import _debounce from '~/helpers/debounce';

import { clickOutside } from '~/utilities/directives/click-outside/click-outside-directive.js';
import { useBloomreachAutosuggestApi } from '~/composables';
import { useBloomreachDiscoveryStore } from '~/stores';
import { slugify } from '~/helpers/stringHelpers';

import ArrowLeftIcon from '~/assets/icons/arrow-left.svg';
import SearchIcon from '~/assets/icons/search.svg';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfInput,
    SfButton,
    ArrowLeftIcon,
    SearchIcon,
  },
  directives: { clickOutside },
  props: {
    minTermLen: {
      type: Number,
      default: 1,
    },
  },
  setup({ minTermLen }, { emit }) {
    const {
      app,
      app: { $config, i18n, $cookies },
    } = useContext();
    const route = useRoute();
    const router = useRouter();
    const term = ref('');
    const result = ref(null);
    const isSearchOpen = ref(false);
    const { searchSuggestionsEnabled } = app.$config.bloomreachDiscovery;
    const isSearchSuggestionsEnabled = searchSuggestionsEnabled?.[i18n.locale.substring(0, 2)] ?? false;
    const searchInputElement = ref();

    const { results: suggestResults, search: searchAutosuggest } = useBloomreachAutosuggestApi({
      $config,
      i18n,
      $cookies,
    });
    const { selectedSortOption } = storeToRefs(useBloomreachDiscoveryStore());
    const { clearFilters } = useBloomreachDiscoveryStore();

    const isDesktop = computed(() => app.$device.isDesktop);

    const showSearch = () => {
      if (!isSearchSuggestionsEnabled || !term.value?.length) return;

      if (!isSearchOpen.value) {
        isSearchOpen.value = true;
        emit('SearchBar:toggle', true);
        if (document) {
          document.body.classList.add('no-scroll');
        }
      }
    };

    const hideSearch = () => {
      searchInputElement.value?.blur();
      isSearchOpen.value = false;
      emit('SearchBar:toggle', false);
      emit('SearchBar:result', {});
      if (document) {
        document.body.classList.remove('no-scroll');
      }
      setTimeout(() => (term.value = ''), 10);
    };

    const toggleSearch = () => {
      if (isSearchOpen.value) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = (event) => {
      if (document) {
        const searchResultsEl = document.getElementsByClassName('search');
        const wishlistSelectionEl = document.getElementsByClassName('wishlist-variant-selection-modal');
        const sidebarEl = document.getElementsByClassName('mjsf-sidebar');

        if (
          !searchResultsEl[0]?.contains(event.target) &&
          !wishlistSelectionEl[0]?.contains(event.target) &&
          !sidebarEl[0]?.contains(event.target) &&
          !event.target?.closest('.mjsf-sidebar')
        ) {
          hideSearch();
        }
      } else {
        hideSearch();
      }
    };

    const handleSearch = _debounce(async () => {
      if (!isSearchSuggestionsEnabled) return;

      if (term.value.length < minTermLen) {
        hideSearch();
        return;
      }
      showSearch();

      emit('SearchBar:loading', true);
      await searchAutosuggest(term.value);

      const termRegex = new RegExp(`(${term.value})`, 'gi');
      const products = suggestResults.value?.suggestionGroups?.[0].searchSuggestions?.slice(0, 6);
      const categories = suggestResults.value?.suggestionGroups?.[0].attributeSuggestions
        ?.filter((s) => s.attributeType === 'category')
        ?.map(({ name, value }) => {
          return {
            id: value,
            label: name,
            markedLabel: name.replace(termRegex, '<mark>$&</mark>'),
            textSlug: slugify(name.toLowerCase()),
            slug: slugify(value),
          };
        });

      const suggestions =
        suggestResults.value?.suggestionGroups?.[0]?.querySuggestions?.slice(0, 4).map(({ query, displayText }) => ({
          query,
          displayText,
          markedText: displayText.replace(termRegex, '<mark>$&</mark>'),
        })) || [];

      result.value = {
        query: term.value,
        products,
        categories,
        suggestions,
      };

      emit('SearchBar:result', result.value);
      emit('SearchBar:loading', false);
    }, 500);

    const submitSearch = () => {
      if (!term.value) return;
      handleSearch.cancel();

      clearFilters();
      selectedSortOption.value = '';
      router
        .push({
          path: `${app.localePath('/catalogsearch/result/')}`,
          query: { q: term.value },
        })
        .catch(() => {});
    };

    watch(route, hideSearch);

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      handleSearch,
      submitSearch,
      isSearchOpen,
      result,
      term,
      isDesktop,
      isSearchSuggestionsEnabled,
      searchInputElement,
    };
  },
});
