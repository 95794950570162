import { render, staticRenderFns } from "./BlogPostHeader.vue?vue&type=template&id=b4b5f73e&"
import script from "./BlogPostHeader.vue?vue&type=script&lang=ts&"
export * from "./BlogPostHeader.vue?vue&type=script&lang=ts&"
import style0 from "./BlogPostHeader.vue?vue&type=style&index=0&id=b4b5f73e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HTMLContent: require('/builds/my-jewellery/code/frontend/components/HTMLContent.vue').default})
