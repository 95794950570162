




































import { defineComponent, computed, ref, useContext, onMounted, onUnmounted } from '@nuxtjs/composition-api';
import { getBynderAsset, BynderAsset } from '~/helpers/bynderImage';

export default defineComponent({
  name: 'BynderAsset',
  props: {
    data: {
      type: String,
      default: '',
    },
    dataMobile: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: '100vw',
    },
    ratio: {
      type: Number,
      required: false,
    },
    ratioMobile: {
      type: Number,
      required: false,
    },
    alt: {
      type: String,
      required: false,
    },
    loading: {
      type: String,
      required: false,
    },
    transformation: {
      type: Symbol,
      required: false,
    },
  },
  setup(props) {
    const { app } = useContext();
    const ratioPercentage = computed(() => {
      if (app.$device.isMobile && props.ratioMobile) {
        return Math.round(100 * props.ratioMobile);
      }
      return props.ratio ? Math.round(100 * props.ratio) : null;
    });
    const cssVars = computed(() => {
      if (!ratioPercentage.value) return;
      return {
        '--padding-top': ratioPercentage.value + '%',
      };
    });

    const asset = computed(() => {
      return getBynderAsset(
        props.data,
        props.dataMobile,
        props.size,
        props.ratio,
        props.ratioMobile,
        props.transformation,
      ) as BynderAsset;
    });

    const getExtensionFromUrl = (url) => {
      return url?.split('.')?.pop();
    };

    const showVideo = ref(false);
    const videoRef = ref();
    const observer = ref();
    const videoSource = ref(app.$device.isMobile && asset.value?.urlMobile ? asset.value.urlMobile : asset.value?.url);

    onMounted(() => {
      if (asset.value?.type !== 'VIDEO') return;
      observer.value = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            showVideo.value = true;
            observer.value.unobserve(videoRef.value);
          }
        });
      });
      observer.value.observe(videoRef.value);
    });

    onUnmounted(() => {
      observer.value?.disconnect();
    });

    return {
      asset,
      ratioPercentage,
      cssVars,
      getExtensionFromUrl,
      videoRef,
      showVideo,
      videoSource,
    };
  },
});
