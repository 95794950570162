































import { defineComponent, useContext, ref, computed } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { getSpacingClassName } from '~/helpers/spacing';
import { kebabize } from '~/helpers/stringHelpers';
import { parseBloomreachInternalLinks } from '~/helpers/urlHelpers';
import { AspectRatio, parseBynderImagesInHtml } from '~/helpers/bynderImage';
import HTMLContent from '~/components/HTMLContent.vue';

const dividerString = '&#60;READMORE&#62;'; //<READMORE>
const buttonClassMapping = {
  'primary-black': 'black',
  'primary-white': 'white',
  conversion: 'pink',
  outlined: 'hollow',
  'text-button': 'text',
};

const wrapTables = (html) =>
  html.replaceAll('<table', '<div class="table-wrapper"><table').replaceAll('</table>', '</table></div>');

export default defineComponent({
  name: 'RichText',
  computed: {
    computedDocument() {
      if (this.document) return this.document;

      const { document } = this.component?.getModels();
      return this.page.getContent(document);
    },
    documentData() {
      return this.computedDocument?.getData() || {};
    },
    isPreview() {
      return this.page?.isPreview();
    },
    htmlContent() {
      const fullText = this.documentData?.richText?.value;
      let textToShow = '';
      if (fullText?.includes(dividerString)) {
        if (this.isInvisible) {
          textToShow = fullText.substring(0, fullText.indexOf(dividerString));
        } else {
          textToShow = fullText.replaceAll(dividerString, '');
        }
      } else {
        textToShow = fullText;
      }
      return wrapTables(parseBynderImagesInHtml(parseBloomreachInternalLinks(textToShow), this.aspectRatio));
    },
    showButton() {
      return this.documentData?.richText?.value?.includes(dividerString);
    },
    params() {
      return this.component?.model?.meta?.paramsInfo || {};
    },
    isFullWidth() {
      return this.documentData?.fullWidth;
    },
    backgroundColor() {
      return this.documentData?.backgroundColor;
    },
    buttonType() {
      return buttonClassMapping[kebabize(this.documentData?.buttonType?.selectionValues?.[0]?.key || 'black')];
    },
    buttonLabel() {
      return this.documentData?.buttonLabel;
    },
    buttonLink() {
      return this.documentData?.ctaUrl || this.localePath(this.page.getContent(this.documentData?.ctaLink)?.getUrl());
    },
    buttonIcon() {
      return this.documentData?.buttonIcon;
    },
    buttonTarget() {
      return this.documentData?.ctaUrl ? '_blank' : '';
    },
    aspectRatio() {
      return this.documentData?.aspectRatio?.selectionValues?.[0]?.key || AspectRatio.Original;
    },
    marginBottom() {
      return getSpacingClassName(this.params?.marginBottom || '');
    },
    styleAttr() {
      let styles = {};

      if (this.backgroundColor) {
        styles['backgroundColor'] = this.backgroundColor;
      }

      if (this.marginBottom) {
        styles['--mj-component-margin-bottom'] = `var(--margin-bottom-${this.marginBottom})`;
      }

      return styles;
    },
  },
  components: {
    HTMLContent,
    SfButton,
  },
  props: {
    component: null,
    document: null,
    page: null,
  },
  setup(props) {
    const { app } = useContext();
    const richText = ref(null);

    const isDesktop = computed(() => app.$device.isDesktop);

    const showRichText = computed(() => {
      return (
        (isDesktop.value && !props.component?.getParameters()?.mobileOnly) ||
        (!isDesktop.value && !props.component?.getParameters()?.desktopOnly)
      );
    });

    return {
      richText,
      showRichText,
      isInvisible: true,
    };
  },
});
