import { render, staticRenderFns } from "./AddressesDetails.vue?vue&type=template&id=f34d530e&scoped=true&"
import script from "./AddressesDetails.vue?vue&type=script&lang=js&"
export * from "./AddressesDetails.vue?vue&type=script&lang=js&"
import style0 from "./AddressesDetails.vue?vue&type=style&index=0&id=f34d530e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f34d530e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAddressDetails: require('/builds/my-jewellery/code/frontend/components/UserAddressDetails.vue').default})
