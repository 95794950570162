var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",attrs:{"id":"shipping-details-form"},on:{"submit":function($event){$event.preventDefault();_vm.formSubmitted = true;
      handleSubmit(_vm.submitForm);}}},[_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:2","name":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"firstname","label":_vm.$t('First Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:2","name":"lastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"lastname","label":_vm.$t('Last Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:8","name":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"telephone","label":_vm.$t('Phone number'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"country_code","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('SfSelect',{staticClass:"form__select sf-select--underlined",attrs:{"label":_vm.$t('Country'),"data-placeholder":_vm.$t('Country'),"name":"country","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.searchCountry({ id: $event })}},model:{value:(_vm.form.country_code),callback:function ($$v) {_vm.$set(_vm.form, "country_code", $$v)},expression:"form.country_code"}},[_c('SfSelectOption',{attrs:{"value":""}}),_vm._v(" "),_vm._l((_vm.countriesList),function(countryOption){return _c('SfSelectOption',{key:countryOption.id,attrs:{"value":countryOption.abbreviation}},[_vm._v("\n          "+_vm._s(countryOption.label)+"\n        ")])})],2)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:4","name":"postcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"postcode","label":_vm.$t('Zip-code'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}})]}}],null,true)}),_vm._v(" "),_c('SfInput',{staticClass:"form__element",attrs:{"name":"address-line2","label":_vm.$t('House/Apartment number'),"required":""},model:{value:(_vm.form.apartment),callback:function ($$v) {_vm.$set(_vm.form, "apartment", $$v)},expression:"form.apartment"}})],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"region"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [(!_vm.form.country_code || _vm.regionInformation.length === 0)?_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-state'),expression:"'shipping-state'"}],attrs:{"label":_vm.$t('State/Province'),"disabled":!_vm.form.country_code,"name":"state","valid":!!_vm.form.country_code,"error-message":_vm.formSubmitted && !_vm.form.country_code ? _vm.$t('Please select a country first') : ''},model:{value:(_vm.form.region.region),callback:function ($$v) {_vm.$set(_vm.form.region, "region", $$v)},expression:"form.region.region"}}):_c('SfSelect',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-state'),expression:"'shipping-state'"}],staticClass:"form__element form__element--half form__element--half-even form__select sf-select--underlined",attrs:{"label":_vm.$t('State/Province'),"placeholder":_vm.$t('State/Province'),"name":"state","valid":true,"error-message":null,"data-valid":!errors[0],"data-error-message":_vm.$t(errors[0])},model:{value:(_vm.form.region.region_code),callback:function ($$v) {_vm.$set(_vm.form.region, "region_code", $$v)},expression:"form.region.region_code"}},_vm._l((_vm.regionInformation),function(regionOption){return _c('SfSelectOption',{key:regionOption.id,attrs:{"value":regionOption.abbreviation}},[_vm._v("\n            "+_vm._s(regionOption.label)+"\n          ")])}),1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required","name":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"address-line1","label":_vm.$t('Street Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.street),callback:function ($$v) {_vm.$set(_vm.form, "street", $$v)},expression:"form.street"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"city","label":_vm.$t('City'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)})],1),_vm._v(" "),(_vm.isAuthenticated)?_c('SfCheckbox',{staticClass:"form__checkbox-isDefaultShipping",attrs:{"name":"isDefaultShipping","label":_vm.$t('Set as default shipping')},model:{value:(_vm.form.default_shipping),callback:function ($$v) {_vm.$set(_vm.form, "default_shipping", $$v)},expression:"form.default_shipping"}}):_vm._e(),_vm._v(" "),(_vm.isAuthenticated)?_c('SfCheckbox',{staticClass:"form__checkbox-isDefaultBilling",attrs:{"name":"isDefaultBilling","label":_vm.$t('Set as default billing')},model:{value:(_vm.form.default_billing),callback:function ($$v) {_vm.$set(_vm.form, "default_billing", $$v)},expression:"form.default_billing"}}):_vm._e(),_vm._v(" "),_c('SfButton',{staticClass:"form__button",attrs:{"disabled":_vm.isSubmitting}},[_vm._v("\n      "+_vm._s(_vm.isSubmitting ? 'loading...' : _vm.isNew ? _vm.$t('Add the address') : _vm.$t('Update the address'))+"\n    ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }